<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconCommunicate"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="iQuoteStore_GetQuote.quoteOpportunityId + ' (revision ' + iQuoteStore_GetQuote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3 class="appDarkFont">Adjuncts</h3>
      <br>
      <span style="font-weight: 500">ASC Call Recording</span>
      <div style="margin-left: 10px">
        <label
          for="numEssentials"
        >Essentials
          <TooltipIcon text="Call Recording channel licence - Concurrent users" />
        </label>
        <input
          id="numEssentials"
          v-model.number="viewModel.adjuncts.ascEssentials"
          type="number"
          min="0"
          max="1000000"
          @blur="viewModel.adjuncts.ascEssentials = onInputItemFocusLost(viewModel.adjuncts.ascEssentials)"
        >
        <label
          for="numPremier"
        >Premier
          <TooltipIcon text="Call Recording channel licence + Screen recording + Basic Quality Management - Named users" />
        </label>
        <input
          id="numPremier"
          v-model.number="viewModel.adjuncts.ascPremier"
          type="number"
          min="0"
          max="1000000"
          @blur="viewModel.adjuncts.ascPremier = onInputItemFocusLost(viewModel.adjuncts.ascPremier)"
        >
        <label
          for="numElite"
        >Elite
          <TooltipIcon text="Call Recording channel licence + Screen recording + Advanced Quality Management - Named User" />
        </label>
        <input
          id="numElite"
          v-model.number="viewModel.adjuncts.ascElite"
          type="number"
          min="0"
          max="1000000"
          @blur="viewModel.adjuncts.ascElite = onInputItemFocusLost(viewModel.adjuncts.ascElite)"
        >
        <br>
        <label
          :class="{InputError:!isValidMaxConcurrentCalls}"
          for="numMaxConcurrentCalls"
        >Max Concurrent calls</label>
        <input
          id="numMaxConcurrentCalls"
          v-model.number="viewModel.adjuncts.maxConcurrentCalls"
          type="number"
          min="0"
          max="1000000"
        >
        <label
          :class="{InputError:!isValidNumCallsPerAgentPerDay}"
          for="numAgentCallsPerDay"
        >Calls per agent per day</label>
        <input
          id="numAgentCallsPerDay"
          v-model.number="viewModel.adjuncts.numCallsPerAgentPerDay"
          type="number"
          min="0"
          max="1000000"
        >
        <label
          :class="{InputError:!isValidAvgCallDuration}"
          for="numAvgCallDuration"
        >Avg call duration (minutes)
          <TooltipIcon text="Average Call Duration for Call recording, average time for all recorded calls in minutes" />
        </label>
        <input
          id="numAvgCallDuration"
          v-model.number="viewModel.adjuncts.avgCallDuration"
          type="number"
          min="0"
          max="1000000"
        >
        <label
          :class="{InputError:!isValidRetentionPeriod}"
          for="numRetentionPeriod"
        >Retention period (months)
          <TooltipIcon text="Retention period in months for all recorded calls" />
        </label>
        <input
          id="numRetentionPeriod"
          v-model.number="viewModel.adjuncts.retentionPeriod"
          type="number"
          min="0"
          max="1000000"
        >
        <label
          class=""
          for="cboVirtualMachine"
        >Specify the number of concurrent replays required
          <TooltipIcon text="If greater 5 Million conversations to be saved or greater than 40 concurrent replays then view requirements in ISS" />
        </label>
        <select
          id="cboVirtualMachine"
          v-model.number="viewModel.adjuncts.virtualMachineId"
        >
          <option
            key="126"
            value="126"
            title="Small server"
          >≤ 10 Concurrent replays</option>
          <option
            key="127"
            :selected="true"
            value="127"
            title="Medium server"
          >≤ 20 Concurrent replays</option>
          <option
            key="128"
            value="128"
            title="Large server"
          >≤ 40 Concurrent replays</option>
        </select>
      </div>
      <br>
      <span style="font-weight: 500">Add-Ons</span>
      <br>
      <label
        class=""
        for="chkRightfaxServer"
      >Rightfax Server
        <TooltipIcon text="Enables servers for Right Fax. License for Right Fax needs to be ordered separately" />
      </label>
      <input
        id="chkRightfaxServer"
        v-model="viewModel.adjuncts.rightfax"
        type="checkbox"
        class="small"
      >
      <div
        v-if="viewModel.adjuncts.rightfax"
        class="InputError">Please contact supplier for the Rightfax license costs separately
      </div>
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('UserProfilesMitelUC')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('SelectService')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'

import { mapActions } from 'vuex'
export default {
  name: 'AdjunctsMitelUC',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteStoreMixin],
  data () {
    return {
      quote: {},
      viewModel: {
        quoteId: 0,
        adjuncts: {
          ascEssentials: 0,
          ascPremier: 0,
          ascElite: 0,
          maxConcurrentCalls: 0,
          numCallsPerAgentPerDay: 0,
          avgCallDuration: 0,
          retentionPeriod: 0,
          rightFax: false,
          dataTrackCallLogging: false,
          virtualMachineId: 126
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconCommunicate () {
      return require(`../../../assets/icons/iconCommunicate.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidMaxConcurrentCalls () {
      var value = this.viewModel.adjuncts.maxConcurrentCalls
      return (this.totalCallRecordingUserCount > 0 ? this.iQuoteFunctions_IsInteger(value) && value >= 1 : true)
    },
    isValidNumCallsPerAgentPerDay () {
      var value = this.viewModel.adjuncts.numCallsPerAgentPerDay
      return (this.totalCallRecordingUserCount > 0 ? this.iQuoteFunctions_IsInteger(value) && value >= 1 : true)
    },
    isValidAvgCallDuration () {
      var value = this.viewModel.adjuncts.avgCallDuration
      return (this.totalCallRecordingUserCount > 0 ? this.iQuoteFunctions_IsInteger(value) && value >= 1 : true)
    },
    isValidRetentionPeriod () {
      var value = this.viewModel.adjuncts.retentionPeriod
      return (this.totalCallRecordingUserCount > 0 ? this.iQuoteFunctions_IsInteger(value) && value >= 1 : true)
    },
    totalCallRecordingUserCount () {
      return this.viewModel.adjuncts.ascEssentials + this.viewModel.adjuncts.ascPremier + this.viewModel.adjuncts.ascElite
    }
  },
  async mounted () {
    this.viewModel.quoteId = this.$store.getters.quote.id
    var quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC

    if (quoteTechnologyMitelUC.id === undefined) {
      this.viewModel.id = await this.getQuoteTechnologyId(this.viewModel.quoteId, this.iQuoteConstants.technologies.mitelUC_MSP)
      this.viewModel.userProfiles = await this.getQuoteTechnologyUserProfiles(this.viewModel.id)
      this.viewModel.options = await this.getQuoteOptionsMitelUC(this.viewModel.quoteId)
      this.viewModel.adjuncts = await this.getQuoteMitelUCAdjuncts(this.viewModel.quoteId)

      this.setQuoteTechnologyMitelUC(this.viewModel)
    } else {
      this.viewModel.id = await this.getQuoteTechnologyId(this.viewModel.quoteId, this.iQuoteConstants.technologies.mitelUC_MSP)
      this.viewModel.adjuncts = quoteTechnologyMitelUC.adjuncts ? quoteTechnologyMitelUC.adjuncts : this.viewModel.adjuncts
    }

    if (this.viewModel.adjuncts.virtualMachineId === undefined) {
      this.viewModel.adjuncts.virtualMachineId = this.iQuoteConstants.virtualMachines.ASC_SMALL
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyMitelUC'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      if (!this.isValidMaxConcurrentCalls) {
        ValidationFailed = true
      }
      if (!this.isValidNumCallsPerAgentPerDay) {
        ValidationFailed = true
      }
      if (!this.isValidAvgCallDuration) {
        ValidationFailed = true
      }
      if (!this.isValidRetentionPeriod) {
        ValidationFailed = true
      }

      if (ValidationFailed) {
        alert('Please correct the inputs on this page')
        return
      } else {
        // Save to DB only if valid data is supplied
        this.viewModel.adjuncts = await this.saveQuoteMitelUCAdjuncts(this.viewModel.adjuncts)
      }

      var quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC

      quoteTechnologyMitelUC.adjuncts = this.viewModel.adjuncts

      this.setQuoteTechnologyMitelUC(quoteTechnologyMitelUC)
      this.setCurrentQuoteState(page)
    },
    isValid (value) {
      return (this.iQuoteFunctions_IsInteger(value) && value >= 1)
    },
    onInputItemFocusLost (entry) {
      return entry === undefined || entry === '' ? 0 : entry
    }
  }
}
</script>
<style scoped>
  .small{width:60px;}
</style>
