<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconCommunicate"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="quote.quoteOpportunityId + ' (revision ' + quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <p>Select the number of user profiles required:</p>
      <br><br>
      <table>
        <tr>
          <th style="text-align:left;">Profile
            <TooltipIcon text="Description of user types, full details are covered in the <u>Service Description</u>.  Profiles may vary dependant on vendor" />
          </th>
          <th>Min Commit Users<br>
            <TooltipIcon text="This covers the minimum number of agents committed to in the customer MSA and the actual number of users required for operation" />
          </th>
          <th>Maximum<br>
            <TooltipIcon text="The maximum number of agents configures the size of the ICON infrastructure and maximum size the customer may grow to.  This value requires to be 20% above the Minimum Commit Users. Adjust to required level" />
          </th>
        </tr>
        <tr><td colspan="3">&nbsp;</td></tr>
        <tr
          v-for="userProfile in quoteTechnologyMitelUC.userProfiles"
          :key="userProfile.technologyUserProfileId"
        >
          <td
            :class="{InputError: !isValidUserProfile(userProfile)}"
            style="width:220px; text-align:left"
          >{{ userProfile.description }}
            <TooltipIcon
              v-if="userProfile.additionalInfo != ''"
              :text="userProfile.additionalInfo" />
          </td>
          <td>
            <input
              v-model.number="userProfile.minCommitment"
              type="number"
              min="0"
              max="1000000"
              @keyup="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
              @change="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
              @blur="checkProfile(userProfile)"
            >
          </td>
          <td>
            <input
              v-model.number="userProfile.maxAllowed"
              type="number"
              min="0"
              max="1000000"
            >
          </td>
          <td>
            <label
              v-if="userProfile.minCommitment > 0 && userProfile.warning != ''"
              class="InputError"
              style="width:620px; text-align:left"
            >{{ userProfile.warning }}
            </label>
          </td>
        </tr>
        <tr
          v-if="totalUserLicenseCount > 8333 || totalUserMaxAllowedCount > 10000"
          style="width:620px; text-align:left">
          <td
            colspan="3"
            class="InputError">Pre-sales must be engaged if the Maximum number of users is above 10,000 or minumum Commit above 8,333</td>
        </tr>
      </table>
      <br><br>
      <label
        for="chkWebConf"
      >Audio/Web Conferencing
        <TooltipIcon
          width="500"
          text="AWC is a feature-rich audio conferencing solution with simple to use intuitive web conferencing facilities" />
      </label>
      <input
        v-if="quoteTechnologyMitelUC.options !== undefined"
        id="chkWebConf"
        v-model.number="quoteTechnologyMitelUC.options.webConferencingIsRequired"
        type="checkbox"
      >
      <label
        :class="{InputError:!isValidAdditionalWebConferencingPorts}"
        for="txtAdditionalConfPorts"
      >Additional Audio/Web Conferencing Ports
        <TooltipIcon
          width="500"
          text="Select up to 200 AWC ports" />
      </label>
      <input
        id="txtAdditionalConfPorts"
        v-model.number="quoteTechnologyMitelUC.options.additionalWebConferencingPorts"
        type="text"
      >

      <br><br>
      <label
        for="txtAdditionalVoicemailBoxes"
      >Additional Voicemail Boxes
        <TooltipIcon
          width="500"
          text="Additional mailboxes can be added, for example in hospitals where departments don't have a physical phone" />
      </label>
      <input
        id="txtAdditionalVoicemailBoxes"
        v-model.number="quoteTechnologyMitelUC.options.additionalVoicemailBoxes"
        type="text"
      >
      <label
        for="chkOIGServers"
      >OIG Servers
        <TooltipIcon
          width="500"
          text="Unselect if Ultimate users do not need Google/SFDC integration or advanced call control integration is required" />

      </label>
      <input
        v-if="quoteTechnologyMitelUC.options !== undefined"
        id="chkOIGServers"
        v-model.number="quoteTechnologyMitelUC.options.oigServersAreRequired"
        type="checkbox"
      >

      <label
        for="txtConcurrentRemoteWorkerCalls"
      >Concurrent Calls to Remote Workers
        <TooltipIcon
          width="500"
          text="Enter number of concurrent calls to remote workers required here which enables DMX server sizing" />
      </label>
      <input
        id="txtConcurrentRemoteWorkerCalls"
        v-model.number="quoteTechnologyMitelUC.options.concurrentCallsToRemoteWorkers"
        type="text"
      >
      <br><br><br>
      <h4
      >Does the customer require their own public domain space or will they use ICON anywhere.net domain
        <TooltipIcon
          width="500"
          text="Customer needs to purchase these Domain names themselves if they choose to use their own public name space" />
      </h4>
      <br>
      <input
        id="1"
        v-model.number="quoteTechnologyMitelUC.options.publicNameSpaceOptionId"
        type="radio"
        name="1"
        value="1">ICON anywhere.net domain
      <br>
      <input
        id="2"
        v-model.number="quoteTechnologyMitelUC.options.publicNameSpaceOptionId"
        type="radio"
        name="2"
        value="2">Own public name space
      <label
        v-if="quoteTechnologyMitelUC.options.publicNameSpaceOptionId === iQuoteConstants.PublicNameSpaceOptions.CUSTOMER_PROVIDES"
        class="InputError"
        style="width:620px; text-align:left">Customer needs to purchase these Domain names themselves
      </label>
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('MitelUCService')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('AdjunctsMitelUC')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { mapActions } from 'vuex'
export default {
  name: 'UserProfileMitelUC',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteStoreMixin, iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions],
  data () {
    return {
      quote: {},
      quoteTechnologyMitelUC: {
        userProfiles: [],
        options: {
          webConferencingIsRequired: false,
          oigServersAreRequired: false,
          additionalWebConferencingPorts: 0,
          additionalVoicemailBoxes: 0,
          concurrentCallsToRemoteWorkers: 0,
          publicNameSpaceOptionId: 1 /* MAINTEL_PROVIDES */
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconCommunicate () {
      return require(`../../../assets/icons/iconCommunicate.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidAdditionalWebConferencingPorts () {
      var value = this.quoteTechnologyMitelUC.options.additionalWebConferencingPorts
      return (this.iQuoteFunctions_IsInteger(value) && value >= 0 && value <= 200)
    },
    totalUserLicenseCount () {
      var totalUserCount = 0

      if (this.quoteTechnologyMitelUC.userProfiles !== undefined) {
        for (let index = 0; index < this.quoteTechnologyMitelUC.userProfiles.length; index++) {
          const userProfile = this.quoteTechnologyMitelUC.userProfiles[index]
          if (this.isValidUserProfile(userProfile)) {
            totalUserCount += userProfile.minCommitment
          }
        }
      }
      return totalUserCount
    },
    totalUserMaxAllowedCount () {
      var totalUserCount = 0

      if (this.quoteTechnologyMitelUC.userProfiles !== undefined) {
        for (let index = 0; index < this.quoteTechnologyMitelUC.userProfiles.length; index++) {
          const userProfile = this.quoteTechnologyMitelUC.userProfiles[index]
          if (this.isValidUserProfile(userProfile)) {
            totalUserCount += userProfile.maxAllowed
          }
        }
      }
      return totalUserCount
    }
  },
  async mounted () {
    this.quote = this.$store.getters.quote
    var quoteTechnologyMitelUCStore = this.$store.getters.quoteTechnologyMitelUC

    if (quoteTechnologyMitelUCStore.id === undefined || quoteTechnologyMitelUCStore.id === 0) {
      var userProfiles
      var adjuncts
      var options

      this.quoteTechnologyMitelUC.id = await this.getQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.mitelUC_MSP)

      if (this.quoteTechnologyMitelUC.id === 0) {
        this.quoteTechnologyMitelUC.id = await this.addQuoteTechnologyId(this.quote.id, this.iQuoteConstants.technologies.mitelUC_MSP)

        // Set default values for new technology on quote
        userProfiles = this.iQuoteStore_getDefaultQuoteTechnologyUserProfiles(this.iQuoteConstants.technologies.mitelUC_MSP, this.quoteTechnologyMitelUC.id)
        adjuncts = {
          id: 0,
          quoteId: this.quote.id,
          rightFax: false,
          dataTrackCallLogging: false,
          maxConcurrentCalls: 0,
          numCallsPerAgentPerDay: 0,
          avgCallDuration: 0,
          retentionPeriod: 0,
          ascEssentials: 0,
          ascPremier: 0,
          ascElite: 0
        }
        options = {
          id: 0,
          quoteId: this.quote.id,
          webConferencingIsRequired: false,
          oigServersAreRequired: false,
          additionalWebConferencingPorts: 0,
          additionalVoicemailBoxes: 0,
          concurrentCallsToRemoteWorkers: 0,
          publicNameSpaceOptionId: 1 /* MAINTEL_PROVIDES */
        }
      } else {
        // Retrieve data from DB
        userProfiles = await this.getQuoteTechnologyUserProfiles(this.quoteTechnologyMitelUC.id)
        adjuncts = await this.getQuoteMitelUCAdjuncts(this.quote.id)
        options = await this.getQuoteOptionsMitelUC(this.quote.id)
      }

      if (userProfiles.length > 0) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyMitelUC, 'userProfiles', userProfiles)
      }

      if (adjuncts.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyMitelUC, 'adjuncts', adjuncts)
      }

      if (options.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.quoteTechnologyMitelUC, 'options', options)
      }

      this.setQuoteTechnologyMitelUC(this.quoteTechnologyMitelUC)
    } else {
      this.quoteTechnologyMitelUC = quoteTechnologyMitelUCStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteTechnologyMitelUC'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      var minWasGreaterThanMax = false
      var maxNotHighEnough = false

      for (let index = 0; index < this.quoteTechnologyMitelUC.userProfiles.length; index++) {
        const userProfile = this.quoteTechnologyMitelUC.userProfiles[index]
        if (userProfile.minCommitment > userProfile.maxAllowed) {
          minWasGreaterThanMax = true
        }
        if (userProfile.maxAllowed < (userProfile.minCommitment * 1.2)) {
          maxNotHighEnough = true
        }
        if (!this.isValidUserProfile(userProfile)) {
          ValidationFailed = true
        }
      }

      if (minWasGreaterThanMax) {
        this.iQuoteFunctions_ShowError('min commit cannot exceed maximum value')
        return
      } else if (maxNotHighEnough) {
        this.iQuoteFunctions_ShowError('maximum value must be at least 20% higher than the min commit')
        return
      } else if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      } else if (this.totalUserLicenseCount < 10) {
        if (page === 'IconCommunicate' && this.totalUserLicenseCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter at least 10 users, or enter 0 (this will remove mitelUC from the quote)')
          return
        } else if (page === 'AdjunctsMitelUC') {
          this.iQuoteFunctions_ShowError('Please enter at least 10 users')
          return
        }
      } else if (this.quoteTechnologyMitelUC.options.publicNameSpaceOptionId === null) {
        this.iQuoteFunctions_ShowError('Please choose a public namespace option')
        return
      } else if (this.totalUserMaxAllowedCount > 10000) {
        if (page === 'IconCommunicate' && this.totalUserMaxAllowedCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter 10,000 or less users, or enter 0 (this will remove mitelUC from the quote)')
        } else if (page === 'AdjunctsMitelUC') {
          this.iQuoteFunctions_ShowError('cannot support over 10,000 users')
        }
        return
      }

      if (this.totalUserLicenseCount > 0) {
        // update local store
        this.setQuoteTechnologyMitelUC(this.quoteTechnologyMitelUC)
      } else if (this.quoteTechnologyMitelUC.id !== undefined) {
        this.deleteQuoteTechnology(this.quoteTechnologyMitelUC.id)
        // delete from local store
        this.setQuoteTechnologyMitelUC({})
      }

      if (this.quoteTechnologyMitelUC.options.additionalWebConferencingPorts > 200) {
        alert('200 is the max supported AWC Ports')
        return
      }

      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied (even if pressing back rather than next)
        this.quoteTechnologyMitelUC.userProfiles = await this.saveQuoteTechnologyUserProfiles(this.quoteTechnologyMitelUC.userProfiles)
        this.quoteTechnologyMitelUC.options = await this.saveQuoteOptionsMitelUC(this.quoteTechnologyMitelUC.options)
      }

      // update local store
      this.setCurrentQuoteState(page)
    },
    isValidUserProfile (userProfile) {
      return userProfile.minCommitment <= userProfile.maxAllowed &&
                                          this.iQuoteFunctions_IsInteger(userProfile.minCommitment) &&
                                          this.iQuoteFunctions_IsInteger(userProfile.maxAllowed) &&
                                          userProfile.minCommitment >= 0 &&
                                          userProfile.maxAllowed >= 0 &&
                                          userProfile.maxAllowed >= (userProfile.minCommitment * 1.2)
    },
    checkProfile (userProfile) {
      if (userProfile.description === 'Ultimate') {
        this.quoteTechnologyMitelUC.options.oigServersAreRequired = (userProfile.maxAllowed > 0)
      }
    }
  }
}
</script>
<style scoped>
  table tr th {text-align: center;}
  table tr td {text-align: center;}
  .telsetsLabel {
    display: inline-block;
    width: 60px;
    margin: 20px 0;
    font-weight: 600;
  }
  .quotePanel{
    width: 600px;
  }
  .quotePanel label{
    width:350px;
  }
</style>
