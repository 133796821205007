<template>
  <div
    :class="selected"
    class="serviceTileContainer"
  >
    <div>
      <img
        :id="id"
        :src="imageSource"
        :alt="serviceCategoryName"
        @click="gotoPage(pageSource)"
      >
    </div>
    <div class="tileButtons" >
      <button @click="remove()">Remove</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'

export default {
  name: 'ServiceCategoryTile',
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteConstants, iQuoteFunctions],
  props: {
    serviceCategoryId: {
      type: Number,
      default: -1
    },
    serviceCategoryName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      page: 'ServiceCategories'
    }
  },
  computed: {
    selected () {
      var isSelected = ''

      if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.ukSip) {
        if (this.$store.getters.quoteGammaSIP.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.sipMigration) {
        if (this.$store.getters.quoteSIPMigrationProperties.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.internationalSip) {
        /* TODO: Add support for Colt here */
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.phoneLinePlus) {
        if (this.$store.getters.quotePhoneLinePlus.id !== undefined) {
          isSelected = 'selected'
        }
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.mitelUC) {
        if (this.$store.getters.quoteTechnologyMitelUC.id !== undefined || this.$store.getters.quoteTechnologyMitelUCE2.id !== undefined) {
          isSelected = 'selected'
        }
      } else {
      }

      return isSelected
    },
    imageSource () {
      if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.ukSip) {
        return require(`../../../assets/icons/ukSip.png`)
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.internationalSip) {
        return require(`../../../assets/icons/internationalSip.png`)
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.sipMigration) {
        return require(`../../../assets/icons/sipMigrationOffer.png`)
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.phoneLinePlus) {
        return require(`../../../assets/icons/phoneLinePlus.png`)
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.mitelUC) {
        return require(`../../../assets/icons/mitel.png`)
      } else {
        return require(`../../../assets/icons/notFoundTile.png`)
      }
    },
    pageSource () {
      if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.internationalSip) {
        return `InternationalSip`
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.ukSip) {
        return `sipGammaService`
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.sipMigration) {
        return `SipMigrationOffer`
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.phoneLinePlus) {
        return 'PhoneLinePlus'
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.mitelUC) {
        return 'mitelUCService'
      } else {
        return `SelectService`
      }
    },
    id () {
      if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.internationalSip) {
        return `btnInternationalSip`
      } else if (this.serviceCategoryId === this.iQuoteConstants.serviceCategories.ukSip) {
        return `btnUkSip`
      } else {
        return `btnUnknown`
      }
    }
  },
  mounted () {

  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setCurrentServiceCategory',
      'setCurrentTechnology',
      'setQuoteTechnologyAvayaAura',
      'setQuoteTechnologyCallmedia',
      'setQuoteTechnologyMitelCC',
      'setQuoteTechnologyMitelUC',
      'setQuoteTechnologyAvayaElite',
      'setQuoteTechnologyAvayaAACC',
      'setQuoteTechnologyCallmediaCXNow',
      'setQuoteTechnologyIconGateway',
      'setQuoteGammaSIP',
      'setQuoteSIPMigrationProperties',
      'setQuotePhoneLinePlus',
      'setQuoteTechnologyMitelUCE2'
    ]),
    gotoPage (page) {
      this.setCurrentServiceCategory(this.serviceCategoryId)

      this.setCurrentQuoteState(page)
    },
    async remove () {
      if (window.confirm('Remove configuration for this service?')) {
        var technologyId
        var quote = this.$store.getters.quote

        // Find all technologies under the selected service category and delete
        switch (this.serviceCategoryId) {
          case this.iQuoteConstants.serviceCategories.ukSip:
            var quoteGammaSIP = this.$store.getters.quoteGammaSIP
            if (quoteGammaSIP.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.gammaSIP)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteGammaSIP({})
            break
          case this.iQuoteConstants.serviceCategories.sipMigration:
            var quoteSIPMigrationProperties = this.$store.getters.quoteSIPMigrationProperties
            if (quoteSIPMigrationProperties.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.sipMigrationOffer)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteSIPMigrationProperties({})
            break
          case this.iQuoteConstants.serviceCategories.phoneLinePlus:
            var quotePhoneLinePlus = this.$store.getters.quotePhoneLinePlus
            if (quotePhoneLinePlus.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.phoneLinePlus)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuotePhoneLinePlus({})
            break
          case this.iQuoteConstants.serviceCategories.mitelUC:

            var quoteTechnologyMitelUCE2 = this.$store.getters.quoteTechnologyMitelUCE2
            if (quoteTechnologyMitelUCE2.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelUC_E2)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            var quoteTechnologyMitelUC = this.$store.getters.quoteTechnologyMitelUC
            if (quoteTechnologyMitelUC.id !== undefined) {
              technologyId = await this.getQuoteTechnologyId(quote.id, this.iQuoteConstants.technologies.mitelUC_MSP)
              // Delete from DB
              this.deleteQuoteTechnology(technologyId)
            }

            // Clear form details from store
            this.setQuoteTechnologyMitelUC({})
            this.setQuoteTechnologyMitelUCE2({})
        }
        this.$emit('removed')
        this.iQuoteFunctions_ShowMessage('Service Removed')
      }
    }
  }
}
</script>
<style scoped>
  .serviceTileContainer {
    display: inline-block;
    margin: 20px 0 0 20px;
    background-color: #fff;
    border: solid 2px #235622;
    border-radius: 8px;
  }
  .serviceTileContainer:hover {
    background-color: rgb(199, 209, 199);
    border: solid 2px #000;
  }
  .serviceTileContainer.selected {
    display: inline-block;
    margin: 20px 0 0 20px;
    background-color: rgb(199, 209, 199);
    border: solid 2px #235622;
  }
  .serviceTileContainer.selected:hover {
    background-color: rgb(122, 136, 122);
    border: solid 2px #000;
  }
  .serviceTileContainer img {
    width:140px;
    cursor: pointer;
  }
  .serviceTileContainer .tileButtons {
    visibility:hidden;
  }
  .serviceTileContainer.selected .tileButtons {
    visibility:visible;
  }
</style>
