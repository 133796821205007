<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="iconRingCentral"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="ringCentralByocComponent.quote.quoteOpportunityId + ' (revision ' + ringCentralByocComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br>
      <h3>RingCentral</h3>
      <br>
      <label
        for="numRingCentralUsers"
      >Number of RingCentral Users<TooltipIcon
        text="Number of RingCentral Users end users" />
      </label>
      <input
        id="numRingCentralUsers"
        v-model.number="totalRingCentralVoiceUserCount"
        class="small"
        type="number"
        min="0"
        max="100000"
        readonly
      >
      <label
        :class="{InputError:!isValidNumPBXUsers}"
        for="numPBXUsers"
      >Number of PBX Users<TooltipIcon
        text="Number of PBX End Users required to integrate with" />
      </label>
      <input
        id="numPBXUsers"
        v-model.number="ringCentralByocComponent.ringCentral.options.numPBXUsers"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <label
        for="numsipChannelsCalculated"
      >Trunk Ratio<TooltipIcon
        text="Number of users per SIP channel" />
      </label>
      <select
        id="cboUsersPerSipChannel"
        v-model.number="ringCentralByocComponent.ringCentral.options.usersPerSipChannel"
      >
        <option
          v-for="item in ringCentralByocComponent.usersPerSipChannelOptions"
          :key="item.text"
          :value="item.val"
          :title="item.text"
        >
          {{ item.text }}
        </option>
      </select>
      <label
        for="numsipChannelsCalculated"
      >Total Number of SIP Channels
      </label>
      <input
        id="numsipChannelsCalculated"
        v-model.number="sipChannelsCalculated"
        class="small"
        type="number"
        min="0"
        max="100000"
        readonly
      >
      <p class="InputError">If SIP Services are ordered with RingCentral, please ensure the correct number of SIP channels have been configured</p>
      <label
        :class="{InputError:!isValidAdditionalSbcLicencesForInternalCalls}"
        for="numAdditionalSbcLicencesForInternalCalls"
      >Number of SBC Channels for Internal Calls
      </label>
      <input
        id="numAdditionalSbcLicencesForInternalCalls"
        v-model.number="ringCentralByocComponent.ringCentral.options.additionalSbcLicencesForInternalCalls"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <br><br>
      <label
        :class="{InputError:!isValidNumAdditionalSIPChannels}"
        for="numAdditionalSIPChannels"
      >Number of Additional SIP Channels
      </label>
      <input
        id="numAdditionalSIPChannels"
        v-model.number="ringCentralByocComponent.ringCentral.options.numAdditionalSIPChannels"
        class="small"
        type="number"
        min="0"
        max="100000"
      >
      <br><br>
      <label
        class=""
        for="cboNumberPorting"
      >Number Porting
      </label>
      <select
        id="cboNumberPorting"
        v-model.number="ringCentralByocComponent.ringCentral.options.teamsConnectorNumberPortingOptionId"
        @change="setNumDDIs($event)"
      >
        <option
          key="1"
          value="1"
          title="Number Porting"
        >Number Porting</option>
        <option
          key="2"
          value="2"
          title="New DDI"
        >New DDI</option>
      </select>
      <label
        v-if="ringCentralByocComponent.ringCentral.options.teamsConnectorNumberPortingOptionId === 2"
        :class="{InputError:!isValidNumNewDDIs}"
        for="numNewDDI"
      >New DDI<TooltipIcon
        text="If you require new DDI numbers please state how many you need" />
      </label>
      <input
        v-if="ringCentralByocComponent.ringCentral.options.teamsConnectorNumberPortingOptionId === 2"
        id="numNewDDI"
        v-model.number="ringCentralByocComponent.ringCentral.options.newDDIs"
        class="small"
        type="number"
        min="0"
        max="100000"
      >

      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('UserProfilesRingCentralCC')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="finishService"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteCalculationsCommonMixin } from '../../../mixins/iQuote/iQuoteCalculationsCommonMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'

import { mapActions } from 'vuex'
export default {
  name: 'RingCentralByoc',
  components: {
    TooltipIcon
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsCommonMixin, iQuoteStoreMixin],
  data () {
    return {
      ringCentralByocComponent: {
        quote: {},
        ringCentral: {
          options: {
            id: 0,
            numRingCentralUsers: 0,
            numPBXUsers: 0,
            sipChannelsCalculated: 0,
            additionalSbcLicencesForInternalCalls: 0,
            teamsConnectorNumberPortingOptionId: 1, // 1 = Number Porting, 2 = New DDI
            usersPerSipChannel: 5,
            numAdditionalSIPChannels: 0
          }
        },
        usersPerSipChannelOptions: [{ text: '0', val: 0 }, { text: '1', val: 1 }, { text: '1.2', val: 120 }, { text: '3', val: 3 }, { text: '5', val: 5 }, { text: '7', val: 7 }, { text: '10', val: 10 }]
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    iconRingCentral () {
      return require(`../../../assets/icons/ringCentral.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    isValidNumPBXUsers () {
      var value = this.ringCentralByocComponent.ringCentral.options.numPBXUsers
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumRingCentralUsers () {
      var value = this.ringCentralByocComponent.ringCentral.options.numRingCentralUsers
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidAdditionalSbcLicencesForInternalCalls () {
      var value = this.ringCentralByocComponent.ringCentral.options.additionalSbcLicencesForInternalCalls
      return (this.iQuoteFunctions_IsInteger(value))
    },
    isValidNumAdditionalSIPChannels () {
      var value = this.ringCentralByocComponent.ringCentral.options.numAdditionalSIPChannels
      return (this.iQuoteFunctions_IsInteger(value))
    },
    sipChannelsCalculated () {
      if (this.ringCentralByocComponent.ringCentral.options.usersPerSipChannel === 0) {
        return 0
      } else if (this.ringCentralByocComponent.ringCentral.options.usersPerSipChannel === 120) {
        return Math.ceil((this.totalRingCentralVoiceUserCount + this.ringCentralByocComponent.ringCentral.options.numPBXUsers) * 1.2)
      }

      return Math.ceil((this.totalRingCentralVoiceUserCount + this.ringCentralByocComponent.ringCentral.options.numPBXUsers) / this.ringCentralByocComponent.ringCentral.options.usersPerSipChannel)
    },
    isValidNumNewDDIs () {
      var value = this.ringCentralByocComponent.ringCentral.options.newDDIs
      return (this.iQuoteFunctions_IsInteger(value))
    },
    totalRingCentralVoiceUserCount () {
      var total = 0
      if (this.ringCentralByocComponent.ringCentral.ucUserProfiles) {
        this.ringCentralByocComponent.ringCentral.ucUserProfiles.forEach(item => {
          if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.BASIC_USER ||
              item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ESSENTIAL_USER ||
              item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.STANDARD_USER ||
              item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.PREMIUM_USER ||
              item.technologyUserProfileId === this.iQuoteConstants.ringCentralUCUserProfiles.ULTIMATE_USER
          ) {
            total += item.minCommitment
          }
        })
        this.ringCentralByocComponent.ringCentral.ccUserProfiles.forEach(item => {
          if (item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.ESSENTIALS_AGENT ||
          item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.STANDARD_AGENT ||
          item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.PREMIUM_AGENT ||
          item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.ULTIMATE_AGENT ||
          item.technologyUserProfileId === this.iQuoteConstants.ringCentralCCUserProfiles.UNLIMITED_AGENT
          ) {
            total += item.minCommitment
          }
        })
      }

      return total
    }
  },
  async mounted () {
    this.ringCentralByocComponent.quote = this.$store.getters.quote
    this.ringCentralByocComponent.ringCentral = this.iQuoteStore_GetQuoteRingCentral
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteRingCentral'
    ]),
    async gotoPage (page) {
      this.setCurrentQuoteState(page)
    },
    setNumDDIs (event) {
      var option = event.target.value
      if (option === '2' /* New DDI */) {
        this.ringCentralByocComponent.ringCentral.options.newDDIs = this.sipChannelsCalculated
      } else {
        this.ringCentralByocComponent.ringCentral.options.newDDIs = 0
      }
    },
    async finishService () {
      var ValidationFailed = false
      if (!this.isValidNumPBXUsers) {
        ValidationFailed = true
      }

      if (!ValidationFailed) {
        // Save to DB only if valid data is supplied
        this.ringCentralByocComponent.ringCentral.options.sipChannelsCalculated = this.sipChannelsCalculated

        await this.saveQuoteOptionsRingCentral(this.ringCentralByocComponent.ringCentral.options)
        this.setQuoteRingCentral(this.ringCentralByocComponent.ringCentral)
        this.setCurrentQuoteState('SelectService')
      } else {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
      }
    }
  }
}
</script>
<style scoped>
  .indentOnce {padding-left: 50px;}
  .quotePanel {width: 620px}
  .quotePanel label {width:320px;}
  .small {width:60px;}
  .hidden {visibility: hidden;}

  #cboUsersPerSipChannel {
    width: 50px
  }

</style>
