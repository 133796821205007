import { mapActions } from 'vuex'
import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsCallmediaMixin } from './iQuoteCalculationsCallmediaMixin'
import { iQuoteCalculationsCallmediaCXNowMixin } from './iQuoteCalculationsCallmediaCXNowMixin'
import { iQuoteCalculationsMitelUCMixin } from './iQuoteCalculationsMitelUCMixin'
import { iQuoteCalculationsMitelUCE2Mixin } from './Calculations/iQuoteCalculationsMitelUCE2Mixin'
import { iQuoteCalculationsMitelCCMixin } from './iQuoteCalculationsMitelCCMixin'
import { iQuoteCalculationsAvayaEliteMixin } from './iQuoteCalculationsAvayaEliteMixin'
import { iQuoteCalculationsAvayaAACCMixin } from './iQuoteCalculationsAvayaAACCMixin'
import { iQuoteCalculationsAvayaAuraMixin } from './iQuoteCalculationsAvayaAuraMixin'
import { iQuoteCalculationsExpansionsMixin } from './iQuoteCalculationsExpansionsMixin'
import { iQuoteCalculationsGammaSIPMixin } from './iQuoteCalculationsGammaSIPMixin'
import { iQuoteCalculationsSIPMigrationMixin } from './iQuoteCalculationsSIPMigrationMixin'
import { iQuoteQuoteVMsRequiredAPIMixin } from './iQuoteQuoteVMsRequiredAPIMixin'
import { iQuoteQuoteLicensesRequiredAPIMixin } from './iQuoteQuoteLicensesRequiredAPIMixin'
import { iQuoteQuoteBladeServersAPIMixin } from './iQuoteQuoteBladeServersAPIMixin'
import { iQuoteQuoteUnitCostsAPIMixin } from './iQuoteQuoteUnitCostsAPIMixin'
import { iQuoteCalculationsBladeServersMixin } from './iQuoteCalculationsBladeServersMixin'
import { iQuoteCalculationsUnitCostsMixin } from './iQuoteCalculationsUnitCostsMixin'
import { iQuoteCalculationsDesignPackEntriesMixin } from './iQuoteCalculationsDesignPackEntriesMixin'
import { iQuoteCalculationsCustomerSummaryEntriesMixin } from './iQuoteCalculationsCustomerSummaryEntriesMixin'
import { iQuoteQuoteDesignPackEntriesAPIMixin } from './iQuoteQuoteDesignPackEntriesAPIMixin'
import { iQuoteQuoteCustomerSummaryEntriesAPIMixin } from './iQuoteQuoteCustomerSummaryEntriesAPIMixin'
import { iQuoteCalculationsTeamsConnectorMixin } from './Calculations/iQuoteCalculationsTeamsConnectorMixin'
import { iQuoteCalculationsGenesysMixin } from './Calculations/iQuoteCalculationsGenesysMixin'
import { iQuoteCalculationsAvayaAXPMixin } from './Calculations/iQuoteCalculationsAvayaAXPMixin'
import { iQuoteCalculationsRingCentralMixin } from './Calculations/iQuoteCalculationsRingCentralMixin'
import { iQuoteCalculationsPhoneLinePlusMixin } from './Calculations/iQuoteCalculationsPhoneLinePlusMixin'
import { iQuoteCalculationsSycurioMixin } from './Calculations/iQuoteCalculationsSycurioMixin'
import { iQuoteCalculationsUCAnalyticsMixin } from './Calculations/iQuoteCalculationsUCAnalyticsMixin'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { iQuoteCalculationsNetCallMixin } from './Calculations/iQuoteCalculationsNetCallMixin'

import { iQuoteQuoteTechnologyAPIMixin } from './iQuoteQuoteTechnologyAPIMixin'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'

export const iQuoteCalculationsCommonMixin = {
  data () {
    return {
      quote: {},
      licensesRequired: [],
      quoteTechnologyAvayaAura: {},
      quoteTechnologyMitelUC: {},
      quoteTechnologyAvayaElite: {},
      quoteTechnologyAvayaAACC: {},
      quoteTechnologyCallmedia: {},
      quoteTechnologyIconGateway: {},
      quoteTechnologyMitelCC: {},
      unitCostsRequired: [],
      quoteBladeServers: [],
      designPackEntries: [],
      quoteCustomerSummaryEntries: [],
      unitCosts: {},
      gammaSip: {},
      quoteSIPMigrationProperties: {},
      iQuoteStoreMixin
    }
  },
  mixins: [
    iQuoteConstants,
    iQuoteQuoteTechnologyAPIMixin,
    iQuoteQuoteDesignPackEntriesAPIMixin,
    iQuoteCalculationsDesignPackEntriesMixin,
    iQuoteCalculationsUnitCostsMixin,
    iQuoteQuoteUnitCostsAPIMixin,
    iQuoteCalculationsAvayaAuraMixin,
    iQuoteCalculationsCallmediaMixin,
    iQuoteCalculationsGammaSIPMixin,
    iQuoteCalculationsSIPMigrationMixin,
    iQuoteCalculationsBladeServersMixin,
    iQuoteQuoteVMsRequiredAPIMixin,
    iQuoteQuoteBladeServersAPIMixin,
    iQuoteQuoteLicensesRequiredAPIMixin,
    iQuoteCalculationsAvayaEliteMixin,
    iQuoteCalculationsMitelUCMixin,
    iQuoteCalculationsMitelUCE2Mixin,
    iQuoteCalculationsMitelCCMixin,
    iQuoteCalculationsCallmediaCXNowMixin,
    iQuoteCalculationsAvayaAACCMixin,
    iQuoteCalculationsCustomerSummaryEntriesMixin,
    iQuoteQuoteCustomerSummaryEntriesAPIMixin,
    iQuoteCalculationsExpansionsMixin,
    iQuoteCalculationsTeamsConnectorMixin,
    iQuoteCalculationsPhoneLinePlusMixin,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteCalculationsGenesysMixin,
    iQuoteCalculationsRingCentralMixin,
    iQuoteCalculationsSycurioMixin,
    iQuoteCalculationsUCAnalyticsMixin,
    iQuoteCalculationsAvayaAXPMixin,
    iQuoteCalculationsNetCallMixin
  ],
  computed: {
    calculationsCommonMixin_isExpansionOnlyQuote () {
      return this.iQuoteStore_GetQuoteExpansions.id !== undefined &&
             this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined &&
             this.iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined &&
             this.iQuoteStore_GetQuoteTechnologyAvayaElite.id === undefined &&
             this.iQuoteStore_GetQuoteTechnologyCallmedia.id === undefined &&
             this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow.id === undefined &&
             this.iQuoteStore_GetQuoteTechnologyIconGateway.id === undefined &&
             this.iQuoteStore_GetQuoteSIPMigrationProperties.id === undefined &&
             this.iQuoteStore_GetGammaSip.id === undefined &&
             this.iQuoteStore_GetQuoteTechnologyMitelCC.id === undefined &&
             this.iQuoteStore_GetQuoteSycurio.id === undefined &&
             this.iQuoteStore_GetQuoteUCAnalytics.id === undefined
    },
    /*
     * RECURRING COSTS
     */
    calculationsCommonMixin_totalYr1Discount () {
      var total = 0

      var licenses = this.iQuoteStore_GetLicenses
      var licensesRequired = this.iQuoteStore_GetQuoteLicensesRequired

      for (let index = 0; index < licensesRequired.length; index++) {
        const licenseRequired = licensesRequired[index]

        licenses.forEach(availableLicense => {
          if (availableLicense.id === licenseRequired.licenseId) {
            total += licenseRequired.quantity * availableLicense.yr1Discount
          }
        })
      }

      total = Number(total.toFixed(2))

      return total
    },
    calculationsCommonMixin_allowedTermLengths () {
      // Initially allow all term lengths
      var yrs = [ 12, 24, 36, 48, 60 ]

      // Go through each added service and remove any term lengths that it doesn't support.  The output
      // at the end of this function  will be a list of term lengths that are supported by all of the selected services.
      // if they have none in common, then the returned list will be empty.

      if (this.iQuoteStore_GetGammaSip.id !== undefined) {
        yrs = yrs.filter(e => e !== 24 && e !== 48)
      }

      if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
        yrs = yrs.filter(e => e !== 12 && e !== 24)
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined) {
        yrs = yrs.filter(e => e !== 12 && e !== 24)
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaElite.id !== undefined) {
        yrs = yrs.filter(e => e !== 12 && e !== 24)
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAACC.id !== undefined) {
        yrs = yrs.filter(e => e !== 12 && e !== 24)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined) {
        yrs = yrs.filter(e => e !== 12 && e !== 24)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id !== undefined) {
        yrs = yrs.filter(e => e !== 24 && e !== 48)
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id !== undefined) {
        yrs = yrs.filter(e => e !== 12 && e !== 24)
      }

      if (this.iQuoteStore_GetTeamsConnector.id !== undefined) {
        yrs = yrs.filter(e => e !== 24 && e !== 48)
      }

      if (this.iQuoteStore_GetQuoteGenesys.id !== undefined) {
        yrs = yrs.filter(e => e !== 12 && e !== 24 && e !== 48)
      }

      if (this.iQuoteStore_GetQuoteAvayaAXP.id !== undefined) {
        yrs = yrs.filter(e => e !== 24 && e !== 48)
      }

      if (this.iQuoteStore_GetQuoteRingCentral.ucTechId !== undefined || this.iQuoteStore_GetQuoteRingCentral.ccTechId !== undefined) {
        yrs = yrs.filter(e => e !== 12 && e !== 24)
      }

      if (this.iQuoteStore_GetQuoteExpansions.id !== undefined) {
        yrs = yrs.filter(e => e !== 12 && e !== 24)
      }

      if (this.iQuoteStore_GetQuoteTechnologyIconGateway.id !== undefined) {
        yrs = yrs.filter(e => e !== 12 && e !== 24)
      }

      if (this.iQuoteStore_GetPhoneLinePlus.id !== undefined) {
        yrs = yrs.filter(e => e !== 24)
      }

      if (this.iQuoteStore_GetQuoteSycurio.id !== undefined) {
        yrs = yrs.filter(e => e !== 24 && e !== 48)
      }

      if (this.iQuoteStore_GetQuoteUCAnalytics.id !== undefined) {
        yrs = yrs.filter(e => e !== 24 && e !== 48)
      }

      console.log('Allowed Term Lengths', yrs.join())
      return yrs
    },
    totalRecurringAnnualPrice () {
      var total = 0
      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations) {
          total += license.annualPrice
        }
      })

      total = Number(total.toFixed(2))

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined && this.iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined) {
        /* Avaya and Mitel quotes don't amortise the upfront.  Instead they include the cost in license prices */
        total += this.totalInternalAnnualUnitCostsWithMarkup
      }

      total += this.annualTeamsUnitCostsPrice
      total += this.annualIconGatewayCostWithMarkup
      total += this.annualSipCostWithMarkup
      total += this.calcGenesysConnector_CSMCharges
      total += this.calcGenesysConnector_ManagedServicesCharges

      return total
    },
    iconContactTotalAnnualLicenseCost () {
      if (this.iQuoteStore_GetQuoteTechnologyCallmedia.id === undefined && this.iQuoteStore_GetQuoteTechnologyAvayaElite.id === undefined &&
          this.iQuoteStore_GetQuoteTechnologyAvayaAACC.id === undefined && this.iQuoteStore_GetQuoteTechnologyMitelCC.id === undefined) {
        return 0
      }

      var totalAnnualCost = 0

      if (this.iQuoteStore_GetQuoteTechnologyCallmedia.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyCallmedia.userProfiles.forEach(userProfile => {
          totalAnnualCost += userProfile.annualTotalCost
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaElite.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyAvayaElite.userProfiles.forEach(userProfile => {
          totalAnnualCost += userProfile.annualTotalCost
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAACC.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyAvayaAACC.userProfiles.forEach(userProfile => {
          totalAnnualCost += userProfile.annualTotalCost
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyMitelCC.userProfiles.forEach(userProfile => {
          totalAnnualCost += userProfile.annualTotalCost
        })
      }

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.licenseId === this.iQuoteConstants.licenses.AVAYA_CALL_RECORDING_TIER1 ||
            license.licenseId === this.iQuoteConstants.licenses.AVAYA_CALL_RECORDING_TIER2 ||
            license.licenseId === this.iQuoteConstants.licenses.AVAYA_CALL_RECORDING_TIER3) {
          totalAnnualCost += license.annualCost
        }
      })

      return totalAnnualCost
    },
    iconCommunicateTotalAnnualLicenseCost () {
      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id === undefined && this.iQuoteStore_GetQuoteTechnologyMitelUC.id === undefined) {
        return 0
      }

      var totalAnnualCost = 0

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyAvayaAura.userProfiles.forEach(userProfile => {
          totalAnnualCost += userProfile.annualTotalCost
        })
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined) {
        this.iQuoteStore_GetQuoteTechnologyMitelUC.userProfiles.forEach(userProfile => {
          totalAnnualCost += userProfile.annualTotalCost
        })
      }

      return totalAnnualCost
    },
    callmediaCXNowSipAnnualCost () {
      var totalAnnualCost = 0

      if (this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow.id === undefined) {
        return 0
      }

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.licenseId === this.iQuoteConstants.licenses.SIP_ACTIVE_36 || license.licenseId === this.iQuoteConstants.licenses.SIP_STANDBY_36) {
          totalAnnualCost += license.annualCost
        }
      })

      return totalAnnualCost
    },
    yr4To5TotalInternalAnnualUnitCost () {
      var Year4to5UnitCosts = [
        this.iQuoteConstants.unitCosts.ICON_REP_UC_SUP,
        this.iQuoteConstants.unitCosts.ICON_REP_CC_SUP,
        this.iQuoteConstants.unitCosts.ICON_VM_01,
        this.iQuoteConstants.unitCosts.ICON_OS_WIN_UC,
        this.iQuoteConstants.unitCosts.ICON_OS_WIN_CC,
        this.iQuoteConstants.unitCosts.ICON_DB_SQL_UC,
        this.iQuoteConstants.unitCosts.ICON_DB_SQL_CC,
        this.iQuoteConstants.unitCosts.ICON_MNT_01,
        this.iQuoteConstants.unitCosts.ICON_DC_01,
        this.iQuoteConstants.unitCosts.ICON_BAK_01_SUP,
        this.iQuoteConstants.unitCosts.ICON_BLD_STD_01_SUP,
        this.iQuoteConstants.unitCosts.ICON_BLD_HPR_01_SUP,
        this.iQuoteConstants.unitCosts.ICON_CHA_01_SUP,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_SF_STD,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_SF_CUSTOM,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_MS_365_STD,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_MS_365_CUSTOM,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_MS_PREMISE_STD,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_MS_PREMISE_CUSTOM,
        this.iQuoteConstants.unitCosts.CXNOW_CHATBOT_STANDALONE,
        this.iQuoteConstants.unitCosts.CXNOW_CHATBOT_INTEGRATION,
        this.iQuoteConstants.unitCosts.CXNOW_GAMMA_SIP_SETUP,
        this.iQuoteConstants.unitCosts.ICON_PORTAL,
        this.iQuoteConstants.unitCosts.ICON_COMMUNICATE_SBC_PORT_CHARGE,
        this.iQuoteConstants.unitCosts.ICON_INF_ILC,
        this.iQuoteConstants.unitCosts.ICON_PS_IOH_01,
        this.iQuoteConstants.unitCosts.ICON_PS_OOH_01,
        this.iQuoteConstants.unitCosts.SIP_CORE_BANDWIDTH_STANDARD_MONTHLY_ICON_COMMUNICATE,
        this.iQuoteConstants.unitCosts.SIP_CORE_BANDWIDTH_RESILIENT_MONTHLY_ICON_COMMUNICATE,
        this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_RECURRING,
        this.iQuoteConstants.unitCosts.ICON_SRG_ARC_TB_2
      ]
      var total = 0.0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (Year4to5UnitCosts.includes(unitCost.unitCostId) && unitCost.includeInCalculations) {
          total += unitCost.yearlyCost
        }
      })

      return total
    },
    yr1To3TotalInternalAnnualUnitCost () {
      var Year1to3UnitCosts = [
        this.iQuoteConstants.unitCosts.ICON_REP_UC_SUP,
        this.iQuoteConstants.unitCosts.ICON_REP_CC_SUP,
        this.iQuoteConstants.unitCosts.ICON_VM_01,
        this.iQuoteConstants.unitCosts.ICON_OS_WIN_UC,
        this.iQuoteConstants.unitCosts.ICON_OS_WIN_CC,
        this.iQuoteConstants.unitCosts.ICON_DB_SQL_UC,
        this.iQuoteConstants.unitCosts.ICON_DB_SQL_CC,
        this.iQuoteConstants.unitCosts.ICON_MNT_01,
        this.iQuoteConstants.unitCosts.ICON_DC_01,
        this.iQuoteConstants.unitCosts.ICON_INF_ILC,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_SF_STD,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_SF_CUSTOM,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_MS_365_STD,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_MS_365_CUSTOM,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_MS_PREMISE_STD,
        this.iQuoteConstants.unitCosts.CXNOW_CRM_MS_PREMISE_CUSTOM,
        this.iQuoteConstants.unitCosts.CXNOW_CHATBOT_STANDALONE,
        this.iQuoteConstants.unitCosts.CXNOW_CHATBOT_INTEGRATION,
        this.iQuoteConstants.unitCosts.CXNOW_GAMMA_SIP_SETUP,
        this.iQuoteConstants.unitCosts.ICON_PORTAL,
        this.iQuoteConstants.unitCosts.ICON_COMMUNICATE_SBC_PORT_CHARGE,
        this.iQuoteConstants.unitCosts.ICON_PS_IOH_01,
        this.iQuoteConstants.unitCosts.ICON_PS_OOH_01,
        this.iQuoteConstants.unitCosts.SIP_CORE_BANDWIDTH_STANDARD_MONTHLY_ICON_COMMUNICATE,
        this.iQuoteConstants.unitCosts.SIP_CORE_BANDWIDTH_RESILIENT_MONTHLY_ICON_COMMUNICATE,
        this.iQuoteConstants.unitCosts.ICON_SEC_CERTIFICATE_RECURRING,
        this.iQuoteConstants.unitCosts.ICON_SRG_ARC_TB_2
      ]
      var total = 0.0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (Year1to3UnitCosts.includes(unitCost.unitCostId) && unitCost.includeInCalculations) {
          total += unitCost.yearlyCost
        }
      })

      return this.iQuoteFunctions_RoundToFourDecimalPlaces(total)
    },
    totalInternalAnnualUnitCosts () {
      var total = 0

      switch (this.iQuoteStore_GetQuote.termLengthMonths) {
        case 12:
          total += this.yr1To3TotalInternalAnnualUnitCost
          break
        case 24:
          total += this.yr1To3TotalInternalAnnualUnitCost * 2
          break
        case 36:
          total += this.yr1To3TotalInternalAnnualUnitCost * 3
          break
        case 48:
          total += (this.yr1To3TotalInternalAnnualUnitCost * 3) + this.yr4To5TotalInternalAnnualUnitCost
          break
        case 60:
          total += (this.yr1To3TotalInternalAnnualUnitCost * 3) + (this.yr4To5TotalInternalAnnualUnitCost * 2)
          break
      }

      total = total / (this.iQuoteStore_GetQuote.termLengthMonths / 12)

      return total
    },
    upfrontIconInfrastructureCostAmortised () {
      var value = 0
      if (!this.iQuoteStore_GetQuote.chargeUpfront) {
        value = (this.upfrontIconInfrastructureCost / (this.iQuoteStore_GetQuote.termLengthMonths / 12))
      }

      return this.iQuoteFunctions_RoundToFourDecimalPlaces(value)
    },
    genesysLicensesAnnualCost () {
      var total = 0
      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations && license.serviceId === this.iQuoteConstants.services.genesys) {
          total += license.annualCost
        }
      })
      return total
    },
    ringCentralLicensesAnnualCost () {
      var total = 0
      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations && license.serviceId === this.iQuoteConstants.services.ringCentral) {
          total += license.annualCost
        }
      })
      return total
    },
    totalLicensesAnnualCost () {
      var total = 0
      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations) {
          total += license.annualCost
        }
      })
      return total
    },
    totalLicensesAnnualPrice () {
      var total = 0
      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.includeInCalculations) {
          total += license.annualPrice
        }
      })
      return total
    },
    sipTotalAnnualCost () {
      var total = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (license.serviceId === this.iQuoteConstants.services.sip || license.serviceId === this.iQuoteConstants.services.teamsConnector) {
          total += license.annualCost
        }
      })

      total += this.sipAnnualUnitCosts

      if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
        total -= this.totalAnnualExternalHelpdeskCost + this.totalAnnualInternalHelpdeskCost + this.annualIconInfrastructureCost
      }

      return total
    },
    annualInternalLicenseCost () {
      var total = 0

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(license => {
        if (!this.isThirdPartyCost(license.description) && license.includeInCalculations) {
          total += license.annualCost
        }
      })
      return total
    },
    annualIconInfrastructureCost () {
      var total = 0
      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.ICON_INFRASTRUCTURE) {
            total += this.utilFunctions_GetUnitCostAmountPerYear(unitCost.unitCostId) * unitCost.quantity
          }
        }
      })

      return this.iQuoteFunctions_RoundToFourDecimalPlaces(total)
    },
    /*
     * UPFRONT COSTS
     */

    upfrontIconInfrastructureCost () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.ICON_INFRASTRUCTURE) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })

      if (this.iQuoteStore_GetGammaSip.id !== undefined) {
        total -= this.totalUpfrontSipInfrastuctureCost
        /* On sip migration quotes, we count infrastucture as infrastructure, for Gamma SIP We cound infrastucture as SIP Setup Cost */
      }

      return this.iQuoteFunctions_RoundToFourDecimalPlaces(total)
    },
    calculationsCommonMixin_totalUpfrontBladeServerCost () {
      var total = 0

      this.iQuoteStore_GetQuoteBladeServers.forEach(blade => {
        total += blade.cost * blade.quantityRequired
      })

      return Number(total.toFixed(2))
    },
    totalUpfrontUnitCosts () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          total += unitCost.cost * unitCost.quantity
        }
      })
      return Number(total.toFixed(2))
    },
    sipAnnualUnitCosts () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.sip) {
            total += unitCost.yearlyCost
          }
        }
      })
      return Number(total.toFixed(2))
    },
    callmediaCXNowAnnualUnitCosts () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.callmediaCXNow) {
            total += unitCost.yearlyCost
          }
        }
      })
      return Number(total.toFixed(2))
    },
    phonelinePlusAnnualUnitCosts () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.sip) {
            total += unitCost.yearlyCost
          }
        }
      })
      return Number(total.toFixed(2))
    },
    teamsConnectorAnnualUnitCosts () {
      if (this.iQuoteStore_GetTeamsConnector.id === undefined) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.teamsConnector) {
            total += unitCost.yearlyCost
          }
        }
      })
      return Number(total.toFixed(2))
    },
    genesysAnnualUnitCosts () {
      if (this.iQuoteStore_GetQuoteGenesys.id === undefined) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.genesys ||
              unitCost.serviceId === this.iQuoteConstants.services.teamsConnector
          ) {
            total += unitCost.yearlyCost
          }
        }
      })
      return Number(total.toFixed(2))
    },
    ringCentralAnnualUnitCosts () {
      if (this.iQuoteStore_GetQuoteRingCentral.ucTechId === undefined) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.ringCentral
          ) {
            total += unitCost.yearlyCost
          }
        }
      })
      return Number(total.toFixed(2))
    },
    AnnualUnitCosts () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.teamsConnector) {
            total += unitCost.yearlyCost
          }
        }
      })
      return Number(total.toFixed(2))
    },
    annualIconGatewayCost () {
      if (this.iQuoteStore_GetQuoteTechnologyIconGateway.id === undefined) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.ICON_GATEWAY_INFRASTRUCTURE) {
            total += unitCost.yearlyCost
          }
        }
      })

      return Number(total.toFixed(2))
    },
    annualTeamsUnitCostsPrice () {
      if (this.iQuoteStore_GetTeamsConnector.id === undefined) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.teamsConnector &&
              unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.ICON_INFRASTRUCTURE &&
              unitCost.price !== null) {
            total += unitCost.price * unitCost.paymentsPerYear * unitCost.quantity
          }
        }
      })

      return Number(total.toFixed(2))
    },
    annualGenesysUnitCostsCost () {
      if (this.iQuoteStore_GetQuoteGenesys.id === undefined) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if ((unitCost.serviceId === this.iQuoteConstants.services.teamsConnector ||
              unitCost.serviceId === this.iQuoteConstants.services.genesys) &&
              unitCost.cost !== null) {
            total += unitCost.cost * unitCost.paymentsPerYear * unitCost.quantity
          }
        }
      })

      return Number(total.toFixed(2))
    },
    annualTeamsUnitCostsCost () {
      if (this.iQuoteStore_GetTeamsConnector.id === undefined) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.serviceId === this.iQuoteConstants.services.teamsConnector &&
              unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.ICON_INFRASTRUCTURE &&
              unitCost.cost !== null) {
            total += unitCost.cost * unitCost.paymentsPerYear * unitCost.quantity
          }
        }
      })

      return Number(total.toFixed(2))
    },
    annualIconGatewayCostWithMarkup () {
      if (this.iQuoteStore_GetQuoteTechnologyIconGateway.id === undefined) {
        return 0
      }

      return Number((this.annualIconGatewayCost / ((100 - this.iQuoteStore_GetQuote.marginIconGateway) / 100)).toFixed(2))
    },
    annualSipCostWithMarkup () {
      if (this.iQuoteStore_GetGammaSip.id === undefined && this.iQuoteStore_GetQuoteSIPMigrationProperties.id === undefined) {
        return 0
      }

      if (this.$store.getters.quoteSIPMigrationProperties.id !== undefined) {
        var totalPrice = 0
        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (unitCost.serviceId === this.iQuoteConstants.services.sip && unitCost.recurringCost) {
            totalPrice += unitCost.yearlyPrice
          }
        })
        return totalPrice
      } else {
        var margin = this.iQuoteStore_GetQuote.marginGammaSip
        return Number((this.sipAnnualUnitCosts / ((100 - margin) / 100)).toFixed(2))
      }
    },
    annualAWSPlatformCost () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.AWS_INFRASTRUCTURE) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })

      return Number(total.toFixed(2))
    },
    totalInternalAnnualUnitCostsWithMarkup () {
      return Number(((this.totalInternalAnnualUnitCosts / ((100 - this.iQuoteStore_GetQuote.marginInfrastructure) / 100))).toFixed(2))
    },
    totalUpfrontInfrastructureCost () {
      return this.upfrontIconInfrastructureCost
    },
    totalUpfrontInfrastructureCostWithMarkup () {
      if (!this.iQuoteStore_GetQuote.chargeUpfront) {
        return 0
      }

      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.includeInCalculations) {
          if (unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.ICON_INFRASTRUCTURE) {
            total += this.utilFunctions_GetUnitCostPrice(unitCost.unitCostId) * unitCost.quantity
          }
        }
      })

      if (this.iQuoteStore_GetGammaSip.id !== undefined) {
        total -= Number((this.totalUpfrontSipInfrastuctureCost / ((100 - this.iQuoteStore_GetQuote.marginInfrastructure) / 100)).toFixed(2))
        /* On sip migration quotes, we count infrastucture as infrastructure, for Gamma SIP We cound infrastucture as SIP Setup Cost */
      }

      return Number(total.toFixed(2))
    },
    totalUpfrontPSCost () {
      return this.totalUpfrontInternalPSCost + this.totalUpfrontExternalPSCost
    },
    totalUpfrontInternalPSCost () {
      return this.totalUpfrontIconPSCost + this.totalUpfrontCallmediaCXNowPSCost + this.totalUpfrontGenericInternalPSCost
    },
    totalUpfrontGenericInternalPSCost () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.INTERNAL_PS && unitCost.includeInCalculations) {
          total += unitCost.cost * unitCost.quantity
        }
      })

      return Number(total.toFixed(2))
    },
    totalUpfrontExternalPSCost () {
      return 0
    },
    totalUpfrontIconPSCost () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.ICON_PS && unitCost.includeInCalculations) {
          total += unitCost.cost * unitCost.quantity
        }
      })

      return Number(total.toFixed(2))
    },
    totalRecurringIconPSCost () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.includeInCalculations) {
          if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.ICON_PS_IOH_01 ||
              unitCost.unitCostId === this.iQuoteConstants.unitCosts.ICON_PS_OOH_01) {
            total += unitCost.cost * unitCost.quantity
          }
        }
      })

      return Number(total.toFixed(2))
    },
    totalUpfrontCallmediaCXNowPSCost () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.CALLMEDIA_CX_PS && unitCost.includeInCalculations) {
          total += unitCost.cost * unitCost.quantity
        }
      })

      return Number(total.toFixed(2))
    },
    totalUpfrontCallmediaCXNowPSPrice () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.CALLMEDIA_CX_PS && unitCost.includeInCalculations) {
          total += unitCost.price * unitCost.quantity
        }
      })

      return Number(total.toFixed(2))
    },
    totalUpfrontSipSetupCostWithMarkup () {
      var returnValue = 0

      if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
        returnValue = (this.totalUpfrontSipSetupCost) / ((100 - this.iQuoteStore_GetQuote.marginSipMigration) / 100)
      }

      if (this.iQuoteStore_GetGammaSip.id !== undefined) {
        returnValue = this.totalUpfrontSipSetupCost / ((100 - this.iQuoteStore_GetQuote.marginGammaSip) / 100)
      }

      return Number(returnValue.toFixed(2))
    },
    STCMChargeQuantity () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.SIP_TRUNK_CALL_MANAGER_CHARGE) {
          total = unitCost.quantity
        }
      })
      return total
    },
    annualSTCMCharge () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.SIP_TRUNK_CALL_MANAGER_CHARGE) {
          total += unitCost.cost * unitCost.quantity * 12
        }
      })

      if (this.iQuoteStore_GetGammaSip.id !== undefined) {
        total = total / ((100 - this.iQuoteStore_GetQuote.marginGammaSip) / 100)
      }

      if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
        total = total / (100 - this.iQuoteStore_GetQuote.marginSipMigration) / 100
      }

      return Number(total.toFixed(2))
    },
    annualSBCPortCharge () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.ICON_COMMUNICATE_SBC_PORT_CHARGE) {
          total += unitCost.cost * unitCost.quantity * 12
        }
      })

      total = total / ((100 - this.iQuoteStore_GetQuote.marginInfrastructure) / 100)

      return Number(total.toFixed(2))
    },
    SBCPortQuantity () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.ICON_COMMUNICATE_SBC_PORT_CHARGE) {
          total = unitCost.quantity
        }
      })
      return total
    },
    totalUpfrontSipInfrastuctureCost () {
      var total = 0

      if (this.iQuoteStore_GetGammaSip.id !== undefined || this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (!unitCost.recurringCost && unitCost.serviceId === this.iQuoteConstants.services.sip && unitCost.includeInCalculations) {
            if (unitCost.unitCostCategoryId === this.iQuoteConstants.unitCostCategories.ICON_INFRASTRUCTURE) {
              total += unitCost.cost * unitCost.quantity
            }
          }
        })
      }
      return Number(total.toFixed(2))
    },
    totalUpfrontSipSetupCost () {
      var total = 0

      if (this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow.id !== undefined || this.iQuoteStore_GetGammaSip.id !== undefined || this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (!unitCost.recurringCost && unitCost.serviceId === this.iQuoteConstants.services.sip && unitCost.includeInCalculations) {
            if (unitCost.unitCostCategoryId !== this.iQuoteConstants.unitCostCategories.ICON_INFRASTRUCTURE) {
              total += unitCost.cost * unitCost.quantity
            }
          } else if (unitCost.unitCostId === this.iQuoteConstants.unitCosts.CXNOW_GAMMA_SIP_SETUP) {
            total += unitCost.cost * unitCost.quantity
          }
        })
      }

      if (this.iQuoteStore_GetGammaSip.id !== undefined) {
        total += this.totalUpfrontSipInfrastuctureCost
        /* On sip migration quotes, we count infrastucture as infrastructure, for Gamma SIP We cound infrastucture as SIP Setup Cost */
      }

      return Number(total.toFixed(2))
    },
    totalAnnualInternalHelpdeskCost () {
      var total = 0

      if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (unitCost.recurringCost && unitCost.includeInCalculations) {
            switch (unitCost.unitCostId) {
              case this.iQuoteConstants.unitCosts.SIP_HELPDESK_INTERNAL:
                total += unitCost.cost * unitCost.quantity * 12
            }
          }
        })
      }
      return Number(total.toFixed(2))
    },
    totalAnnualExternalHelpdeskCost () {
      var total = 0

      if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (unitCost.recurringCost && unitCost.includeInCalculations) {
            switch (unitCost.unitCostId) {
              case this.iQuoteConstants.unitCosts.SIP_HELPDESK_EXTERNAL:
                total += unitCost.cost * unitCost.quantity * 12
            }
          }
        })
      }
      return Number(total.toFixed(2))
    },
    totalCustomerFacingUpfrontCharges () {
      var total = 0

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
        if (!unitCost.recurringCost && unitCost.visibleToCustomer && unitCost.includeInCalculations) {
          total += unitCost.price * unitCost.quantity
        }
      })
      return Number(total.toFixed(2))
    },
    totalUpfrontCharges () {
      var total = 0

      if (this.iQuoteStore_GetQuote.chargeUpfront) {
        if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id === undefined) {
          // We don't want to do this for Sip migrations, as the prices are already counted under "totalCustomerFacingUpfrontCharges"
          total += this.totalUpfrontSipSetupCostWithMarkup
        }

        total += this.calcTeamsConnector_UpfrontCharges
        total += this.totalCustomerFacingUpfrontCharges + this.totalUpfrontInfrastructureCostWithMarkup
      }

      return Number(total.toFixed(2))
    },
    numPrimarySipChannels () {
      if (this.iQuoteStore_GetGammaSip.id !== undefined) {
        if (this.iQuoteStore_GetGammaSip.overridePrimarySIPChannels !== 0) {
          return this.iQuoteStore_GetGammaSip.overridePrimarySIPChannels
        } else {
          return this.iQuoteStore_GetGammaSip.sipConfigured
        }
      } else {
        return 0
      }
    },
    numSecondarySipChannels () {
      if (this.iQuoteStore_GetGammaSip.id !== undefined) {
        if (this.iQuoteStore_GetGammaSip.overrideSecondarySIPChannels !== 0) {
          return this.iQuoteStore_GetGammaSip.overrideSecondarySIPChannels
        } else {
          return this.iQuoteStore_GetGammaSip.sipConfigured
        }
      } else {
        return 0
      }
    },
    managedServiceCost () {
      if (this.iQuoteStore_GetQuote.id === undefined) {
        return 0
      }

      // var percentage = 0
      var managedServiceCost = 0

      // KP - Ticket 3544 No managed service cost for IconCommunicate
      // if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined) {
      //   switch (this.iQuoteStore_GetQuote.quoteLicenseTypeId) {
      //     case this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION:
      //       if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
      //         percentage = 16
      //       } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
      //         percentage = 27
      //       } else {
      //         console.log('iQuote Common calc mixin Error - Unexpected service level:' + this.iQuoteStore_GetQuote.quoteServiceLevelTypeId)
      //       }
      //       break
      //     case this.iQuoteConstants.quoteLicenceTypes.SURRENDER:
      //       if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
      //         percentage = 23
      //       } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
      //         percentage = 31
      //       } else {
      //         console.log('iQuote Common calc mixin Error - Unexpected service level:' + this.iQuoteStore_GetQuote.quoteServiceLevelTypeId)
      //       }
      //       break
      //     case this.iQuoteConstants.quoteLicenceTypes.PERPETUAL:
      //       if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
      //         percentage = 40
      //       } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
      //         percentage = 60
      //       } else {
      //         console.log('iQuote Common calc mixin Error - Unexpected service level:' + this.iQuoteStore_GetQuote.quoteServiceLevelTypeId)
      //       }
      //       break
      //     default:
      //       console.log('iQuote Common calc mixin Error - Unexpected license type:' + this.iQuoteStore_GetQuote.quoteLicenseTypeId)
      //   }
      //   managedServiceCost = (percentage / 100) * ((this.upfrontIconInfrastructureCost / (this.iQuoteStore_GetQuote.termLengthMonths / 12)) + this.totalInternalAnnualUnitCosts + this.totalUpfrontPSCost + this.iconContactTotalAnnualLicenseCost + this.iconCommunicateTotalAnnualLicenseCost)
      // } else if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined) {
      //   switch (this.iQuoteStore_GetQuote.quoteLicenseTypeId) {
      //     case this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION:
      //       if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
      //         percentage = 28
      //       } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
      //         percentage = 32
      //       } else {
      //         console.log('iQuote Common calc mixin Error - Unexpected service level:' + this.iQuoteStore_GetQuote.quoteServiceLevelTypeId)
      //       }
      //       break
      //     case this.iQuoteConstants.quoteLicenceTypes.PERPETUAL:
      //       if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.STANDARD) {
      //         percentage = 110
      //       } else if (this.iQuoteStore_GetQuote.quoteServiceLevelTypeId === this.iQuoteConstants.quoteServiceLevelTypes.PREMIUM) {
      //         percentage = 130
      //       } else {
      //         console.log('iQuote Common calc mixin Error - Unexpected service level:' + this.iQuoteStore_GetQuote.quoteServiceLevelTypeId)
      //       }
      //       break
      //     default:
      //       console.log('iQuote Common calc mixin Error - Unexpected license type:' + this.iQuoteStore_GetQuote.quoteLicenseTypeId)
      //   }
      //   managedServiceCost = (percentage / 100) * ((this.upfrontIconInfrastructureCost / (this.iQuoteStore_GetQuote.termLengthMonths / 12)) + this.totalInternalAnnualUnitCosts + this.totalUpfrontPSCost + this.iconContactTotalAnnualLicenseCost + this.iconCommunicateTotalAnnualLicenseCost)
      // }

      return Number(managedServiceCost.toFixed(2))
    },
    customerFacingUpfrontUnitCosts () {
      var unitCostsRequired = this.$store.getters.quoteUnitCosts
      var unitCosts = []

      for (let index = 0; index < unitCostsRequired.length; index++) {
        const unitCost = unitCostsRequired[index]

        if (unitCost.visibleToCustomer && !unitCost.recurringCost &&
            unitCost.unitCostCategoryId !== this.iQuoteConstants.unitCostCategories.ICON_INFRASTRUCTURE) {
          unitCosts.push(unitCost)
        }
      }
      return unitCosts
    },
    customerFacingAnnualUnitCosts () {
      var unitCostsRequired = this.$store.getters.quoteUnitCosts
      var unitCosts = []

      for (let index = 0; index < unitCostsRequired.length; index++) {
        const unitCost = unitCostsRequired[index]

        if (unitCost.visibleToCustomer && unitCost.recurringCost) {
          unitCosts.push(unitCost)
        }
      }
      return unitCosts
    }
  },
  methods: {
    ...mapActions([
      'setQuoteVMsRequired',
      'setQuoteLicensesRequired',
      'setQuoteDesignPackEntries',
      'setQuoteUnitCosts',
      'setQuoteGammaSIP',
      'setQuoteCustomerSummaryEntries'
    ]),
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calculationsCommon_calcNumSipChannelsRequired () {
      var VoiceUserCount = 0
      var MultiChannelUserCount = 0
      var gammaSip = this.iQuoteStore_GetGammaSip

      gammaSip.sipConfigured = 0

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined) {
        VoiceUserCount = 0

        for (let index = 0; index < this.iQuoteStore_GetQuoteTechnologyAvayaAura.userProfiles.length; index++) {
          const userProfile = this.iQuoteStore_GetQuoteTechnologyAvayaAura.userProfiles[index]
          VoiceUserCount += userProfile.minCommitment
        }

        if (VoiceUserCount > 0) {
          gammaSip.sipConfigured += Math.ceil(VoiceUserCount / 10)
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined) {
        VoiceUserCount = 0

        for (let index = 0; index < this.iQuoteStore_GetQuoteTechnologyMitelUC.userProfiles.length; index++) {
          const userProfile = this.iQuoteStore_GetQuoteTechnologyMitelUC.userProfiles[index]
          VoiceUserCount += userProfile.minCommitment
        }

        if (VoiceUserCount > 0) {
          gammaSip.sipConfigured += Math.ceil(VoiceUserCount / 10)
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id !== undefined) {
        VoiceUserCount = 0

        for (let index = 0; index < this.iQuoteStore_GetQuoteTechnologyMitelUCE2.userProfiles.length; index++) {
          const userProfile = this.iQuoteStore_GetQuoteTechnologyMitelUCE2.userProfiles[index]
          if (userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.TELEPHONY ||
            userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.ENTRY_UC ||
            userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.PREMIER_UC) {
            VoiceUserCount += userProfile.minCommitment
          } else if (userProfile.technologyUserProfileId === this.iQuoteConstants.mitelUCE2userProfiles.ELITE_CX) {
            gammaSip.sipConfigured += userProfile.minCommitment
          }
        }

        if (VoiceUserCount > 0) {
          gammaSip.sipConfigured += Math.ceil(VoiceUserCount / 10)
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaElite.id !== undefined) {
        VoiceUserCount = 0

        for (let index = 0; index < this.iQuoteStore_GetQuoteTechnologyAvayaElite.userProfiles.length; index++) {
          const userProfile = this.iQuoteStore_GetQuoteTechnologyAvayaElite.userProfiles[index]
          VoiceUserCount += userProfile.minCommitment
        }

        if (VoiceUserCount > 0) {
          gammaSip.sipConfigured += VoiceUserCount
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyCallmedia.id !== undefined) {
        VoiceUserCount = 0
        MultiChannelUserCount = 0

        for (let index = 0; index < this.iQuoteStore_GetQuoteTechnologyCallmedia.userProfiles.length; index++) {
          const userProfile = this.iQuoteStore_GetQuoteTechnologyCallmedia.userProfiles[index]
          if (userProfile.description.toLowerCase().includes('multi-channel')) {
            MultiChannelUserCount += userProfile.minCommitment
          } else {
            VoiceUserCount += userProfile.minCommitment
          }
        }

        if ((VoiceUserCount + MultiChannelUserCount) > 0) {
          gammaSip.sipConfigured += VoiceUserCount + Math.ceil(MultiChannelUserCount / 7)
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAACC.id !== undefined) {
        VoiceUserCount = 0
        MultiChannelUserCount = 0

        for (let index = 0; index < this.iQuoteStore_GetQuoteTechnologyAvayaAACC.userProfiles.length; index++) {
          const userProfile = this.iQuoteStore_GetQuoteTechnologyAvayaAACC.userProfiles[index]
          if (userProfile.description.toLowerCase().includes('multimedia')) {
            MultiChannelUserCount += userProfile.minCommitment
          } else {
            VoiceUserCount += userProfile.minCommitment
          }
        }

        if ((VoiceUserCount + MultiChannelUserCount) > 0) {
          gammaSip.sipConfigured += VoiceUserCount + Math.ceil(MultiChannelUserCount / 7)
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id !== undefined) {
        VoiceUserCount = 0
        MultiChannelUserCount = 0

        for (let index = 0; index < this.iQuoteStore_GetQuoteTechnologyMitelCC.userProfiles.length; index++) {
          const userProfile = this.iQuoteStore_GetQuoteTechnologyMitelCC.userProfiles[index]
          if (userProfile.description.toLowerCase().includes('multi-channel')) {
            MultiChannelUserCount += userProfile.minCommitment
          } else {
            VoiceUserCount += userProfile.minCommitment
          }
        }

        if ((VoiceUserCount + MultiChannelUserCount) > 0) {
          gammaSip.sipConfigured += VoiceUserCount + Math.ceil(MultiChannelUserCount / 7)
        }
      }

      // update local store
      this.setQuoteGammaSIP(gammaSip)

      return gammaSip.sipConfigured
    },
    calculationsCommon_Calculate () {
      var previouslyCalculatedUnitCosts = this.iQuoteStore_GetQuoteUnitCostsRequired
      var previouslyCalculatedLicenses = this.iQuoteStore_GetQuoteLicensesRequired

      this.licensesRequired = []
      this.unitCostsRequired = []
      this.designPackEntries = []
      this.quoteCustomerSummaryEntries = []
      this.quoteBladeServers = []
      this.quoteVMsRequired = []

      this.setQuoteLicensesRequired(this.licensesRequired)
      this.setQuoteVMsRequired(this.quoteVMsRequired)
      this.setQuoteBladeServers(this.quoteBladeServers)
      this.setQuoteUnitCosts(this.unitCostsRequired)
      this.setQuoteDesignPackEntries(this.designPackEntries)
      this.setQuoteCustomerSummaryEntries(this.quoteCustomerSummaryEntries)

      // Work out what VMs are required to run the solution
      this.calcGammaSIP_VMs()

      if (this.iQuoteStore_GetQuoteSIPMigrationProperties.id !== undefined) {
        this.calcSipMigration_calculate()
      }

      this.avayaAuraCalcs_calculate()
      this.calcTeamsConnector_calculate()
      this.calcPhonelinePlus_calculate()
      this.calcGenesysConnector_calculate()
      this.calcRingCentralMixin_calculate()
      this.calcSycurio_calculate()
      this.calcUCAnalytics_calculate()
      this.calcAvayaAXPConnector_calculate()
      this.calcMitelUCE2_calculate()
      this.calcNetCall_calculate()

      if (this.iQuoteStore_GetQuoteTechnologyCallmedia.id !== undefined) {
        this.calcCallmediaVMs()
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaElite.id !== undefined) {
        this.calcAvayaEliteVMs()
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAACC.id !== undefined) {
        this.calcAvayaAACCVMs()
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id !== undefined) {
        this.calcMitelCCVMs()
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined) {
        this.calcMitelUCVMs()
        this.calcMitelUnitCosts()
      }

      if (this.iQuoteStore_GetQuoteExpansions.id !== undefined) {
        this.calcExpansions()
      }

      // Work out what blades are required to run the needed VMs
      this.calcBladeServersRequired()

      this.unitCostCalcs_calcUnitCosts()

      if (this.iQuoteStore_GetQuoteTechnologyCallmedia.id !== undefined) {
        this.calcCallmediaUserLicenses()
        this.calcCallmediaAdjunctLicenses()
      }

      this.callmediaCXNowCalcs_calculate()

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined) {
        this.calcMitelUCUserLicenses()
        this.calcMitelUCAdjunctLicenses()
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelCC.id !== undefined) {
        this.calcMitelCCUserLicenses()
        this.calcMitelCCAdjunctLicenses()
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaElite.id !== undefined) {
        this.calcEliteUserLicenses()
        this.calcEliteAdjunctLicenses()
      }

      if (this.iQuoteStore_GetQuoteTechnologyAvayaAACC.id !== undefined) {
        this.calcAACCUserLicenses()
        this.calcAACCAdjunctLicenses()
      }

      this.calcGammaSIP_calculate()

      this.calculateQuoteLicensePrices()

      if (this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined ||
        this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id !== undefined ||
        this.iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined ||
        this.iQuoteStore_GetQuoteExpansions.id !== undefined
      ) {
        // Calculate Managed Services and Infrastructure so they can be added to the Autotask Export
        // This has to go last, as it takes into account other unitcosts and licenses on the quote

        // KP - 3544 - Remove managed services from icon communicate
        // this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_COMMUNICATE_MANAGED_SERVICES, 1, null, this.managedServiceCost)

        if (this.iQuoteStore_GetQuote.chargeUpfront) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_COMMUNICATE_UPFRONT_INFRASTRUCTURE, 1, null, this.upfrontIconInfrastructureCost)
        } else {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_COMMUNICATE_UPFRONT_INFRASTRUCTURE_AMORTISED, 1, this.upfrontIconInfrastructureCostAmortised, this.upfrontIconInfrastructureCostAmortised)
        }

        if (this.iQuoteCalculationsDesignPackEntriesMixin_recurringInfrastructureCost !== 0) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_COMMUNICATE_RECURRING_INFRASTRUCTURE, 1, null, this.iQuoteCalculationsDesignPackEntriesMixin_recurringInfrastructureCost)
        }
      }

      this.calcDesignPackEntries_calculate()
      this.calcCustomerSummaryEntries()

      // *********************************************************************
      // Restore any unitcost and license prices that were overriden in DP
      // *********************************************************************

      this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(entry => {
        var unitCostBackup = previouslyCalculatedUnitCosts.find(x => x.unitCostId === entry.unitCostId)

        if (unitCostBackup !== undefined) {
          entry.priceOverride = unitCostBackup.priceOverride
        }
      })

      this.iQuoteStore_GetQuoteLicensesRequired.forEach(entry => {
        var licenseBackup = previouslyCalculatedLicenses.find(x => x.licenseId === entry.licenseId)

        if (licenseBackup !== undefined) {
          entry.priceOverride = licenseBackup.priceOverride
        }
      })
    },
    async calculationsCommon_saveQuoteCalculationsToDB () {
      // Save all calculations to the DB
      await this.saveQuoteVMsRequiredToDB()
      await this.saveQuoteBladeServersToDB()
      await this.saveQuoteLicensesToDB()
      await this.saveQuoteUnitCostsToDB()
      await this.saveQuoteDesignPackEntriesToDB()
      await this.saveQuoteCustomerSummaryEntriesToDB()
    },
    calculateQuoteLicensePrices () {
    // The defined license prices include infrastructure costs.  If we are charging upfront for infrastructure we have reduce licence
    // prices  accordingly

      var quoteLicensesRequired = this.$store.getters.quoteLicensesRequired
      var availableLicenses = this.$store.getters.licenses
      var numLicensesToSpreadCost = 0
      var chargeUpfront = this.iQuoteStore_GetQuote.chargeUpfront
      var costToSpreadPerYear = 0

      if (this.iQuoteStore_GetQuoteSycurio.id !== undefined || this.iQuoteStore_GetQuoteUCAnalytics.id !== undefined || this.iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined || this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined) {
        costToSpreadPerYear = (this.totalUpfrontCharges / (this.iQuoteStore_GetQuote.termLengthMonths / 12))
      } else if (this.iQuoteStore_GetQuoteTechnologyCallmediaCXNow.id !== undefined) {
        costToSpreadPerYear = this.totalUpfrontCallmediaCXNowPSPrice / (this.iQuoteStore_GetQuote.termLengthMonths / 12)
      }

      quoteLicensesRequired.forEach(license => {
        if (license.isPrimaryUserLicense && license.includeInCalculations) {
          numLicensesToSpreadCost += license.quantity
        }
      })

      quoteLicensesRequired.forEach(license => {
        availableLicenses.forEach(availableLicense => {
          if (availableLicense.id === license.licenseId) {
            if (!license.priceOverriden) {
              // Initially set the license price as the default
              license.price = availableLicense.price

              if (availableLicense.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY) {
                license.annualPrice = availableLicense.price * license.quantity * 12
              } else if (availableLicense.chargeIntervalId === this.iQuoteConstants.chargeInterval.YEARLY) {
                license.annualPrice = availableLicense.price * license.quantity
              } else if (availableLicense.chargeIntervalId === this.iQuoteConstants.chargeInterval.ONE_OFF) {
                license.annualPrice = 0
              }
            }

            if (license.isPrimaryUserLicense && license.includeInCalculations) {
              if (license.serviceId === this.iQuoteConstants.services.callmediaCXNow) {
                // CX now license prices don't take into account any upfront costs.  So if those are to be spread accross
                // the term we need to increase the cost of those licenses
                if (!chargeUpfront) {
                  license.annualPrice += costToSpreadPerYear * (license.quantity / numLicensesToSpreadCost)
                  license.price = Number((license.annualPrice / (12 * license.quantity)).toFixed(2))
                }
              } else if (license.serviceId === this.iQuoteConstants.services.sycurio) {
                // sycurio doesn't take into account any upfront costs.  So if those are to be spread accross
                // the term we need to increase the cost of those licenses
                if (!chargeUpfront) {
                  license.annualPrice += costToSpreadPerYear * (license.quantity / numLicensesToSpreadCost)

                  if (availableLicense.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY) {
                    license.price = Number((license.annualPrice / (12 * license.quantity)).toFixed(2))
                  } else if (availableLicense.chargeIntervalId === this.iQuoteConstants.chargeInterval.YEARLY) {
                    license.price = Number((license.annualPrice / license.quantity).toFixed(2))
                  }
                }
              } else if (license.serviceId === this.iQuoteConstants.services.ucAnalytics) {
                // ucAnalytics doesn't take into account any upfront costs.  So if those are to be spread accross
                // the term we need to increase the cost of those licenses
                if (!chargeUpfront) {
                  license.annualPrice += costToSpreadPerYear * (license.quantity / numLicensesToSpreadCost)

                  if (availableLicense.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY) {
                    license.price = Number((license.annualPrice / (12 * license.quantity)).toFixed(2))
                  } else if (availableLicense.chargeIntervalId === this.iQuoteConstants.chargeInterval.YEARLY) {
                    license.price = Number((license.annualPrice / license.quantity).toFixed(2))
                  }
                }
              } else {
                // Other platforms (Aura / Mitel) have license prices that assume infrastructure will be paid
                // monthly, so those prices should be reduced if paying for infrastructure etc upfront
                if (chargeUpfront) {
                  license.annualPrice -= costToSpreadPerYear * (license.quantity / numLicensesToSpreadCost)
                  license.price = Number((license.annualPrice / (12 * license.quantity)).toFixed(2))
                }
              }

              license.annualPrice = Number(license.annualPrice.toFixed(2))
            }
          }
        })
      })

      this.setQuoteLicensesRequired(quoteLicensesRequired)
    },
    isThirdPartyCost (licenseDescription) {
      return (!licenseDescription.toLowerCase().includes('icon communicate') &&
              !licenseDescription.toLowerCase().includes('icon contact') &&
              !licenseDescription.toLowerCase().includes('callmedia'))
    },
    getLicenseServiceId (licenseId) {
      var serviceId = 0
      var availableLicenses = this.$store.getters.licenses
      availableLicenses.forEach(license => {
        if (license.id === licenseId) {
          serviceId = license.serviceId
        }
      })

      return serviceId
    },
    getUnitCostServiceId (unitCostId) {
      var serviceId = 0
      var availableUnitCosts = this.$store.getters.unitCosts
      availableUnitCosts.forEach(unitCost => {
        if (unitCost.id === unitCostId) {
          serviceId = unitCost.serviceId
        }
      })

      return serviceId
    }
  },
  mounted () {
  }
}
