<template>
  <div class="quotePanelContainer">
    <div class="quotePanel">
      <label
        class="iQuoteLabel"
        for="txtiQuoteReference"
      >iQuote</label>
      <img
        :src="genesysIcon"
        class="iconButton"
      >
      <br>
      #<input
        id="txtiQuoteReference"
        :value="userProfilesGenesysComponent.quote.quoteOpportunityId + ' (revision ' + userProfilesGenesysComponent.quote.revisionNumber + ')'"
        type="text"
        readonly="true"
      >
      <br><br>
      <label
        class=""
        for="cboPaymentTerm"
      >Payment Term</label>
      <select
        id="cboPaymentTerm"
        v-model.number="userProfilesGenesysComponent.genesys.options.genesysPaymentTermId"
      >
        <option
          key="1"
          value="1"
          title="Annual Prep-Pay"
        >Annual Prep-Pay</option>
        <option
          key="2"
          value="2"
          title="Annual Monthly"
        >Annual Monthly</option>
      </select>
      <br><br>
      <label
        class=""
        for="cboGenesysUserTypeId"
      >User Type</label>
      <select
        id="cboGenesysUserTypeId"
        v-model.number="userProfilesGenesysComponent.genesys.options.genesysUserTypeId"
      >
        <option
          key="1"
          value="1"
          title="Named"
        >Named</option>
        <option
          key="2"
          value="2"
          title="Concurrent"
        >Concurrent</option>
      </select>
      <br><br><br>
      <table class="noTableShading">
        <tr>
          <th
            style="text-align:left;"
            class="numericTableColumn" />
          <th class="numericTableColumn">Min Commit Users<br>
            <TooltipIcon text="This covers the minimum number of agents committed to in the customer MSA and the actual number of users required for operation" />
          </th>
        </tr>
        <tr><td>&nbsp;</td></tr>
        <tbody
          v-for="category in userProfileCategories"
          :key="category.id">
          <tr><td>&nbsp;</td></tr>
          <tr class="TableSectionHeader">{{ category.description }}</tr>
          <tr
            v-for="userProfile in getUserProfilesByCategoryId(category.id)"
            :key="userProfile.technologyUserProfileId">
            <td
              :class="{InputError: !isValidUserProfile(userProfile)}"
              style="width:220px; text-align:left"
            >{{ userProfile.description }}</td>
            <td class="numericTableColumn">
              <input
                v-model.number="userProfile.minCommitment"
                type="number"
                min="0"
                max="1000000"
                @keyup="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
                @change="userProfile.maxAllowed = Math.ceil(userProfile.minCommitment * 1.2)"
              >
            </td>
          </tr>
        </tbody>
        <tr><td colspan="3">&nbsp;</td></tr>
      </table>
      <h3>BYOC</h3>
      <label
        v-if="false"
        :class="{InputError:!isValidNumCallsPerYear}"
        for="numCallsPerYear"
      ># of Expected Annual Calls<TooltipIcon text="BYOC is a Genesys Resource charge that applies to all Maintel / Genesys deployments" />
      </label>
      <input
        v-if="false"
        id="numCallsPerYear"
        v-model.number="userProfilesGenesysComponent.genesys.options.numCallsPerYear"
        class="small"
        type="number"
        min="1"
        max="100000000"
      >
      <br>
      <label
        v-if="false"
        :class="{InputError:!isValidAvgCallDurationMins}"
        for="numAvgCallDurationMins"
      >Average Duration of calls (Minutes)
      </label>
      <input
        v-if="false"
        id="numAvgCallDurationMins"
        v-model.number="userProfilesGenesysComponent.genesys.options.avgCallDurationMins"
        class="small"
        type="number"
        min="1"
        max="240"
      >
      <div class="iQuoteWarningMessage">
        There are further resource charges linked to Genesys Cloud profiles that may apply to customer systems such as for SMS, Social Media, and Predictive Engagement.  Check with pre-sales for any customer charges that could apply based on their profile levels.
      </div>
      <br>
      <h3>Options</h3>
      <label
        class=""
      >Customer Success Manager</label>
      <TriStateToggleSwitch
        ref="sql-toggle"
        :enabled="true"
        :current-state="userProfilesGenesysComponent.genesys.options.customerServiceManagerRequired ? 1 : 2"
        :tri-state="false"
        class="toggleSwitch"
        tabindex="1"
        @statechanged="userProfilesGenesysComponent.genesys.options.customerServiceManagerRequired = !userProfilesGenesysComponent.genesys.options.customerServiceManagerRequired"
      />
      <br><br>
      <div class="buttons">
        <div class="leftButtons">
          <input
            id="btnBack"
            value="< Back"
            type="button"
            @click="gotoPage('SelectService')"
          >
        </div>
        <div class="rightButtons">
          <input
            id="btnNext"
            value="Next >"
            type="button"
            @click="gotoPage('GenesysPage2')"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TooltipIcon from '../../common/tooltipIcon'
import { iQuoteQuoteTechnologyAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteTechnologyAPIMixin'
import { iQuoteQuoteAPIMixin } from '../../../mixins/iQuote/iQuoteQuoteAPIMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import TriStateToggleSwitch from '../../common/triStateToggleSwitch'
import { mapActions } from 'vuex'
export default {
  name: 'UserProfilesGenesys',
  components: {
    TooltipIcon,
    TriStateToggleSwitch
  },
  mixins: [iQuoteQuoteTechnologyAPIMixin, iQuoteQuoteAPIMixin, iQuoteConstants, iQuoteFunctions, iQuoteStoreMixin],
  data () {
    return {
      userProfilesGenesysComponent: {
        quote: {},
        genesys: {
          userProfiles: [],
          options: {
            customerServiceManagerRequired: false,
            genesysUserTypeId: 1,
            genesysPaymentTermId: 1,
            numCallsPerYear: 0,
            avgCallDurationMins: 0,
            numAdditionalSIPChannels: 0,
            netCall: false
          }
        }
      }
    }
  },
  computed: {
    question () {
      return require(`../../../assets/icons/question.png`)
    },
    genesysIcon () {
      return require(`../../../assets/icons/genesys.png`)
    },
    toiQuotePrevious () {
      return require(`../../../assets/icons/toiQuotePrev.png`)
    },
    toiQuoteNext () {
      return require(`../../../assets/icons/toiQuoteNext.png`)
    },
    userProfileCategories () {
      var categories = []
      this.$store.getters.userProfileCategories.forEach(item => {
        if (item.technologyId === this.iQuoteConstants.technologies.genesys) {
          categories.push(item)
        }
      })

      return categories
    },
    isValidNumCallsPerYear () {
      var value = this.userProfilesGenesysComponent.genesys.options.numCallsPerYear
      return (this.iQuoteFunctions_IsInteger(value) && value > 0 && value <= 100000000)
    },
    isValidAvgCallDurationMins () {
      var value = this.userProfilesGenesysComponent.genesys.options.avgCallDurationMins
      return (this.iQuoteFunctions_IsInteger(value) && value > 0 && value <= 240)
    },
    totalGenesysUserCount () {
      var total = 0
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_COMMUNICATE ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_1 ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_2 ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_3 ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_2 ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_3
        ) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalGenesysAddOnsCount () {
      var total = 0
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GENESYS_1_USER_WEM_UPGRADE_II ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GENESYS_2_USER_WEM_UPGRADE_I ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.PURECLOUD_USER_1_DIGITAL_UPGRADE_I ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.PURECLOUD_USER_1_DIGITAL_UPGRADE_II ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.PURECLOUD_USER_2_DIGITAL_UPGRADE_I ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.MICROSOFT_TEAMS_INTEGRATION ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.PURECLOUD_FOR_WALLBOARD_DEVICE_CHARGE ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.PURECLOUD_FOR_SALESFORCE_ADD_ON ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.PURECLOUD_FOR_SALESFORCE_COMMUNICATE_ADD_ON ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_CX_AI_EXPERIENCE_FOR_DIGITAL ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_CX_AI_EXPERIENCE
        ) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalGenesysPurecloudCount () {
      var total = 0
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_1 ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_2 ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_3
        ) {
          total += item.minCommitment
        }
      })

      return total
    },
    hasMultipleProfiles () {
      return (this.totalGenesysUserCount === 0) ||
      (this.totalGenesysPurecloud1Count > 0 && (this.totalGenesysPurecloud1Count + this.totalGenesysCommunicateCount) !== this.totalGenesysUserCount) ||
      (this.totalGenesysPurecloud2Count > 0 && (this.totalGenesysPurecloud2Count + this.totalGenesysCommunicateCount) !== this.totalGenesysUserCount) ||
      (this.totalGenesysPurecloud3Count > 0 && (this.totalGenesysPurecloud3Count + this.totalGenesysCommunicateCount) !== this.totalGenesysUserCount) ||
      (this.totalGenesysDigital2Count > 0 && this.totalGenesysDigital2Count !== this.totalGenesysUserCount) ||
      (this.totalGenesysDigital3Count > 0 && this.totalGenesysDigital3Count !== this.totalGenesysUserCount)
    },
    hasMultiplePureCloudProfiles () {
      return (this.totalGenesysPurecloud1Count > 0 && this.totalGenesysPurecloud1Count !== this.totalGenesysPurecloudCount) ||
      (this.totalGenesysPurecloud2Count > 0 && this.totalGenesysPurecloud2Count !== this.totalGenesysPurecloudCount) ||
      (this.totalGenesysPurecloud3Count > 0 && this.totalGenesysPurecloud3Count !== this.totalGenesysPurecloudCount)
    },
    hasGcDigital_2_Or_3 () {
      let result = false
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_2 ||
            item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_3
        ) {
          if (item.minCommitment > 0) {
            result = true
          }
        }
      })

      return result
    },
    totalGenesysCommunicateCount () {
      var total = 0
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_COMMUNICATE) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalGenesysPurecloud1Count () {
      var total = 0
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_1) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalGenesysPurecloud2Count () {
      var total = 0
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_2) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalGenesysPurecloud3Count () {
      var total = 0
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_3) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalGenesysDigital2Count () {
      var total = 0
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_2) {
          total += item.minCommitment
        }
      })

      return total
    },
    totalGenesysDigital3Count () {
      var total = 0
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_3) {
          total += item.minCommitment
        }
      })

      return total
    }
  },
  async mounted () {
    this.userProfilesGenesysComponent.quote = this.$store.getters.quote
    var genesysStore = this.$store.getters.quoteGenesys

    if (genesysStore.id === undefined || genesysStore.id === 0) {
      var userProfiles
      var options

      this.userProfilesGenesysComponent.genesys.id = await this.getQuoteTechnologyId(this.userProfilesGenesysComponent.quote.id, this.iQuoteConstants.technologies.genesys)

      if (this.userProfilesGenesysComponent.genesys.id === 0) {
        this.userProfilesGenesysComponent.genesys.id = await this.addQuoteTechnologyId(this.userProfilesGenesysComponent.quote.id, this.iQuoteConstants.technologies.genesys)

        userProfiles = this.iQuoteStore_getDefaultQuoteTechnologyUserProfiles(this.iQuoteConstants.technologies.genesys, this.userProfilesGenesysComponent.genesys.id)
        options = {
          id: 0,
          quoteId: this.userProfilesGenesysComponent.quote.id,
          customerServiceManagerRequired: false,
          genesysUserTypeId: 1,
          genesysPaymentTermId: 1,
          numGenesysUsers: 0,
          numPBXUsers: 0,
          sipChannelsCalculated: 0,
          overrideSIPChannels: 0,
          additionalSbcLicencesForInternalCalls: 0,
          subscriptionBillingPeriodId: this.iQuoteConstants.subscriptionBillingIntervals.ANNUAL,
          teamsConnectorNumberPortingOptionId: 1, // 1 = Number Porting, 2 = New DDI
          numCallsPerYear: 0,
          avgCallDurationMins: 0,
          usersPerSipChannel: 5,
          netCall: false
        }
      } else {
        // Retrieve data from DB
        userProfiles = await this.getQuoteTechnologyUserProfiles(this.userProfilesGenesysComponent.genesys.id)
        options = await this.getQuoteOptionsGenesys(this.userProfilesGenesysComponent.quote.id)
      }

      if (userProfiles.length > 0) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.userProfilesGenesysComponent.genesys, 'userProfiles', userProfiles)
      }

      if (options.id !== undefined) {
        // Set component data to what came from DB
        // Need to use $set rather than just assigning the value otherwise VUE.JS doesn't react to the changes in the UI
        this.$set(this.userProfilesGenesysComponent.genesys, 'options', options)
      }

      this.setQuoteGenesys(this.userProfilesGenesysComponent.genesys)
    } else {
      this.userProfilesGenesysComponent.genesys = genesysStore
    }
  },
  methods: {
    ...mapActions([
      'setCurrentQuoteState',
      'setQuoteGenesys'
    ]),
    async gotoPage (page) {
      var ValidationFailed = false
      var minWasGreaterThanMax = false
      var maxNotHighEnough = false

      for (let index = 0; index < this.userProfilesGenesysComponent.genesys.userProfiles.length; index++) {
        const userProfile = this.userProfilesGenesysComponent.genesys.userProfiles[index]
        if (userProfile.minCommitment > userProfile.maxAllowed) {
          minWasGreaterThanMax = true
        }
        if (userProfile.maxAllowed < (userProfile.minCommitment * 1.2)) {
          maxNotHighEnough = true
        }
        if (!this.isValidUserProfile(userProfile)) {
          ValidationFailed = true
        }
      }

      if (!this.isValidNumCallsPerYear || !this.isValidAvgCallDurationMins) {
      // ValidationFailed = true
      }

      if (minWasGreaterThanMax) {
        this.iQuoteFunctions_ShowError('min commit cannot exceed maximum value')
        return
      } else if (maxNotHighEnough) {
        this.iQuoteFunctions_ShowError('maximum value must be at least 20% higher than the min commit')
        return
      } else if (this.hasMultiplePureCloudProfiles) {
        this.iQuoteFunctions_ShowError('Cannot select multiple PureCloud types')
        return
      } else if (ValidationFailed) {
        this.iQuoteFunctions_ShowError('Please Correct incorrect inputs on the page')
        return
      } else if (this.totalGenesysUserCount < 10) {
        if (page === 'SelectService' && this.totalGenesysUserCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter at least 10 users, or enter 0 (this will remove genesys from the quote)')
          return
        } else if (page === 'GenesysPage2' && this.totalGenesysAddOnsCount === 0) {
          this.iQuoteFunctions_ShowError('Please enter at least 10 users')
          return
        }
      } else if (this.hasMultipleProfiles) {
        this.iQuoteFunctions_ShowError('Cannot select multiple profiles')
        return
      } else if (this.totalGenesysUserCount > 10000) {
        if (page === 'SelectService' && this.totalGenesysUserCount > 0) {
          this.iQuoteFunctions_ShowError('If you wish to go back please either enter 10,000 or less users, or enter 0 (this will remove genesys from the quote)')
        } else if (page === 'GenesysPage2') {
          this.iQuoteFunctions_ShowError('cannot support over 10,000 users')
        }
        return
      }

      this.userProfilesGenesysComponent.genesys.options.numGenesysUsers = this.totalGenesysUserCount

      if (this.totalGenesysUserCount > 0 || this.totalGenesysAddOnsCount > 0) {
        if (!ValidationFailed) {
          // Save to DB only if valid data is supplied (even if pressing back rather than next)
          this.userProfilesGenesysComponent.genesys.userProfiles = await this.saveQuoteTechnologyUserProfiles(this.userProfilesGenesysComponent.genesys.userProfiles)
          this.userProfilesGenesysComponent.genesys.options = await this.saveQuoteOptionsGenesys(this.userProfilesGenesysComponent.genesys.options)
        }
        // update local store
        this.setQuoteGenesys(this.userProfilesGenesysComponent.genesys)
      } else {
        this.deleteQuoteTechnology(this.userProfilesGenesysComponent.genesys.id)
        // delete from local store
        this.setQuoteGenesys({})
      }

      if (this.hasGcDigital_2_Or_3) {
        page = 'SelectService'
      }

      // update local store
      this.setCurrentQuoteState(page)
    },
    isValidUserProfile (userProfile) {
      return userProfile.minCommitment <= userProfile.maxAllowed &&
                                          this.iQuoteFunctions_IsInteger(userProfile.minCommitment) &&
                                          this.iQuoteFunctions_IsInteger(userProfile.maxAllowed) &&
                                          userProfile.minCommitment >= 0 &&
                                          userProfile.maxAllowed >= 0 &&
                                          userProfile.maxAllowed >= (userProfile.minCommitment * 1.2) &&
                                          !(
                                            (userProfile.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_1 && this.totalGenesysPurecloud1Count > 0 && this.totalGenesysPurecloud1Count !== this.totalGenesysPurecloudCount) ||
                                           (userProfile.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_2 && this.totalGenesysPurecloud2Count > 0 && this.totalGenesysPurecloud2Count !== this.totalGenesysPurecloudCount) ||
                                           (userProfile.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_3 && this.totalGenesysPurecloud3Count > 0 && this.totalGenesysPurecloud3Count !== this.totalGenesysPurecloudCount)
                                          ) &&
                                          !(
                                            (userProfile.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_1 && this.totalGenesysPurecloud1Count > 0 && (this.totalGenesysPurecloud1Count + this.totalGenesysCommunicateCount) !== this.totalGenesysUserCount) ||
                                          (userProfile.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_2 && this.totalGenesysPurecloud2Count > 0 && (this.totalGenesysPurecloud2Count + this.totalGenesysCommunicateCount) !== this.totalGenesysUserCount) ||
                                          (userProfile.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_PURECLOUD_3 && this.totalGenesysPurecloud3Count > 0 && (this.totalGenesysPurecloud3Count + this.totalGenesysCommunicateCount) !== this.totalGenesysUserCount) ||
                                          (userProfile.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_2 && this.totalGenesysDigital2Count > 0 && this.totalGenesysDigital2Count !== this.totalGenesysUserCount) ||
                                          (userProfile.technologyUserProfileId === this.iQuoteConstants.genesysUserProfiles.GC_DIGITAL_3 && this.totalGenesysDigital3Count > 0 && this.totalGenesysDigital3Count !== this.totalGenesysUserCount)
                                          )
    },
    getUserProfilesByCategoryId (categoryId) {
      var userProfiles = []
      this.userProfilesGenesysComponent.genesys.userProfiles.forEach(item => {
        if (item.userProfileCategoryId === categoryId) {
          userProfiles.push(item)
        }
      })

      return userProfiles
    }
  }
}
</script>
<style scoped>
  table tr th {text-align: center;}
  table tr td {text-align: center;}
  .telsetsLabel {
    display: inline-block;
    width: 60px;
    margin: 20px 0;
    font-weight: 600;
  }

  .quotePanel {
    width: 800px;
  }

  table tr td .numericTableColumn {
    width:55px;
  }

  table tr th .numericTableColumn {
    width:55px;
  }
</style>
