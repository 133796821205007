import { iQuoteConstants } from './iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from './iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from './iQuoteStoreMixin'

export const iQuoteCalculationsGammaSIPMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin],
  data () {
    return {
    }
  },
  methods: {
    getAxiosConfig () {
      var userid = this.$store.getters.userId

      return {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'LoggedInAs': userid
        }
      }
    },
    calcGammaSIP_VMs: function () {
      if (this.iQuoteStore_GetGammaSip.id === undefined) {
        return
      }

      var quoteTechnologyAvayaAura = this.$store.getters.quoteTechnologyAvayaAura

      if (quoteTechnologyAvayaAura.id !== undefined) {
        // These VMs are required for every SIP Deployment
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SIP_SBC8_0_EMS, this.iQuoteConstants.dataCentres.fareham, true, 1)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SIP_SBC8_0_EMS, this.iQuoteConstants.dataCentres.goswellRoad, false, 1)

        this.calcGammaSIP_AvayaAuraSBCVMs()
      }
    },
    calcGammaSIP_AvayaAuraSBCVMs: function () {
      var sipToAllocate
      var serverQuantity

      if (this.quote.criticalAvailability) {
        serverQuantity = 2
      } else {
        serverQuantity = 1
      }

      sipToAllocate = this.numPrimarySipChannels

      while (sipToAllocate > 0) {
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SIP_SBC8_0_ASBCE, this.iQuoteConstants.dataCentres.fareham, true, serverQuantity)
        this.iQuoteStore_AddRequiredVmToQuote(this.iQuoteConstants.virtualMachines.SIP_SBC8_0_ASBCE, this.iQuoteConstants.dataCentres.goswellRoad, true, serverQuantity)

        sipToAllocate -= 5000
      }
    },
    calcGammaSIP_calculate: function () {
      var quote = this.$store.getters.quote
      var amountToAddToEachChannelLicense = 0
      var standardLicensePrice
      var numGammaChannels = 0
      var numTtbChannels = 0
      var numGammaCircuits = 0
      var numTtbCircuits = 0

      if (this.iQuoteStore_GetGammaSip.id === undefined) {
        return
      }

      if (this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
        numGammaChannels = this.numPrimarySipChannels
        numGammaCircuits = this.iQuoteStore_GetGammaSip.numPrimaryCircuits
      } else if (this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
        numGammaChannels = this.numSecondarySipChannels
        numGammaCircuits = this.iQuoteStore_GetGammaSip.numSecondaryCircuits
      }

      if (this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.ttb) {
        numTtbChannels = this.numPrimarySipChannels
        numTtbCircuits = this.iQuoteStore_GetGammaSip.numPrimaryCircuits
      } else if (this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.ttb) {
        numTtbChannels = this.numSecondarySipChannels
        numTtbCircuits = this.iQuoteStore_GetGammaSip.numSecondaryCircuits
      }

      var quantitySTM = Math.ceil((this.iQuoteStore_GetGammaSip.sipTrunkCallManager - 1000) / 1000)

      if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 1000) {
        // Removed 20/06/2024, as it's now a license with monthly charge (Ticket 3621)
        // this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TRUNK_CALL_MANAGER_CHARGE, quantitySTM)
        amountToAddToEachChannelLicense = (quantitySTM * this.utilFunctions_GetUnitCostPrice(this.iQuoteConstants.unitCosts.SIP_TRUNK_CALL_MANAGER_CHARGE)) / (this.numPrimarySipChannels + this.numSecondarySipChannels)
      }

      if (numGammaChannels > 0) {
        this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_MINS_LAND_MOB, numGammaChannels)

        if (quote.termLengthMonths >= 36) {
          standardLicensePrice = this.utilFunctions_GetLicensePrice(this.iQuoteConstants.licenses.SIP_ACTIVE_36) + amountToAddToEachChannelLicense
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_ACTIVE_36, numGammaChannels, standardLicensePrice)
        } else {
          standardLicensePrice = this.utilFunctions_GetLicensePrice(this.iQuoteConstants.licenses.SIP_ACTIVE_12) + amountToAddToEachChannelLicense
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_ACTIVE_12, numGammaChannels, standardLicensePrice)
        }

        if ((this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.gamma && this.iQuoteStore_GetGammaSip.primarySipResilienceId === this.iQuoteConstants.sipResilienceTypes.ACTIVE) ||
            (this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma && this.iQuoteStore_GetGammaSip.secondrySipResilienceId === this.iQuoteConstants.sipResilienceTypes.ACTIVE)) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_SETUP_STANDARD, numGammaCircuits)
        } else {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_SETUP_RESILIENT, numGammaCircuits * 2)

          // Gamma standby is always added for resilient quotes, even if there's another carrier
          if (quote.termLengthMonths >= 36) {
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_STANDBY_36, numGammaChannels)
          } else {
            this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_STANDBY_12, numGammaChannels)
          }
        }

        if (this.iQuoteStore_GetGammaSip.stcmRequired || this.iQuoteStore_GetGammaSip.semaphoneUplift) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SP_GAMMA_IP_DC_GAMMA_SIP, 1)
        } else {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SP_GAMMA_IP_HC_GAMMA_SIP, 1)
        }
      }

      if (numTtbChannels > 0) {
        if (quote.termLengthMonths >= 36) {
          standardLicensePrice = this.utilFunctions_GetLicensePrice(this.iQuoteConstants.licenses.TTB_SIP_ACTIVE_36) + amountToAddToEachChannelLicense
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TTB_SIP_ACTIVE_36, numTtbChannels, standardLicensePrice)
        } else {
          standardLicensePrice = this.utilFunctions_GetLicensePrice(this.iQuoteConstants.licenses.TTB_SIP_ACTIVE_12) + amountToAddToEachChannelLicense
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.TTB_SIP_ACTIVE_12, numTtbChannels, standardLicensePrice)
        }

        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_SETUP, numTtbCircuits)
      }

      if (this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.ttb) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_SL_PORT, this.iQuoteStore_GetGammaSip.numPortingPrimarySingleLine)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_ML_PORT, this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLine)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_ML_LESS_THAN_11_PORT, this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLine1To10)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_ML_GREATER_THAN_10_PORT, this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLineBlocksOver10)
      } else if (this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.ttb) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_SL_PORT, this.iQuoteStore_GetGammaSip.numPortingSecondarySingleLine)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_ML_PORT, this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLine)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_ML_LESS_THAN_11_PORT, this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLine1To10)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_ML_GREATER_THAN_10_PORT, this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLineBlocksOver10)
      }

      if (this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_SL_PORT, this.iQuoteStore_GetGammaSip.numPortingPrimarySingleLine)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_ML_PORT, this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLine)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_ML_LESS_THAN_11_PORT, this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLine1To10)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_ML_GREATER_THAN_10_PORT, this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLineBlocksOver10)
      } else if (this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_SL_PORT, this.iQuoteStore_GetGammaSip.numPortingSecondarySingleLine)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_ML_PORT, this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLine)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_ML_LESS_THAN_11_PORT, this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLine1To10)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_ML_GREATER_THAN_10_PORT, this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLineBlocksOver10)
      }

      if (this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.ttb ||
          this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.ttb) {
        if (
          this.iQuoteStore_GetGammaSip.numPortingPrimarySingleLine +
          this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLine +
          this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLine1To10 +
          this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLineBlocksOver10 +
          this.iQuoteStore_GetGammaSip.numPortingSecondarySingleLine +
          this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLine +
          this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLine1To10 +
          this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLineBlocksOver10 > 0
        ) {
          if (this.iQuoteStore_GetGammaSip.numPortingOohWeekdayEvening > 0) {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_OOH_EVE_PORT, this.iQuoteStore_GetGammaSip.numPortingOohWeekdayEvening)
          }

          if (this.iQuoteStore_GetGammaSip.numPortingOohSaturday > 0) {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_OOH_SAT_PORT, this.iQuoteStore_GetGammaSip.numPortingOohSaturday)
          }
        }
      }

      if (this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.gamma ||
          this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
        if (
          this.iQuoteStore_GetGammaSip.numPortingPrimarySingleLine +
          this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLine +
          this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLine1To10 +
          this.iQuoteStore_GetGammaSip.numPortingPrimaryMultiLineBlocksOver10 +
          this.iQuoteStore_GetGammaSip.numPortingSecondarySingleLine +
          this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLine +
          this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLine1To10 +
          this.iQuoteStore_GetGammaSip.numPortingSecondaryMultiLineBlocksOver10 > 0
        ) {
          if (this.iQuoteStore_GetGammaSip.numPortingOohWeekdayEvening > 0) {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_OOH_EVE_PORT, this.iQuoteStore_GetGammaSip.numPortingOohWeekdayEvening)
          }

          if (this.iQuoteStore_GetGammaSip.numPortingOohSaturday > 0) {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_OOH_SAT_PORT, this.iQuoteStore_GetGammaSip.numPortingOohSaturday)
          }
        }
      }

      if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 0 && this.iQuoteStore_GetGammaSip.stcmRequired) {
        // Removed 20/06/2024, as there's a monthly licence of £0 for the first 1000 DDIs (Ticket 3621)
        // this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TRUNK_CALL_MANAGER_FREE, 1)

        if (numGammaChannels > 0) {
          if ((this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.gamma && this.iQuoteStore_GetGammaSip.primarySipResilienceId === this.iQuoteConstants.sipResilienceTypes.ACTIVE_STANDBY) ||
          (this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma && this.iQuoteStore_GetGammaSip.secondrySipResilienceId === this.iQuoteConstants.sipResilienceTypes.ACTIVE_STANDBY)) {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_STCM_SERVICE, 2)
          } else {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_STCM_SERVICE, 1)
          }
        }
      }

      var sipBandwidthStandardUnitCost
      var sipBandwidthResilientUnitCost

      if (this.iQuoteStore_GetGammaSip.sbcPorts + this.numPrimarySipChannels > 0) {
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_PS_PROJECT_MANAGEMENT, 0.5)

        if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id !== undefined || this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined || this.iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined) {
          // Icon Communicate````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````````
          sipBandwidthStandardUnitCost = this.iQuoteConstants.unitCosts.SIP_CORE_BANDWIDTH_STANDARD_MONTHLY_ICON_COMMUNICATE
          sipBandwidthResilientUnitCost = this.iQuoteConstants.unitCosts.SIP_CORE_BANDWIDTH_RESILIENT_MONTHLY_ICON_COMMUNICATE
        } else {
          // Standalone SIP
          sipBandwidthStandardUnitCost = this.iQuoteConstants.unitCosts.SIP_CORE_BANDWIDTH_STANDARD_MONTHLY_STANDALONE_SIP
          sipBandwidthResilientUnitCost = this.iQuoteConstants.unitCosts.SIP_CORE_BANDWIDTH_RESILIENT_MONTHLY_STANDALONE_SIP
        }

        var iconBandwidth = 0

        iconBandwidth = 2 * (5 + ((numTtbChannels + numGammaChannels + this.iQuoteStore_GetGammaSip.sbcPorts) / 5))
        this.iQuoteStore_AddRequiredUnitCostToQuote(sipBandwidthStandardUnitCost, iconBandwidth)

        if (numGammaChannels > 0) {
          if ((this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.gamma && this.iQuoteStore_GetGammaSip.primarySipResilienceId === this.iQuoteConstants.sipResilienceTypes.ACTIVE_STANDBY) ||
              (this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma && this.iQuoteStore_GetGammaSip.secondrySipResilienceId === this.iQuoteConstants.sipResilienceTypes.ACTIVE_STANDBY)) {
            this.iQuoteStore_AddRequiredUnitCostToQuote(sipBandwidthResilientUnitCost, iconBandwidth)
          }
        }
      }

      if (this.iQuoteStore_GetQuoteTechnologyMitelUCE2.id !== undefined || this.iQuoteStore_GetQuoteTechnologyMitelUC.id !== undefined || this.iQuoteStore_GetQuoteTechnologyAvayaAura.id !== undefined) {
        // With ICON Communicate, the infrastructure charges assumed as part of the user profile licencing, so not charged for seperately
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_COMMUNICATE_SBC_PORT_CHARGE, this.numPrimarySipChannels + this.iQuoteStore_GetGammaSip.sbcPorts)
      } else {
        // Standalone SIP will charge the customer for SBC Ports
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SIP_SBC_PORT_CHARGE, this.numPrimarySipChannels + this.iQuoteStore_GetGammaSip.sbcPorts)

        var sipRecurringInfrastructureCost = 0

        var sipRecurringInfrastructureUnitCosts = [
          this.iQuoteConstants.unitCosts.ICON_SIP_SBC_PORT_CHARGE,
          sipBandwidthStandardUnitCost,
          sipBandwidthResilientUnitCost
        ]

        // Calculate the total cost of infrastructure for SIP
        this.iQuoteStore_GetQuoteUnitCostsRequired.forEach(unitCost => {
          if (sipRecurringInfrastructureUnitCosts.includes(unitCost.unitCostId) && unitCost.includeInCalculations) {
            sipRecurringInfrastructureCost += unitCost.yearlyCost
          }
        })

        var sipOverrideUnitCostPerYear = sipRecurringInfrastructureCost
        var sipOverrideCost = sipRecurringInfrastructureCost

        if (sipOverrideCost > 0) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.ICON_SIP_RECURRING_INFRASTRUCTURE, 1, sipOverrideUnitCostPerYear, sipOverrideCost)
        }
      }

      if (this.iQuoteStore_GetGammaSip.newDDIs > 0) {
        if (this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_NUMBER, this.iQuoteStore_GetGammaSip.newDDIs)
        } else if (this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.ttb) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_NUMBER, this.iQuoteStore_GetGammaSip.newDDIs)
        }
      }

      if (this.iQuoteStore_GetGammaSip.newDDIsSecondary > 0) {
        if (this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_NUMBER, this.iQuoteStore_GetGammaSip.newDDIsSecondary)
        } else if (this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.ttb) {
          this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_TTB_NUMBER, this.iQuoteStore_GetGammaSip.newDDIsSecondary)
        }
      }

      if (this.iQuoteStore_GetGammaSip.stcmRequired) {
        // Setup costs for STCM DDIs (one-off)
        this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_STCM_DDI_CONNECTION, this.iQuoteStore_GetGammaSip.sipTrunkCallManager)

        // Choose appropriate licence based on the number of STCM DDIs.  It is in tiers based on steps of 1000
        if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 20000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_20001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 19000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_19001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 18000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_18001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 17000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_17001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 16000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_16001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 15000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_15001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 14000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_14001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 13000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_13001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 12000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_12001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 11000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_11001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 10000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_10001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 9000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_9001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 8000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_8001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 7000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_7001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 6000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_6001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 5000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_5001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 4000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_4001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 3000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_3001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 2000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_2001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 1000) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_ADD_OVER_1K_1001_PLUS, 1)
        } else if (this.iQuoteStore_GetGammaSip.sipTrunkCallManager > 0) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_GAMMA_STCM_DDI_INCE_1_1K, 1)
        } 
      }

      if (this.iQuoteStore_GetGammaSip.primarySipProviderId === this.iQuoteConstants.sipProviders.gamma ||
          this.iQuoteStore_GetGammaSip.secondarySipProviderId === this.iQuoteConstants.sipProviders.gamma) {
        if (this.iQuoteStore_GetGammaSip.semaphoneUplift && numGammaChannels > 0) {
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_ACTIVE_SEMA, numGammaChannels)
          this.iQuoteStore_AddRequiredLicenseToQuote(this.iQuoteConstants.licenses.SIP_STANDBY_SEMA, numGammaChannels)

          if (numGammaChannels < 150) {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_SEMA_SETUP_UNDER_150, 1)
          } else {
            this.iQuoteStore_AddRequiredUnitCostToQuote(this.iQuoteConstants.unitCosts.SIP_GAMMA_SEMA_SETUP_150_PLUS, 1)
          }
        }
      }
    }
  }
}
