// This module is used for state mamangement for app UI related variables.
import { set, toggle } from '@/utils/vuex'

const mutations = {
  setDrawer: set('drawer'),
  toggleDrawer: toggle('drawer')
}

const getters = {
  version: state => {
    return state.history.slice(-1)[0].Version
  },
  history: state => {
    return state.history
  }
}

const state = {
  drawer: null,
  history: [
    { 'Version': '0.4.505', 'Date': '20-04-2020', 'ReleasedBy': 'Richard', 'Description': 'Updated menu permissoins logic and hard coded new IDH users' },
    { 'Version': '0.4.506', 'Date': '21-04-2020', 'ReleasedBy': 'Alistair', 'Description': 'New tickets component created on dahsboard and associated calls' },
    { 'Version': '0.4.512', 'Date': '22-04-2020', 'ReleasedBy': 'Richard', 'Description': 'Completed tickets component created and iQuote updates' },
    { 'Version': '0.4.513', 'Date': '22-04-2020', 'ReleasedBy': 'Alistair', 'Description': 'Completed tickets component new icon' },
    { 'Version': '0.4.514', 'Date': '23-04-2020', 'ReleasedBy': 'Richard', 'Description': 'Changed dashboard to include permissions for components' },
    { 'Version': '0.4.515', 'Date': '23-04-2020', 'ReleasedBy': 'Alistair', 'Description': 'Changed formatting of dashboard tiles' },
    { 'Version': '0.4.528', 'Date': '28-04-2020', 'ReleasedBy': 'Richard', 'Description': 'Culumated iQuote changes with no version changes and style corrections' },
    { 'Version': '0.4.532', 'Date': '28-04-2020', 'ReleasedBy': 'Richard', 'Description': 'Updated side panel sizes and component contnet' },
    { 'Version': '0.4.534', 'Date': '28-04-2020', 'ReleasedBy': 'Richard', 'Description': 'Refreshing of ticket and site page issue' },
    { 'Version': '0.4.537', 'Date': '30-04-2020', 'ReleasedBy': 'Richard', 'Description': 'Change ticket colours throughout the application' },
    { 'Version': '0.4.539', 'Date': '30-04-2020', 'ReleasedBy': 'Richard', 'Description': 'Update tickets and sites for purple and grey' },
    { 'Version': '0.4.550', 'Date': '30-04-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fix the change password functionality and create duplicate "admin" change password component' },
    { 'Version': '0.4.553', 'Date': '04-05-2020', 'ReleasedBy': 'Richard', 'Description': 'New site health column and calls for Highlight alarms' },
    { 'Version': '0.4.554', 'Date': '04-05-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fix loading and menu bugs' },
    { 'Version': '0.4.555', 'Date': '11-05-2020', 'ReleasedBy': 'Richard', 'Description': 'Updated permissions for new users' },
    { 'Version': '0.4.586', 'Date': '18-05-2020', 'ReleasedBy': 'Richard', 'Description': 'Updated permissions for new iQuote users and a number of iQuote updates from Kevin' },
    { 'Version': '0.4.587', 'Date': '19-05-2020', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Changed layout and calcs on review and BOM screens to group by service' },
    { 'Version': '0.4.645', 'Date': '03-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'New permissions stitched in - merge - release' },
    { 'Version': '0.4.658', 'Date': '08-06-2020', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Support for AACC, WFO/WFM and some updated calculations' },
    { 'Version': '0.4.672', 'Date': '09-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Permissions bug fixes and performance enhancements' },
    { 'Version': '0.4.679', 'Date': '10-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Added common loader for dashboard tiles and fixed role update' },
    { 'Version': '0.4.682', 'Date': '10-06-2020', 'ReleasedBy': 'Richard', 'Description': 'Changed sites population to use permissions database rather than auth' },
    { 'Version': '0.4.684', 'Date': '10-06-2020', 'ReleasedBy': 'Richard', 'Description': 'Refactored site, tickets and site security pages to use new site call' },
    { 'Version': '0.4.686', 'Date': '12-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Optimise user management components' },
    { 'Version': '0.4.697', 'Date': '18-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Various bug fixes and styling updates' },
    { 'Version': '0.4.703', 'Date': '18-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Role Member bug fixes' },
    { 'Version': '0.4.707', 'Date': '18-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Duplicate username validation fix' },
    { 'Version': '0.4.708', 'Date': '18-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fixed ticket load issue' },
    { 'Version': '0.4.712', 'Date': '18-06-2020', 'ReleasedBy': 'Richard', 'Description': 'Changed ticket and dashboard calls to use post with a site list in the body' },
    { 'Version': '0.4.717', 'Date': '22-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Updated UI for consistency and UX improvements on the Details components ' },
    { 'Version': '0.4.719', 'Date': '22-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Implemented not showing inherited only permissions' },
    { 'Version': '0.4.728', 'Date': '24-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fixed bug in inherited only permissions and formatting fixes' },
    { 'Version': '0.4.734', 'Date': '25-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fixed bug updating grant against inherited permission' },
    { 'Version': '0.4.735', 'Date': '26-06-2020', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Pricing updates, AACC Support, Mitel Support, Bug Fixes' },
    { 'Version': '0.4.742', 'Date': '29-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Updated portal to correctly redirect to your intended location if not logged in' },
    { 'Version': '0.4.745', 'Date': '29-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fixed bug on security page not showing tabs correctly and update drawer to load based on whether logged in or not' },
    { 'Version': '0.4.746', 'Date': '30-06-2020', 'ReleasedBy': 'Alastair', 'Description': 'Added loader to my tickets page, fixed my tickets page so it loads data correctly when navigating directly to it' },
    { 'Version': '0.4.761', 'Date': '06-07-2020', 'ReleasedBy': 'Richard', 'Description': 'iQuote error fixes and merged code in place - confirmed by John.' },
    { 'Version': '0.4.768', 'Date': '08-07-2020', 'ReleasedBy': 'Richard', 'Description': 'iQuote error fixes and formatting. Also back button error reported fixed.' },
    { 'Version': '0.4.774', 'Date': '09-07-2020', 'ReleasedBy': 'Richard', 'Description': 'iQuote error fix for mitel user profiles not being retrieved correctly.' },
    { 'Version': '1.0.781', 'Date': '10-07-2020', 'ReleasedBy': 'Richard', 'Description': 'Changed the version numbering as per Nicks instructions' },
    { 'Version': '1.0.782', 'Date': '14-07-2020', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixed MitelCC and MitelUC saving of user profiles, fixed DOM errors on BOM screen' },
    { 'Version': '1.0.821', 'Date': '16-07-2020', 'ReleasedBy': 'Richard', 'Description': 'Fixed bugs reported by Sean on tickets and sites pages.' },
    { 'Version': '1.0.822', 'Date': '16-07-2020', 'ReleasedBy': 'Richard', 'Description': 'iQuote - Added permissions check to the home page prior to making the SearchQuotes call.' },
    { 'Version': '1.0.823', 'Date': '17-07-2020', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixed Mitel Calculations' },
    { 'Version': '1.0.828', 'Date': '17-07-2020', 'ReleasedBy': 'Richard', 'Description': 'Introduced clearing of permissoins on logout and trapped the iQuote tile for permissions.' },
    { 'Version': '1.0.835', 'Date': '22-07-2020', 'ReleasedBy': 'Alastair', 'Description': 'Added ability to create a new user' },
    { 'Version': '1.0.837', 'Date': '22-07-2020', 'ReleasedBy': 'Richard', 'Description': 'Added set password page with login on success' },
    { 'Version': '1.0.838', 'Date': '22-07-2020', 'ReleasedBy': 'Richard', 'Description': 'Refined new user and forgotten password work' },
    { 'Version': '1.0.852', 'Date': '24-07-2020', 'ReleasedBy': 'Alastair', 'Description': 'Corrected new user change password login bug ' },
    { 'Version': '1.0.857', 'Date': '27-07-2020', 'ReleasedBy': 'Richard', 'Description': 'Changed update enabled logic to use patch call for security reasons on set password.' },
    { 'Version': '1.0.858', 'Date': '27-07-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fixed UI issues on the Create User screen' },
    { 'Version': '1.0.862', 'Date': '28-07-2020', 'ReleasedBy': 'Richard', 'Description': 'Introduced new snackbar component and changed all snackbar calls.' },
    { 'Version': '1.0.863', 'Date': '29-07-2020', 'ReleasedBy': 'Richard', 'Description': 'Removed encryption intercetpor, related variable in the env files and changed the token call to new API passthrough.' },
    { 'Version': '1.0.868', 'Date': '03-08-2020', 'ReleasedBy': 'Richard', 'Description': 'Corrected permissions to allow iQuote users only to view iQuote section.' },
    { 'Version': '1.0.879', 'Date': '03-08-2020', 'ReleasedBy': 'Alastair', 'Description': 'Updated Create User UI, added data refresh popup and fixed update username bug.' },
    { 'Version': '1.0.881', 'Date': '07-08-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fixed adding new roles and updated add button' },
    { 'Version': '1.0.884', 'Date': '10-08-2020', 'ReleasedBy': 'Alastair', 'Description': 'Change behaviour of the triStateToggle to work similarly to a standard toggle switch. Fixed various console issues with the role functionality' },
    { 'Version': '1.0.890', 'Date': '10-08-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fixed effective permission table on create new user screen when no permissions assigned to role' },
    { 'Version': '1.0.891', 'Date': '10-08-2020', 'ReleasedBy': 'Alastair', 'Description': 'Implement Highlight SSO' },
    { 'Version': '1.0.900', 'Date': '13-08-2020', 'ReleasedBy': 'Alastair', 'Description': 'Implement toast notification on Highlight SSO login fail' },
    { 'Version': '1.0.907', 'Date': '14-08-2020', 'ReleasedBy': 'Richard', 'Description': 'Fixed bug for Highlight alarms not showing' },
    { 'Version': '1.0.912', 'Date': '14-08-2020', 'ReleasedBy': 'Richard', 'Description': 'Added new reference column into alarms component on site health page.' },
    { 'Version': '1.0.970', 'Date': '15-09-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fixed Project Status Tile and associated calls.' },
    { 'Version': '1.0.973', 'Date': '18-09-2020', 'ReleasedBy': 'Alastair', 'Description': 'Updates to exec dashboard and implementation of support section for user creation.' },
    { 'Version': '1.0.989', 'Date': '25-09-2020', 'ReleasedBy': 'Kevin', 'Description': 'Updates to iQuote - introduced new Callmedia CX.' },
    { 'Version': '1.0.997', 'Date': '01-10-2020', 'ReleasedBy': 'Alastair', 'Description': 'Implementing customer centric data plus management controls' },
    { 'Version': '1.0.1003', 'Date': '02-10-2020', 'ReleasedBy': 'Alastair', 'Description': 'Formatting and bug fixes for multiple customer support' },
    { 'Version': '1.0.1014', 'Date': '07-10-2020', 'ReleasedBy': 'Richard', 'Description': 'Fixed bug with setting default customer and formatted change password better.' },
    { 'Version': '1.0.1020', 'Date': '07-10-2020', 'ReleasedBy': 'Richard', 'Description': 'Added loading screen for customer selection change.' },
    { 'Version': '1.0.1022', 'Date': '12-10-2020', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Design pack updates, pricing updates' },
    { 'Version': '1.0.1024', 'Date': '14-10-2020', 'ReleasedBy': 'Alastair', 'Description': 'Changed userlist call for post and styling updates / tablehandler fixes' },
    { 'Version': '1.0.1038', 'Date': '15-10-2020', 'ReleasedBy': 'Richard', 'Description': 'Page refresh and iQuote user login permission issue fixed' },
    { 'Version': '1.0.1043', 'Date': '16-10-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fix UserManagement refresh issues' },
    { 'Version': '1.0.1045', 'Date': '20-10-2020', 'ReleasedBy': 'Alastair', 'Description': 'Exec Dashboard Updates' },
    { 'Version': '1.0.1050', 'Date': '21-10-2020', 'ReleasedBy': 'Alastair', 'Description': 'Exec Dashboard Updates and Customer disabled login logic ' },
    { 'Version': '1.0.1057', 'Date': '23-10-2020', 'ReleasedBy': 'Alastair', 'Description': 'Added functionality to assign a role and area on assignment to a customer' },
    { 'Version': '1.0.1076', 'Date': '05-11-2020', 'ReleasedBy': 'Alastair', 'Description': 'Various bug fixes' },
    { 'Version': '1.0.1079', 'Date': '09-11-2020', 'ReleasedBy': 'Alastair', 'Description': 'Completed company integration' },
    { 'Version': '1.0.1086', 'Date': '10-11-2020', 'ReleasedBy': 'Alastair', 'Description': 'Updated support screen to finalise ui changes' },
    { 'Version': '1.0.1093', 'Date': '13-11-2020', 'ReleasedBy': 'Alastair', 'Description': 'Fix update username functionality and search filter autocomplete issue' },
    { 'Version': '1.0.1109', 'Date': '17-11-2020', 'ReleasedBy': 'Richard', 'Description': 'Updated dashboard' },
    { 'Version': '1.0.1119', 'Date': '17-11-2020', 'ReleasedBy': 'Alastair', 'Description': 'Exec Dashboard changes and no data handling on dashboard' },
    { 'Version': '1.0.1135', 'Date': '24-11-2020', 'ReleasedBy': 'Alastair', 'Description': 'Exec Dashboard updates' },
    { 'Version': '1.0.1136', 'Date': '26-11-2020', 'ReleasedBy': 'Alastair', 'Description': 'Updates and additions to Help sections and various bug fixes' },
    { 'Version': '1.0.1138', 'Date': '30-11-2020', 'ReleasedBy': 'Alastair', 'Description': 'Further Help section content additions and lightbox functionality' },
    { 'Version': '1.0.1144', 'Date': '02-12-2020', 'ReleasedBy': 'Alastair', 'Description': 'Bug fixes and code refactor' },
    { 'Version': '1.0.1157', 'Date': '10-12-2020', 'ReleasedBy': 'Richard', 'Description': 'Updated formatting + element ids for testing purposes' },
    { 'Version': '1.0.1163', 'Date': '16-12-2020', 'ReleasedBy': 'Alastair', 'Description': 'Tidied up dashboard tiles and altered timeout logic' },
    { 'Version': '1.0.1177', 'Date': '06-01-2021', 'ReleasedBy': 'Richard', 'Description': 'Completed the create company form and associated tests' },
    { 'Version': '1.0.1196', 'Date': '07-01-2021', 'ReleasedBy': 'Alastair', 'Description': 'Addressed various quotes and projects bugs' },
    { 'Version': '1.0.1199', 'Date': '07-01-2021', 'ReleasedBy': 'Alastair', 'Description': 'Implemented new service status drawer component and integrated with traverse api' },
    { 'Version': '1.0.1200', 'Date': '11-01-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Bug Fixes & Minor UI Tweaks' },
    { 'Version': '1.0.1208', 'Date': '12-01-2021', 'ReleasedBy': 'Alastair', 'Description': 'Various bug fixes and code refactoring to remove old functionality name' },
    { 'Version': '1.0.1209', 'Date': '13-01-2021', 'ReleasedBy': 'Kevin', 'Description': 'Various bug fixes for iQuote' },
    { 'Version': '1.0.1210', 'Date': '14-01-2021', 'ReleasedBy': 'Kevin', 'Description': 'Various bug fixes for iQuote review screen/customer screen/export sheet' },
    { 'Version': '1.0.1212', 'Date': '15-01-2021', 'ReleasedBy': 'Alastair', 'Description': 'Various bug fixes for general portal pages' },
    { 'Version': '1.0.1221', 'Date': '26-01-2021', 'ReleasedBy': 'Alastair', 'Description': 'Added alarm filtering to Site Health screen + various bug fixes' },
    { 'Version': '1.0.1223', 'Date': '29-01-2021', 'ReleasedBy': 'Alastair', 'Description': 'Fixed user create so roles are filtered by selected site and re-enabled ability to modify own users permissions' },
    { 'Version': '1.0.1229', 'Date': '02-02-2021', 'ReleasedBy': 'Alastair', 'Description': 'Updates to Service Status Drawer, repurposed circuit status widget to show alarms data, various bug fixes' },
    { 'Version': '1.0.1230', 'Date': '08-02-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote fixes to designpack and review screen for incorrect values on Icon Communicate / Gamma SIP' },
    { 'Version': '1.0.1240', 'Date': '10-02-2021', 'ReleasedBy': 'Alastair', 'Description': 'Updates to Service Status Drawer including more detailed information in communicate details window and added filtering to projects section of quotes and projects screen' },
    { 'Version': '1.0.1253', 'Date': '12-02-2021', 'ReleasedBy': 'Alastair', 'Description': 'Updated help file and converted projects closed date to end date. Also fixed bug with projects filtering' },
    { 'Version': '1.0.1262', 'Date': '17-02-2021', 'ReleasedBy': 'Alastair', 'Description': 'Updated help files and fixed bug with create user role assignment' },
    { 'Version': '1.0.1272', 'Date': '23-02-2021', 'ReleasedBy': 'Alastair', 'Description': 'Added projects and quotes open / closed filter. Minor fix to messaging used in service status draw for secure service and fixed bug where multiple top level sites weren\'t being considered when getting quotes and projects data.' },
    { 'Version': '1.0.1273', 'Date': '24-02-2021', 'ReleasedBy': 'Kevin', 'Description': 'Fixed issues with review screen on Avaya Aura / Mitel quotes and added support for unit testing' },
    { 'Version': '1.0.1278', 'Date': '24-02-2021', 'ReleasedBy': 'Richard', 'Description': 'Changed images and styles to be resolved from channel comapany tables.' },
    { 'Version': '1.0.1279', 'Date': '04-03-2021', 'ReleasedBy': 'Kevin', 'Description': 'iquote fixes for expansions, and more unit tests' },
    { 'Version': '1.0.1280', 'Date': '10-03-2021', 'ReleasedBy': 'Kevin', 'Description': 'iquote fixes for multiple calculation errors, and more unit tests' },
    { 'Version': '1.0.1281', 'Date': '10-03-2021', 'ReleasedBy': 'Kevin', 'Description': 'iquote fixes for differences between customer summary screen and export doc' },
    { 'Version': '1.0.1282', 'Date': '10-03-2021', 'ReleasedBy': 'Kevin', 'Description': 'iquote fixes for cxnow Accelleration function and if yoou deselect charge upfront' },
    { 'Version': '1.0.1316', 'Date': '19-03-2021', 'ReleasedBy': 'Richard', 'Description': 'Bug fixes for the user management page & rework filter on site health page' },
    { 'Version': '1.0.1317', 'Date': '22-03-2021', 'ReleasedBy': 'Kevin', 'Description': 'Support for TTB SIP' },
    { 'Version': '1.0.1322', 'Date': '22-03-2021', 'ReleasedBy': 'Richard', 'Description': 'Updated create user screen and changed all tables, filter buttons and components to have the same border radius' },
    { 'Version': '1.0.1323', 'Date': '22-03-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Removed support for standalone TTB and merged with gamma' },
    { 'Version': '1.0.1341', 'Date': '31-03-2021', 'ReleasedBy': 'Richard', 'Description': 'Corrected create user issues' },
    { 'Version': '1.0.1347', 'Date': '07-04-2021', 'ReleasedBy': 'Richard', 'Description': 'Corrected user management bugs' },
    { 'Version': '1.0.1349', 'Date': '12-04-2021', 'ReleasedBy': 'Richard', 'Description': 'Final fixes for the create user screen and corrected iQuote bugs.' },
    { 'Version': '1.0.1350', 'Date': '14-04-2021', 'ReleasedBy': 'Kevin', 'Description': 'Fixed bugs in cxnow where sip upfront excluded.  Support for custom term lengths.  Support for resiliency on SIP' },
    { 'Version': '1.0.1351', 'Date': '15-04-2021', 'ReleasedBy': 'Kevin', 'Description': 'Fixed bugs in custom term lengths and display issue for trashcan icon in expansions' },
    { 'Version': '1.0.1351', 'Date': '15-04-2021', 'ReleasedBy': 'Kevin', 'Description': 'Fixed console errors when putting custom term length' },
    { 'Version': '1.0.1385', 'Date': '22-04-2021', 'ReleasedBy': 'Richard', 'Description': 'Tikets details section and channel company users not seen by non-channel company users.' },
    { 'Version': '1.0.1386', 'Date': '22-04-2021', 'ReleasedBy': 'Richard', 'Description': 'Fixes for Gamma SIP when using secondary provider.  Support for CXNow DDI Detail Export' },
    { 'Version': '1.0.1401', 'Date': '05-05-2021', 'ReleasedBy': 'Richard', 'Description': 'New Icon images and spinner image. Also shaddowing on dashboard items.' },
    { 'Version': '1.0.1407', 'Date': '10-05-2021', 'ReleasedBy': 'Richard', 'Description': 'iQuote calculation fixes plus updated getDesignPackVirtualMachines procedure.' },
    { 'Version': '1.0.1430', 'Date': '24-05-2021', 'ReleasedBy': 'Richard', 'Description': 'Bug fixes plus create top level site page completion' },
    { 'Version': '1.0.1433', 'Date': '24-05-2021', 'ReleasedBy': 'Zirak', 'Description': 'Final check-in of upload dashboard components with help files' },
    { 'Version': '1.0.1445', 'Date': '26-05-2021', 'ReleasedBy': 'Zirak', 'Description': 'Fix the cache issue for the dashboard image and fixed bugs' },
    { 'Version': '1.0.1460', 'Date': '26-05-2021', 'ReleasedBy': 'Richard', 'Description': 'Fixed issues on the createCompany component - removed admin namd and manke traverse parent field optional.' },
    { 'Version': '1.0.1461', 'Date': '14-06-2021', 'ReleasedBy': 'Kevin', 'Description': 'Fixed issues Teams Connector Calcs' },
    { 'Version': '1.0.1462', 'Date': '15-06-2021', 'ReleasedBy': 'Kevin', 'Description': 'Fixed issue Teams Connector screen not allowing SIP override on new quotes' },
    { 'Version': '1.0.1463', 'Date': '17-06-2021', 'ReleasedBy': 'Kevin', 'Description': 'CX Now changes with Talkative licenses and unit costs' },
    { 'Version': '1.0.1464', 'Date': '17-06-2021', 'ReleasedBy': 'Kevin', 'Description': 'CX Now bug fixes' },
    { 'Version': '1.0.1516', 'Date': '05-07-2021', 'ReleasedBy': 'Richard', 'Description': 'Changed ticket calls to return directly from Autotask + add new filters to ticket page.' },
    { 'Version': '1.0.1517', 'Date': '06-07-2021', 'ReleasedBy': 'Kevin', 'Description': 'Support for SIP primary and secondary providers with resilience selectable for gamma only' },
    { 'Version': '1.0.1533', 'Date': '09-07-2021', 'ReleasedBy': 'Zirak', 'Description': 'Added Delete user/Users and create global user features' },
    { 'Version': '1.0.1534', 'Date': '09-07-2021', 'ReleasedBy': 'Richard', 'Description': 'Implemented new ticket page changes + add ticket, note and attachment on line.' },
    { 'Version': '1.0.1540', 'Date': '13-07-2021', 'ReleasedBy': 'Richard', 'Description': 'Updated ticket loading and minor bugs fixed.' },
    { 'Version': '1.0.1541', 'Date': '15-07-2021', 'ReleasedBy': 'Kevin', 'Description': 'Fix bug with missing SBC Port Charge' },
    { 'Version': '1.0.1565', 'Date': '19-07-2021', 'ReleasedBy': 'Kevin', 'Description': 'Fix bug with cxnow not counting gamma' },
    { 'Version': '1.0.1567', 'Date': '20-07-2021', 'ReleasedBy': 'Richard', 'Description': 'Change autotask contact details resolution to consider users without email addresses.' },
    { 'Version': '1.0.1583', 'Date': '30-07-2021', 'ReleasedBy': 'Richard', 'Description': 'Removed autotask links and allowed ticket details to be opened from site ticket' },
    { 'Version': '1.0.1587', 'Date': '02-08-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote bug fixing, and new options to CXNow and Phoneline+' },
    { 'Version': '1.0.1591', 'Date': '04-08-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote Fix for phoneline+ on customer summary screen' },
    { 'Version': '1.0.1629', 'Date': '13-08-2021', 'ReleasedBy': 'Richard', 'Description': 'Create ticket updates + bug fixes' },
    { 'Version': '1.0.1646', 'Date': '18-08-2021', 'ReleasedBy': 'Richard', 'Description': 'Included bug fixes from Zirak and public user issue fixes' },
    { 'Version': '1.0.1653', 'Date': '20-08-2021', 'ReleasedBy': 'Richard', 'Description': 'Create ticket bug fixes' },
    { 'Version': '1.0.1661', 'Date': '08-09-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote Genesys Support' },
    { 'Version': '1.0.1667', 'Date': '13-09-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote Genesys fixes' },
    { 'Version': '1.0.1668', 'Date': '13-09-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote phonelineplus fixes' },
    { 'Version': '1.0.1671', 'Date': '16-09-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote teams connector recordings support' },
    { 'Version': '1.0.1673', 'Date': '16-09-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote teams connector recordings fixes' },
    { 'Version': '1.0.1674', 'Date': '17-09-2021', 'ReleasedBy': 'Richard', 'Description': 'Created ticket showing in list' },
    { 'Version': '1.0.1687', 'Date': '21-09-2021', 'ReleasedBy': 'Richard', 'Description': 'Added ticket notification for new notes and attachments' },
    { 'Version': '1.0.1690', 'Date': '21-09-2021', 'ReleasedBy': 'Richard', 'Description': 'Fixed bugs with create ticket notifications + put in loading screens for adding notes and attachments' },
    { 'Version': '1.0.1704', 'Date': '06-10-2021', 'ReleasedBy': 'Richard', 'Description': 'Applied various alterations and fixes to Create Tickets' },
    { 'Version': '1.0.1708', 'Date': '04-10-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Copy quote functionality' },
    { 'Version': '1.0.1724', 'Date': '04-10-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - SIP bug fixes, and cxnow license code change' },
    { 'Version': '1.0.1725', 'Date': '04-10-2021', 'ReleasedBy': 'Richard', 'Description': 'Update the request access form for new company name option' },
    { 'Version': '1.0.1735', 'Date': '25-10-2021', 'ReleasedBy': 'Zirak', 'Description': 'fixed prevent updating an email if the new email exists in the User-Management page' },
    { 'Version': '1.0.1736', 'Date': '26-10-2021', 'ReleasedBy': 'Richard', 'Description': 'Changed My Tickets to Tickets in the UI.' },
    { 'Version': '1.0.1739', 'Date': '27-10-2021', 'ReleasedBy': 'Kevin', 'Description': 'iQuote changes to duplciating and saving quotes.' },
    { 'Version': '1.0.1744', 'Date': '12-11-2021', 'ReleasedBy': 'Kevin', 'Description': 'Hard-coded @maintel.co.uk users to only be able to access iQuote.' },
    { 'Version': '1.0.1747', 'Date': '17-11-2021', 'ReleasedBy': 'Kevin', 'Description': 'Bug fixes to duplicating quote.  Rewritten sip migrations' },
    { 'Version': '1.0.1757', 'Date': '09-12-2021', 'ReleasedBy': 'Zirak', 'Description': 'Updated to tickets and help' },
    { 'Version': '1.0.1760', 'Date': '09-12-2021', 'ReleasedBy': 'Zirak', 'Description': 'Updated email validation in create user, update user details, create global user and create customer components. Also added Id for testing.' },
    { 'Version': '1.0.1777', 'Date': '10-01-2022', 'ReleasedBy': 'Zirak', 'Description': 'Fixed the ticket management tile on the dashboard page.' },
    { 'Version': '1.0.1807', 'Date': '19-01-2022', 'ReleasedBy': 'Zirak', 'Description': 'Fixed the timing issue in the projectStatus, QuoteStatus and TicketManagement tiles.' },
    { 'Version': '1.0.1809', 'Date': '19-01-2022', 'ReleasedBy': 'Richard', 'Description': 'Updated the add new pop up dialog boxes on the security tab and stopped multiple calls on request password.' },
    { 'Version': '1.0.1816', 'Date': '03-02-2022', 'ReleasedBy': 'Richard', 'Description': 'Added mobile ticket type to create ticket screen.' },
    { 'Version': '1.0.1823', 'Date': '09-02-2022', 'ReleasedBy': 'Kevin', 'Description': 'per user subscriptions for Teams and Genesys.' },
    { 'Version': '1.0.1828', 'Date': '10-02-2022', 'ReleasedBy': 'Richard', 'Description': 'Fixed ticket bug and reformatted login page.' },
    { 'Version': '1.0.1834', 'Date': '14-02-2022', 'ReleasedBy': 'Richard', 'Description': 'Fix bug when adding users and changing customers and added new mobile ticket requirements. Also update Power BI to call for token.' },
    { 'Version': '1.0.1837', 'Date': '14-02-2022', 'ReleasedBy': 'Richard', 'Description': 'Added new merge customer support page.' },
    { 'Version': '1.0.1847', 'Date': '02-03-2022', 'ReleasedBy': 'Kevin', 'Description': 'Pricing updates to Genesys / Teams' },
    { 'Version': '1.0.1848', 'Date': '03-03-2022', 'ReleasedBy': 'Kevin', 'Description': 'Genesys Users to SIP Channels Ratio Changed to 5:1' },
    { 'Version': '1.0.1856', 'Date': '06-04-2022', 'ReleasedBy': 'Richard', 'Description': 'New mobile reporting integration added using Jem service' },
    { 'Version': '1.0.1874', 'Date': '06-04-2022', 'ReleasedBy': 'Kevin', 'Description': 'Ring Central Updates, CX Now SIP Fix, Designpack Autotask Download' },
    { 'Version': '1.0.1878', 'Date': '12-05-2022', 'ReleasedBy': 'Kevin', 'Description': 'CXNow addons for advanced user type' },
    { 'Version': '1.0.1879', 'Date': '17-05-2022', 'ReleasedBy': 'Richard', 'Description': 'Merge customer included + loading optimisations.' },
    { 'Version': '1.0.1886', 'Date': '15-06-2022', 'ReleasedBy': 'Richard', 'Description': 'New user details email sent if the username is changed to the email address.' },
    { 'Version': '1.0.1887', 'Date': '16-06-2022', 'ReleasedBy': 'Richard', 'Description': 'Changed the create ticket and related screens to allow save and new and save and view ticket.' },
    { 'Version': '1.0.1891', 'Date': '29-06-2022', 'ReleasedBy': 'Richard', 'Description': 'Added new archive ticket page and resolved contact and resource for ticket details.' },
    { 'Version': '1.0.1892', 'Date': '03-08-2022', 'ReleasedBy': 'Richard', 'Description': 'New view mobile reporting permission + link display logic + traverse container editing.' },
    { 'Version': '1.0.1894', 'Date': '09-08-2022', 'ReleasedBy': 'Richard', 'Description': 'Removed contact from ticket creation.' },
    { 'Version': '1.0.1895', 'Date': '07-09-2022', 'ReleasedBy': 'Richard', 'Description': 'Change of Auth service + new user theme.' },
    { 'Version': '1.0.1896', 'Date': '23-08-2022', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Teams Connector and CXNow fixes' },
    { 'Version': '1.0.1901', 'Date': '07-10-2022', 'ReleasedBy': 'Richard', 'Description': 'MFA completed + traverse and requesting user screens for support.' },
    { 'Version': '1.0.1915', 'Date': '03-11-2022', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Design Pack Updates' },
    { 'Version': '1.0.1929', 'Date': '25-11-2022', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Design Pack Bug Fixes' },
    { 'Version': '1.0.1935', 'Date': '01-12-2022', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Design Pack Bug Fixes + Create Ticket Bug Fix' },
    { 'Version': '1.0.1936', 'Date': '05-12-2022', 'ReleasedBy': 'Richard', 'Description': 'Updated PMN screen + components + removed contact resolution from create tickets.' },
    { 'Version': '1.0.1939', 'Date': '13-12-2022', 'ReleasedBy': 'Kevin', 'Description': 'iQuote pricing updates and genesys / teams changes' },
    { 'Version': '1.0.1963', 'Date': '18-12-2022', 'ReleasedBy': 'Kevin', 'Description': 'iQuote uploading of DP templates.  RingCentral custom buy price support' },
    { 'Version': '1.0.1966', 'Date': '19-12-2022', 'ReleasedBy': 'Richard', 'Description': 'Updated help images + fixed iQuote undefined if no profile added.' },
    { 'Version': '1.0.1967', 'Date': '03-01-2023', 'ReleasedBy': 'Kevin', 'Description': 'Allow salesforce uploads to all customers from external DPs' },
    { 'Version': '1.0.1968', 'Date': '06-01-2023', 'ReleasedBy': 'Kevin', 'Description': 'Fix for DP not showing PS discount, and margin fixes' },
    { 'Version': '1.0.1970', 'Date': '09-01-2023', 'ReleasedBy': 'Kevin', 'Description': 'ring central fixes, and fix for discrepency checker not working properly' },
    { 'Version': '1.0.1971', 'Date': '10-01-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Ring Central buy price fix' },
    { 'Version': '1.0.1972', 'Date': '10-01-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Managed services information not displayed after uploading calculator' },
    { 'Version': '1.0.2001', 'Date': '12-01-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Margin Fixes, DP Overview tab shows DP ID' },
    { 'Version': '1.0.2002', 'Date': '12-01-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - RingCentral buy price fix, issues itemised on ticket 3472' },
    { 'Version': '1.0.2004', 'Date': '12-01-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Mitel unit cost fix, margins and discounts fix on DP  summary tab' },
    { 'Version': '1.0.2010', 'Date': '17-01-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Hardware and PS Tab support negative quantities and pause/resume' },
    { 'Version': '1.0.2013', 'Date': '18-01-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - New Managed Services Calculator' },
    { 'Version': '1.0.2026', 'Date': '20-01-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Ring Central - solved issue when adding add-ons without profile users, Calculators - increased right display width to handle large filenames' },
    { 'Version': '1.0.2028', 'Date': '24-01-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Ring Central - Fix for rounding issues on iQuote quotes on DP Summary tab' },
    { 'Version': '1.0.2030', 'Date': '25-01-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - PS Expenses are margin-based now, and PS Tab rounding issues on DP Fixed' },
    { 'Version': '1.0.2036', 'Date': '26-01-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - New MS calculator- fixed UI formatting issue once uploaded in iQuote' },
    { 'Version': '1.0.2037', 'Date': '26-01-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DPs now handle unit costs that are not shown on quotes' },
    { 'Version': '1.0.2045', 'Date': '30-01-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Twilio calculator + fixed file info not displayed after uploading/replacing calcs + fixed UI formatting issue (width) after uploading calcs' },
    { 'Version': '1.0.2046', 'Date': '30-01-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP now handle unit costs that are shown on quotes, but have zero cost' },
    { 'Version': '1.0.2053', 'Date': '30-01-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Calculator upload layout fixes' },
    { 'Version': '1.0.2054', 'Date': '31-01-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing BOM width issues' },
    { 'Version': '1.0.2057', 'Date': '01-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - SIP upfront unit costs uses NS UPfront code.  Autotask spreadsheet download fixes.' },
    { 'Version': '1.0.2058', 'Date': '01-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Disabled lock DP functionality' },
    { 'Version': '1.0.2066', 'Date': '03-02-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Ring Central - fixing unable to add Ring central services if previously removed from quote' },
    { 'Version': '1.0.2072', 'Date': '01-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixes for discrepencies between DPs and quotes' },
    { 'Version': '1.0.2076', 'Date': '08-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Ability to lock DP and Quote. New Quote summary screen' },
    { 'Version': '1.0.2083', 'Date': '08-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Total sell price on DP was not coping with negative prices' },
    { 'Version': '1.0.2084', 'Date': '09-02-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Change name of standard caveats tab' },
    { 'Version': '1.0.2087', 'Date': '10-02-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - New managed services and Twilio tabs added into DP Export' },
    { 'Version': '1.0.2090', 'Date': '10-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Front-end changes for price overrides (Does not save to DB yet, and quotes will not match)' },
    { 'Version': '1.0.2094', 'Date': '14-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - ability to save price overrides to DB' },
    { 'Version': '1.0.2099', 'Date': '16-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - upload modified DPs' },
    { 'Version': '1.0.2105', 'Date': '16-02-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fixed PS table totals + Broadband override capex sales margin in circuits tab' },
    { 'Version': '1.0.2117', 'Date': '23-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - fixed Icon Gateway entries being missing on quote' },
    { 'Version': '1.0.2121', 'Date': '24-02-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fixed PS table totals not correct + removed controlled release banner' },
    { 'Version': '1.0.2125', 'Date': '27-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Circuits Broadband supports discounts / price overrides' },
    { 'Version': '1.0.2127', 'Date': '27-02-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fixed RC UC override pricing not being retained + sorting inconsistencies in number format on DP export + moved paid upfront button to Detailed Overview screen' },
    { 'Version': '1.0.2129', 'Date': '28-02-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Default Margins Fix' },
    { 'Version': '1.0.2134', 'Date': '28-02-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix Upload Modified DP button showing on duplicate DPs' },
    { 'Version': '1.0.2138', 'Date': '28-02-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixed Overview tab showing on DP when using the upload Modified DP functionality' },
    { 'Version': '1.0.2143', 'Date': '01-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - More circuits support discounts / price overrides + other fixes for price overrides' },
    { 'Version': '1.0.2148', 'Date': '01-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Icon Now discount fix, iQuote tab table fix, PS tab table fixes for days / half days' },
    { 'Version': '1.0.2154', 'Date': '06-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Add Ceases tab' },
    { 'Version': '1.0.2156', 'Date': '07-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - PS Tab table totals calc fixes for OOHs and Sundays + fixing export tab does not show upfront costs' },
    { 'Version': '1.0.2157', 'Date': '07-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Store the ID of files uploaded for modified DPs/DPs based on spreadsheets' },
    { 'Version': '1.0.2158', 'Date': '07-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - change iQuote/DP export file names' },
    { 'Version': '1.0.2160', 'Date': '07-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - change fields on Quote template' },
    { 'Version': '1.0.2165', 'Date': '07-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - list other people that are viewing / editing a DP' },
    { 'Version': '1.0.2170', 'Date': '08-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Support for linking direct to an iquote DP revision' },
    { 'Version': '1.0.2174', 'Date': '08-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fixed Design overview details in DP Download to match' },
    { 'Version': '1.0.2178', 'Date': '08-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - upload to salesforce only allowed for stages other than "Closed Won"' },
    { 'Version': '1.0.2179', 'Date': '09-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - fix for standalone DPs not being exportable' },
    { 'Version': '1.0.2180', 'Date': '09-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - remove PS costs from RingCentral' },
    { 'Version': '1.0.2188', 'Date': '09-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Locking' },
    { 'Version': '1.0.2189', 'Date': '10-03-2023', 'ReleasedBy': 'Richard', 'Description': 'Reduce max for file size uploads from 200 back to 10mb' },
    { 'Version': '1.0.2190', 'Date': '10-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - cosmetic fix for circuits equinix' },
    { 'Version': '1.0.2192', 'Date': '10-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'Portal - fix for failing to add notes and attachments to tickets' },
    { 'Version': '1.0.2193', 'Date': '10-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'Portal - another fix for failing to add notes and attachments to tickets' },
    { 'Version': '1.0.2200', 'Date': '14-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP downloads restricted to only be allowed once' },
    { 'Version': '1.0.2203', 'Date': '15-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Locking fixes' },
    { 'Version': '1.0.2204', 'Date': '15-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Contract framework charges not taking into account price overrides' },
    { 'Version': '1.0.2205', 'Date': '15-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Locking Fixes' },
    { 'Version': '1.0.2206', 'Date': '15-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Locking Fix when pressing upload to salesforce' },
    { 'Version': '1.0.2207', 'Date': '16-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Locking Fix when pressing upload to salesforce' },
    { 'Version': '1.0.2210', 'Date': '16-03-2023', 'ReleasedBy': 'Richard', 'Description': 'Portal - Create Ticket product description bug fix' },
    { 'Version': '1.0.2212', 'Date': '16-03-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix for Creating new DP Revisions being broken' },
    { 'Version': '1.0.2215', 'Date': '20-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Replace Redbox codes - Mitel' },
    { 'Version': '1.0.2217', 'Date': '21-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - PS price changes' },
    { 'Version': '1.0.2219', 'Date': '21-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Update MS Teams Call recording prices' },
    { 'Version': '1.0.2223', 'Date': '22-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Genesys price increases, Mitel - fixing user stuck in adjuncts screen + data retained when removing call recording subscription' },
    { 'Version': '1.0.2230', 'Date': '27-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Add new Genesys codes + Add Genesys 2 User WEM Upgrade I license + Remove ICON Communicate ASC support 3rd Line Platinum channel license' },
    { 'Version': '1.0.2234', 'Date': '28-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Change Upload to Salesforce grey out state' },
    { 'Version': '1.0.2235', 'Date': '28-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fix for text wrap around within DP user list' },
    { 'Version': '1.0.2236', 'Date': '29-03-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fix Incorrect margin for PS in ICON Secure' },
    { 'Version': '1.0.2242', 'Date': '03-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Replace Redbox codes - Avaya' },
    { 'Version': '1.0.2245', 'Date': '04-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Quote Revisions' },
    { 'Version': '1.0.2246', 'Date': '08-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Quote Duplicating a quote now duplicates external calcs and Ring Central' },
    { 'Version': '1.0.2247', 'Date': '11-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - fix for when creating revision 8 I have lost services' },
    { 'Version': '1.0.2250', 'Date': '11-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits and Hardware Upload sheet' },
    { 'Version': '1.0.2251', 'Date': '11-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fix Unable to create DP from duplicate quote with BOM or External calculator' },
    { 'Version': '1.0.2252', 'Date': '11-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fix Duplicate quote states incorrect user in Last Edited field' },
    { 'Version': '1.0.2253', 'Date': '11-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Disable DP Create a new Revision button while revision is being created' },
    { 'Version': '1.0.2254', 'Date': '12-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits Upload - fixed Object reference error on BB sheet + improved error messages' },
    { 'Version': '1.0.2255', 'Date': '12-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits Upload - added a wait to the display whilst uploading' },
    { 'Version': '1.0.2256', 'Date': '17-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits upload - added warning message prior to upload' },
    { 'Version': '1.0.2257', 'Date': '17-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits upload - handling large files' },
    { 'Version': '1.0.2258', 'Date': '17-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits upload - added pagination' },
    { 'Version': '1.0.2259', 'Date': '18-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Limited how frequently hardware, PS and circuits tab save to the DB' },
    { 'Version': '1.0.2265', 'Date': '18-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Salesforce Upload fix for disabled entries, autotask tab now default, circuits contingency can be disabled' },
    { 'Version': '1.0.2267', 'Date': '18-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix for circuits upload not showing entries after upload' },
    { 'Version': '1.0.2268', 'Date': '18-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix for hardware tab console errors and issues with saving' },
    { 'Version': '1.0.2269', 'Date': '18-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Stores decimal portions of margins on circuits tab.  Removed length restriction on DP Description / Quotation Instructions' },
    { 'Version': '1.0.2272', 'Date': '19-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Increased margin precision to 8 decimal places' },
    { 'Version': '1.0.2273', 'Date': '19-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Increased discount precision to 8 decimal places' },
    { 'Version': '1.0.2274', 'Date': '19-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Save margin overrides to DB with precision to 8 decimal places' },
    { 'Version': '1.0.2278', 'Date': '24-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits Upload - Limiting the number of entries on the same page to 100' },
    { 'Version': '1.0.2280', 'Date': '24-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits upload - Add a red warning message prior to upload' },
    { 'Version': '1.0.2282', 'Date': '24-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fixing DP export error' },
    { 'Version': '1.0.2283', 'Date': '24-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits upload - Show a replica of the pagination bar at the top' },
    { 'Version': '1.0.2288', 'Date': '25-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - PS Expenses no longer offers PS Role Type.  Locked quotes cannot be edited' },
    { 'Version': '1.0.2289', 'Date': '26-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixes for calculators not being loaded properly and Icon Secure and Managed Service calcs not duplicating autotask entries properly' },
    { 'Version': '1.0.2290', 'Date': '26-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits upload - simplifying the error message when exceeding the limit of 100 items per page' },
    { 'Version': '1.0.2291', 'Date': '26-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits upload - limiting the total number of entries to 2000' },
    { 'Version': '1.0.2292', 'Date': '26-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits upload/Pagination - Add search function' },
    { 'Version': '1.0.2293', 'Date': '26-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits upload/Pagination - Fixing pagination aligment' },
    { 'Version': '1.0.2294', 'Date': '27-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixing DP discrepancy checker' },
    { 'Version': '1.0.2297', 'Date': '27-04-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixes for Calls and Lines discrepancies' },
    { 'Version': '1.0.2300', 'Date': '28-04-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Hardware Upload Sheet' },
    { 'Version': '1.0.2302', 'Date': '02-05-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP changes rolled back if error saving to DB' },
    { 'Version': '1.0.2304', 'Date': '02-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Hardware Upload Sheet (fixing .xls template)' },
    { 'Version': '1.0.2305', 'Date': '03-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Add ASC Call recording server logic' },
    { 'Version': '1.0.2306', 'Date': '03-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits/Hardware upload - showing a dialog to confirm the upload' },
    { 'Version': '1.0.2307', 'Date': '03-05-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Quote Preview Tab' },
    { 'Version': '1.0.2308', 'Date': '03-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits/Hardware upload - allowing whitespaces in search' },
    { 'Version': '1.0.2309', 'Date': '03-05-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix for iQuote Preview and Searches in hardware tab' },
    { 'Version': '1.0.2310', 'Date': '03-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - ASC Call recording server logic - changing default VM to small' },
    { 'Version': '1.0.2315', 'Date': '03-05-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixes for iQuote Preview' },
    { 'Version': '1.0.2316', 'Date': '03-05-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixes for iQuote Preview' },
    { 'Version': '1.0.2319', 'Date': '09-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits/Hardware Upload - changing template to contain 2000 blank lines' },
    { 'Version': '1.0.2320', 'Date': '09-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Hardware Upload - Validating vendor + quantity in the upload' },
    { 'Version': '1.0.2321', 'Date': '10-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuits/Hardware Upload - added ability to search in dropdowns and calculated columns' },
    { 'Version': '1.0.2323', 'Date': '10-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixed the issue of upload progress disappearing before completion' },
    { 'Version': '1.0.2325', 'Date': '10-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix ASC VM added without ASC recording selected - Mitel only' },
    { 'Version': '1.0.2326', 'Date': '10-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing cannot upload the same file again throughout the app' },
    { 'Version': '1.0.2328', 'Date': '11-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix Hardware Upload - Last page button shows no entries whilst searching' },
    { 'Version': '1.0.2330', 'Date': '15-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing Invalid date shown in Design overview' },
    { 'Version': '1.0.2332', 'Date': '15-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Subscription and Service Level changes' },
    { 'Version': '1.0.2343', 'Date': '16-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Allow Twilio calculator to import Cost and Sell price' },
    { 'Version': '1.0.2346', 'Date': '17-05-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Circuits Pricing updates' },
    { 'Version': '1.0.2350', 'Date': '17-05-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix for creating new revisions adding blank circuit entries.  Stop allowing pause/discount on modified DPs' },
    { 'Version': '1.0.2351', 'Date': '17-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Change text on Cease tab' },
    { 'Version': '1.0.2352', 'Date': '17-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing Unable to upload BB services via Circuit upload' },
    { 'Version': '1.0.2353', 'Date': '18-05-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - URL Now shows DP id and Revision number' },
    { 'Version': '1.0.2370', 'Date': '18-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix Circuits upload - search not working on 5 columns' },
    { 'Version': '1.0.2371', 'Date': '18-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix Circuits/Hardware upload - Wan/BB/Main/Misc data does not change when switching revision' },
    { 'Version': '1.0.2380', 'Date': '24-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix Harware Upload no error if both vendor/quantity are missing' },
    { 'Version': '1.0.2385', 'Date': '24-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix Application licence type needs amending in DP to match business rules' },
    { 'Version': '1.0.2386', 'Date': '24-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix Search function within Broadband table returning unexpected data (ID search)' },
    { 'Version': '1.0.2393', 'Date': '26-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Disabling buttons when updating/saving the DP' },
    { 'Version': '1.0.2395', 'Date': '30-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Adding Upgrade/Migration in Design Overview' },
    { 'Version': '1.0.2396', 'Date': '30-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Adjust labels on Cease tab' },
    { 'Version': '1.0.2397', 'Date': '30-05-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix Credit BOM Third party cost total' },
    { 'Version': '1.0.2403', 'Date': '05-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing PS costs' },
    { 'Version': '1.0.2404', 'Date': '06-06-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Salesforce iQuote link URL now has DP ID and Revision Number, rather than DP Revision ID' },
    { 'Version': '1.0.2417', 'Date': '08-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix information not displayed when a new revision/duplicate is made + fixes in BOM export' },
    { 'Version': '1.0.2419', 'Date': '09-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Remove columns for Circuit upload sheet' },
    { 'Version': '1.0.2420', 'Date': '09-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - BOM - allowing changes in latest revisions' },
    { 'Version': '1.0.2425', 'Date': '13-06-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Updates UC/CC numbers in iQuote object in Salesforce' },
    { 'Version': '1.0.2439', 'Date': '13-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Add Admin Centre for audits (Phase 1)' },
    { 'Version': '1.0.2440', 'Date': '14-06-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Update CCaas and UCaas fields in salesforce' },
    { 'Version': '1.0.2441', 'Date': '14-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Add Admin Centre for audits (Phase 1) - granting access' },
    { 'Version': '1.0.2472', 'Date': '14-06-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix for not updating salesforce with UC Users' },
    { 'Version': '1.0.2473', 'Date': '14-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Add Admin Centre for audits - sorting search/pagination' },
    { 'Version': '1.0.2477', 'Date': '19-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Add Admin Centre for audits - added chart in home screen' },
    { 'Version': '1.0.2482', 'Date': '20-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing Twilio for new revisions/duplicates' },
    { 'Version': '1.0.2488', 'Date': '21-06-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Reduce liklihood of duplicating DP twice' },
    { 'Version': '1.0.2489', 'Date': '21-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing new managed service for new revisions/duplicates' },
    { 'Version': '1.0.2491', 'Date': '21-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing managed service for new revisions/duplicates' },
    { 'Version': '1.0.2492', 'Date': '22-06-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Securio' },
    { 'Version': '1.0.2503', 'Date': '22-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing Icon Secure for new revisions/duplicates' },
    { 'Version': '1.0.2505', 'Date': '23-06-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Securio Fixes' },
    { 'Version': '1.0.2507', 'Date': '23-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing Horizon for new revisions/duplicates' },
    { 'Version': '1.0.2509', 'Date': '23-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing Calls and Lines for new revisions/duplicates' },
    { 'Version': '1.0.2513', 'Date': '26-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing Icon Now for new revisions/duplicates' },
    { 'Version': '1.0.2515', 'Date': '26-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing Icon Connect for new revisions/duplicates' },
    { 'Version': '1.0.2521', 'Date': '27-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Adding half day + new PS codes' },
    { 'Version': '1.0.2527', 'Date': '28-06-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixes for creating new revisions' },
    { 'Version': '1.0.2528', 'Date': '28-06-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixes for C&L and Horizon margins not being updated' },
    { 'Version': '1.0.2534', 'Date': '30-06-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixes for circuits select/unselect buttons' },
    { 'Version': '1.0.2539', 'Date': '04-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Changes to Adding PC time on orders over £1000' },
    { 'Version': '1.0.2545', 'Date': '04-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Adjust Genesys SIP ratios' },
    { 'Version': '1.0.2546', 'Date': '04-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Remove Genesys AI codes from user count' },
    { 'Version': '1.0.2547', 'Date': '04-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Remove BYOC option for Genesys Digital only quotes' },
    { 'Version': '1.0.2548', 'Date': '05-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix for PC charge on DPs over £1000' },
    { 'Version': '1.0.2554', 'Date': '05-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Genesys (defaulting Sip Trunk Ratio to 5 + moving AI Experience services to the Add-ons)' },
    { 'Version': '1.0.2555', 'Date': '06-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Adding PS days codes for Sat/Sunday half days' },
    { 'Version': '1.0.2556', 'Date': '06-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Rename "Infrastructure" on reports to "Service Setup Charge"' },
    { 'Version': '1.0.2557', 'Date': '06-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix for PC charge on DPs over £1000 not looking at PS entry being paused' },
    { 'Version': '1.0.2560', 'Date': '06-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing PS Sat/Sunday half days' },
    { 'Version': '1.0.2563', 'Date': '07-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Salesforce integration down checks and stores the uc and cc provider selected' },
    { 'Version': '1.0.2565', 'Date': '07-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing inconsistent Allocation Summary in PS tab' },
    { 'Version': '1.0.2566', 'Date': '07-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing unable to add Genesys add-on services without user profiles' },
    { 'Version': '1.0.2568', 'Date': '10-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix for error on DP Create' },
    { 'Version': '1.0.2570', 'Date': '10-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Rounding quantity in Autotask' },
    { 'Version': '1.0.2571', 'Date': '10-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing Admin Centre - display not changed if no records exist' },
    { 'Version': '1.0.2578', 'Date': '10-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixing Alignment issues in DP, and issues when duplicating DP' },
    { 'Version': '1.0.2581', 'Date': '12-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing ICON Bandwidth Contribution' },
    { 'Version': '1.0.2582', 'Date': '12-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Change default for ICON Communicate' },
    { 'Version': '1.0.2587', 'Date': '12-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP formatting updates, ICON_SEC costs changed for Mitel' },
    { 'Version': '1.0.2588', 'Date': '12-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Removing change default for ICON Communicate' },
    { 'Version': '1.0.2591', 'Date': '12-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Do not allow creating DP from quote, until quote is fully read' },
    { 'Version': '1.0.2602', 'Date': '12-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Reverted iQuote ticket menu change' },
    { 'Version': '1.0.2605', 'Date': '12-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Added ability to override description for unit costs to show on quotes' },
    { 'Version': '1.0.2631', 'Date': '20-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Admin centre - adding table description + Admin charge added automatically when required' },
    { 'Version': '1.0.2632', 'Date': '20-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Icon Sec Certificate Updates' },
    { 'Version': '1.0.2644', 'Date': '21-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Icon Sec Certificate for Avaya' },
    { 'Version': '1.0.2664', 'Date': '21-07-2023', 'ReleasedBy': 'Richard', 'Description': 'Portal - Merge in changes from Portal No DB Branch' },
    { 'Version': '1.0.2667', 'Date': '24-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixes for Icon Sec Aura, Archive Storage and Sip Channel Calculations not being saved' },
    { 'Version': '1.0.2676', 'Date': '25-07-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Price overrides can now be shown / hidden independantly to whether they support margins / discounts' },
    { 'Version': '1.0.2679', 'Date': '25-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Change banner text + Fix Mitel SRC server shown if ASC selected ' },
    { 'Version': '1.0.2688', 'Date': '25-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Admin centre - Move Search/Pagination controls to top of Home screen' },
    { 'Version': '1.0.2698', 'Date': '31-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'Portal - Convert Uploads Api to .Net7' },
    { 'Version': '1.0.2704', 'Date': '31-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix Export does not contain the renamed fields + Admin centre report tab fix' },
    { 'Version': '1.0.2711', 'Date': '31-07-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Migrating iQuote Api to .Net 7' },
    { 'Version': '1.0.2715', 'Date': '01-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing incorrect PS codes + Deleting "PMO Reviewer - Optional" in Design Overview/Export' },
    { 'Version': '1.0.2719', 'Date': '03-08-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - New UC Analytics module' },
    { 'Version': '1.0.2722', 'Date': '07-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Change default for ICON Communicate' },
    { 'Version': '1.0.2723', 'Date': '08-08-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Price overrides for Circuits, Hardware and C&L/Horizon' },
    { 'Version': '1.0.2724', 'Date': '08-08-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - UC Analytics Bug fixes' },
    { 'Version': '1.0.2726', 'Date': '08-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Adjust Margin levels for product types' },
    { 'Version': '1.0.2730', 'Date': '09-08-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix for Twilio entries not being able to disable autotask entries if no description given to calc' },
    { 'Version': '1.0.2733', 'Date': '10-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Sorting min tech margins for hardware and circuits' },
    { 'Version': '1.0.2734', 'Date': '10-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - UC Analytics - fix foundation level not added by Ring Central ' },
    { 'Version': '1.0.2736', 'Date': '14-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Removing UC Analytics' },
    { 'Version': '1.0.2737', 'Date': '14-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing default for ICON Communicate' },
    { 'Version': '1.0.2738', 'Date': '14-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Hardware/Circuits: showing the margins in red if they go below the min level' },
    { 'Version': '1.0.2739', 'Date': '15-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Adjusting Margin min levels for Technology COE' },
    { 'Version': '1.0.2740', 'Date': '15-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Genesys: fixing cannot select multiple profiles' },
    { 'Version': '1.0.2741', 'Date': '15-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Removing upfront charge warning banner ' },
    { 'Version': '1.0.2742', 'Date': '15-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fixing RingCentral for quantity < 20 ' },
    { 'Version': '1.0.2758', 'Date': '21-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Restoring  UC Analytics' },
    { 'Version': '1.0.2759', 'Date': '22-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - UC Analytics bug fixes' },
    { 'Version': '1.0.2760', 'Date': '23-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing report error on maximum nos. of seats' },
    { 'Version': '1.0.2761', 'Date': '25-08-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - UCA - fixing error messages' },
    { 'Version': '1.115.1', 'Date': '30-08-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - SQL licenses quantity is half the vCPU quantity' },
    { 'Version': '1.115.2', 'Date': '30-08-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - SQL licenses Rounded up per VM' },
    { 'Version': '1.115.3', 'Date': '31-08-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Windows Licences Price Change, and Remove Zerto from ASC server' },
    { 'Version': '1.115.4', 'Date': '01-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - UC Analytics single profile only and disable POC' },
    { 'Version': '1.115.5', 'Date': '01-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Add Framework RM6116' },
    { 'Version': '1.115.6', 'Date': '01-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Framework RM6116 Typo fix' },
    { 'Version': '1.116.1', 'Date': '08-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - BOM Upload fixes, SIP License Spelling fix' },
    { 'Version': '1.116.2', 'Date': '08-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - BOM Upload fixes and Icon Now fix' },
    { 'Version': '1.116.3', 'Date': '18-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - First release of Avaya AXP (First Screen and Preview Screen)' },
    { 'Version': '1.116.4', 'Date': '18-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Avaya AXP License calcs' },
    { 'Version': '1.116.5', 'Date': '19-09-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Avaya AXP additional screens' },
    { 'Version': '1.116.6', 'Date': '20-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Avaya AXP Licenses for Teams Connector and DP/Quote discrepency due to licenses being charged annually' },
    { 'Version': '1.116.7', 'Date': '20-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Avaya AXP Tooltips and Warning updates, and ability to add add-ons with no main profiles added' },
    { 'Version': '1.116.8', 'Date': '20-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Avaya AXP Updates to rules on tiers and quantities' },
    { 'Version': '1.116.9', 'Date': '21-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Avaya AXP Fix allowed range for Sppech Analytics Additional Line of Business' },
    { 'Version': '1.116.10', 'Date': '21-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Avaya AXP Fix incorrect Qty on INTERACTION DATA EXPORT MANAGER' },
    { 'Version': '1.116.11', 'Date': '21-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Avaya AXP Fix 12 month termlength reverting to 36 months' },
    { 'Version': '1.116.12', 'Date': '21-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Avaya AXP do not sort autotask or quote by description' },
    { 'Version': '1.116.13', 'Date': '22-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Disable controls on review screen whilst quote is saving' },
    { 'Version': '1.116.14', 'Date': '22-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Avaya AXP, changed code for Elite CC Licenses' },
    { 'Version': '1.116.15', 'Date': '25-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Revert change to sorting of autotask/quote entries' },
    { 'Version': '1.116.16', 'Date': '25-09-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Change ASC Server from Linux to Windows' },
    { 'Version': '1.117.1', 'Date': '29-09-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Updating descriptions for Windows DC Licenses + Add Circuit Contingency as an AT line item + Fixing Teams Recording not adding costs for storage' },
    { 'Version': '1.117.2', 'Date': '02-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Showing Circuit Contingency in the quote' },
    { 'Version': '1.117.3', 'Date': '03-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Can no longer set invalid term length, Bank Hols on PS Codes, BT OpenReach PST Removed' },
    { 'Version': '1.117.4', 'Date': '04-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuit Contingency as an AT line item - fixing export' },
    { 'Version': '1.117.5', 'Date': '04-10-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Cisco VIP Rebate field in DP' },
    { 'Version': '1.117.6', 'Date': '06-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Add Cisco Rebate to DP Export + Showing circuits contingency as upfront' },
    { 'Version': '1.117.7', 'Date': '09-10-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Cisco Rebate now accepts decimals, bank holiday now in description of PS half days' },
    { 'Version': '1.117.8', 'Date': '10-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Adding products to KComms Broadband + Amending PS Schedule dropdown for Sun Half-Day + SBC Port Charge now appearing on Standalone SIP Quotes' },
    { 'Version': '1.117.9', 'Date': '11-10-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - AXP Module does not include AQM with All Media Bundle, Cisco VIP Discounts decimals no longer crash export service' },
    { 'Version': '1.117.10', 'Date': '11-10-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Icon Communicate SIP fixes introduced when fixing standalone SIP + Fix incorrect gross margin on summary tab + warning label edit on AXP' },
    { 'Version': '1.117.11', 'Date': '20-10-2023', 'ReleasedBy': 'Richard', 'Description': 'Portal - Updated the help section for UC Analytics' },
    { 'Version': '1.117.12', 'Date': '16-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Changing all PS Codes in PS tab' },
    { 'Version': '1.117.13', 'Date': '19-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Removing None from PS profiles/schedules + fix for lics with no price being charged for on DP' },
    { 'Version': '1.117.14', 'Date': '20-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Hiding "None" from PS Role Profiles in both core/options entries + restrict TermLengths In Common For Services' },
    { 'Version': '1.117.15', 'Date': '23-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - PS Codes (phase 2) + UC Analytics Setup Charge + Horizon Calc Ignore -ve' },
    { 'Version': '1.117.16', 'Date': '25-10-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - UC Analytics Fixes' },
    { 'Version': '1.117.17', 'Date': '25-10-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - UC Analytics Labels move' },
    { 'Version': '1.117.18', 'Date': '26-10-2023', 'ReleasedBy': 'Richard', 'Description': 'Portal - Refined the system status page to highlight errors.' },
    { 'Version': '1.117.19', 'Date': '27-10-2023', 'ReleasedBy': 'Richard', 'Description': 'Portal - Removed the test permission from UC Analytics.' },
    { 'Version': '1.117.20', 'Date': '30-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - fixing PS Codes reverted on deployment + circuit contingency being incorrectly added to Quote' },
    { 'Version': '1.117.21', 'Date': '30-10-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Warning not to use Avaya AXP Module' },
    { 'Version': '1.117.22', 'Date': '13-11-2023', 'ReleasedBy': 'Richard', 'Description': 'Portal - fix username logging in issue' },
    { 'Version': '1.118.1', 'Date': '09-11-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Update Exports Service to .Net 7 + change Project Coordination charge + fixing TC redundancy option' },
    { 'Version': '1.118.2', 'Date': '09-11-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing duplicate quote not saving when changing SF Opp number + allow order co-ordination charge to be discounted/overridden' },
    { 'Version': '1.118.3', 'Date': '10-11-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing Project co-ordination charge price and discount' },
    { 'Version': '1.118.4', 'Date': '13-11-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing TC redundancy options + Ceases tab upload' },
    { 'Version': '1.118.5', 'Date': '14-11-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing ceases upload line removal + fixing project co-ordination charge checkbox' },
    { 'Version': '1.118.6', 'Date': '15-11-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Update Avaya AXP Module Pricing' },
    { 'Version': '1.118.7', 'Date': '04-12-2023', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Correct ticket note bug and implement project display changes' },
    { 'Version': '1.118.8', 'Date': '04-12-2023', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Save a new Autotask ticket to the database when it is added' },
    { 'Version': '1.118.9', 'Date': '05-12-2023', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Include the resource ID and dates from autotask when adding a new ticket' },
    { 'Version': '1.119.1', 'Date': '30-11-2023', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Circuit contingency fixes' },
    { 'Version': '1.119.2', 'Date': '06-12-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Salesforce Updates to UC/CC Provider and CaaS Provider' },
    { 'Version': '1.119.3', 'Date': '07-12-2023', 'ReleasedBy': 'Richard', 'Description': 'Merged the hotfix branch back into main' },
    { 'Version': '1.119.4', 'Date': '08-12-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - SF upload fixes' },
    { 'Version': '1.120.1', 'Date': '28-11-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (iQuote Modules, Calls and Lines, BOMs)' },
    { 'Version': '1.120.2', 'Date': '29-11-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Icon Connect), Detail Data applied by default now' },
    { 'Version': '1.120.3', 'Date': '07-12-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Icon now, Twilio, Discounts / Margins fixes' },
    { 'Version': '1.120.4', 'Date': '14-12-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (PS, Circuits, Hardware' },
    { 'Version': '1.120.5', 'Date': '19-12-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (MS, New MS, Icon Secure, Horizon, Modified & Edited DPs, Quote Preview' },
    { 'Version': '1.120.6', 'Date': '19-12-2023', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Paused Entries no longer show on Quote)' },
    { 'Version': '1.120.7', 'Date': '02-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Autotask tabs uses new data, tooltip only shown if more detail than summary, unitcosts always 4 Decimal Places Now)' },
    { 'Version': '1.120.8', 'Date': '03-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Missing Partnumber for Hardware Main, Missing VIR-AVAIL-1001>5000 ,for Aura)' },
    { 'Version': '1.120.9', 'Date': '04-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Missing Upfront Icon Sec Cert, Default PS to 50% Margin on Icon Secure, Fix tooltips showing if there are hidden autotask entries, do not allow discount of autotask C&L items)' },
    { 'Version': '1.120.10', 'Date': '08-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (QuoteRef missing from WAN connectivity and Hosting and Cabling. Supplier Missing from Fixed Line voice and WAN connectivity, Fix for not loading default margins from DB for calculators if description / SF code match)' },
    { 'Version': '1.120.11', 'Date': '09-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (DP and Quote downloads now use detail data)' },
    { 'Version': '1.120.12', 'Date': '10-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Rounding Fix, SF Code not showing when DP read-only, MS Calc Crashing iQuoteAPI, Icon Now Cost Summary Entries showing on Quote)' },
    { 'Version': '1.120.13', 'Date': '10-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Incorrect Service column on quotes for external calcs and some PS, Twilio and Icon Now quotes not downloadable and AT tab has NaN for prices)' },
    { 'Version': '1.120.14', 'Date': '15-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (New Managed Service calc does not crash API)' },
    { 'Version': '1.120.15', 'Date': '15-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Fractional quantities now supported, No longer shows DP Sections and Margins when no detail entries visible)' },
    { 'Version': '1.120.16', 'Date': '16-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Calculators having missing OO entries on AT tab download, Icon Now Service Description on Quote)' },
    { 'Version': '1.120.17', 'Date': '17-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Calculators having incorrect service description on quotes)' },
    { 'Version': '1.120.18', 'Date': '18-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Price overrides)' },
    { 'Version': '1.120.19', 'Date': '18-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix VMs and Blades being removed' },
    { 'Version': '1.120.20', 'Date': '18-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP PRice Model (Hardware, Circuits, PS)' },
    { 'Version': '1.120.21', 'Date': '18-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Tooltip Formatting, Search Price Override Entries By Cost Type' },
    { 'Version': '1.120.22', 'Date': '18-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Fix for priceoverrides being lost when creating new revision)' },
    { 'Version': '1.120.23', 'Date': '18-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Fix for editing price overrides at detail level, not updating global level)' },
    { 'Version': '1.120.24', 'Date': '18-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Fix for no edit button if all prices overriden to £0)' },
    { 'Version': '1.120.25', 'Date': '24-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Additional Logging)' },
    { 'Version': '1.120.26', 'Date': '25-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Fix broken circuit contingency)' },
    { 'Version': '1.120.27', 'Date': '25-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Fix credit items (PS / BOMs etc) from appearing as positive)' },
    { 'Version': '1.120.28', 'Date': '25-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Do not allow price overrides on Horizon / C&L, Hardware Main and Misc Totals rounding issue on hardware tab)' },
    { 'Version': '1.120.29', 'Date': '25-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (BOMs, Icon Connect support.  Fix for recurring circuits entries not updating price overrides)' },
    { 'Version': '1.120.30', 'Date': '25-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Icon Now, New Managed Service Calc, Managed Service Calc, Icon Secure, Twilio)' },
    { 'Version': '1.120.31', 'Date': '25-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (New Managed Service Calc incorrectly importing AR totals)' },
    { 'Version': '1.120.32', 'Date': '25-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Licenses moved to new RRP-based DP sections)' },
    { 'Version': '1.120.33', 'Date': '25-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Fix twilio calc allowing discounts - ticket 7257)' },
    { 'Version': '1.120.34', 'Date': '30-01-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (Fix Managed Service and New Managed Service putting all autotask entries as internal)' },
    { 'Version': '1.120.35', 'Date': '05-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Crate new Licenses and DPTableSections for RRP)' },
    { 'Version': '1.120.36', 'Date': '07-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Fix quotes retaining price overrides when duplicated Ticket 7262)' },
    { 'Version': '1.120.37', 'Date': '07-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Do not allow price overrides to invert the polarity of a price - ticket 7288)' },
    { 'Version': '1.120.38', 'Date': '07-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (BOMs not saving external price overrides - Ticket 7263)' },
    { 'Version': '1.120.39', 'Date': '08-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Override margin error for Equinix Cloud Connect - Ticket 7201)' },
    { 'Version': '1.120.40', 'Date': '08-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (PS Paused items no longer appearing on quote - Ticket 7196)' },
    { 'Version': '1.120.41', 'Date': '08-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (margin changes not working with PS Third Party Pro Services Expenses - Ticket 7197)' },
    { 'Version': '1.120.42', 'Date': '08-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Price Overrides intermittently not being saved - Ticket 7289)' },
    { 'Version': '1.120.43', 'Date': '08-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (External Calc Price Overrides data being inherited from other DP - Ticket 7262)' },
    { 'Version': '1.120.44', 'Date': '09-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (RRP Model and Cost + Margin model with same DP section description did not work together - Ticket 7301)' },
    { 'Version': '1.120.45', 'Date': '09-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Genesys and RC licenses defaulting to margins not discounts post deployment of 1.120.40 - Ticket 7291)' },
    { 'Version': '1.120.46', 'Date': '12-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Not allowing you to clear price overrides from summary table - Ticket 7289)' },
    { 'Version': '1.120.47', 'Date': '12-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Price override not working when table loses focus)' },
    { 'Version': '1.120.48', 'Date': '14-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Circuits & HW tab - circuit & HW upload spreadsheet not uploading - Ticket 7205' },
    { 'Version': '1.120.49', 'Date': '14-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model - Ticket 7262' },
    { 'Version': '1.120.50', 'Date': '15-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix detaildata issues when creating new revisions. Tickets 7305, ' },
    { 'Version': '1.120.51', 'Date': '16-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Order Coordination now supports price overrides - Ticket 7186 ' },
    { 'Version': '1.120.52', 'Date': '16-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Intermittently quote does not reflect override price  - Ticket 7325' },
    { 'Version': '1.120.53', 'Date': '19-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (IFramework charges missing  - Ticket 7185)' },
    { 'Version': '1.120.54', 'Date': '19-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP Detail Data Summary (BOM being removed from DP when creating new revisions with multiple BOMS - Ticket 7332)' },
    { 'Version': '1.120.55', 'Date': '20-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (RRP Discounts and price override data not retained when DP locked and new revision created - Ticket 7287)' },
    { 'Version': '1.120.56', 'Date': '20-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Order Coordination price override now cleared when order co-ordination is removed - Ticket 7186)' },
    { 'Version': '1.120.57', 'Date': '21-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - First row of circuits broadband is missing upfront price - Ticket 7361)' },
    { 'Version': '1.120.58', 'Date': '21-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Only show RRP on tooltips against RRP licenses - Ticket 7303)' },
    { 'Version': '1.120.59', 'Date': '21-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Fractional quantities being truncated to integer on locked DPs - Ticket 7287)' },
    { 'Version': '1.120.60', 'Date': '21-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Annual Licenses incorrectly calculated on locked DPs - Ticket 7287)' },
    { 'Version': '1.120.61', 'Date': '22-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Create new revision of locked DP does not create new quote revision - Ticket 7373)' },
    { 'Version': '1.120.62', 'Date': '23-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (DS Tab - Only one opportunity to add price overrides to DP - Ticket 7374)' },
    { 'Version': '1.120.63', 'Date': '23-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Price Override lost when changing qty in IQuote if clicking on the complete quote button - Ticket 7383)' },
    { 'Version': '1.120.64', 'Date': '23-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Detailed summary data not refreshing until a change is made - Ticket 7382)' },
    { 'Version': '1.120.65', 'Date': '23-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Duplicated DP not using new DS data in some instances  - Ticket 7381)' },
    { 'Version': '1.120.66', 'Date': '26-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (RRP Model data showing on locked revisions that is using the legacy DS code - Ticket 7384)' },
    { 'Version': '1.120.67', 'Date': '26-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Creating a new revision of a locked legacy DP is not switching to use detail data - Ticket 7386)' },
    { 'Version': '1.120.68', 'Date': '28-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Fix for unitcosts changing when loading DP compared to when first creating it - Ticket 7384)' },
    { 'Version': '1.120.69', 'Date': '28-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Fix for designpack entries being missing from legacy quotes - Ticket 7384)' },
    { 'Version': '1.120.70', 'Date': '29-02-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Fix for charge upfront being ticked, but unitcosts benig as if it was not - Ticket 7344)' },
    { 'Version': '1.120.71', 'Date': '04-03-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model (Creating a new revision of a locked legacy DP is not switching to use detail data - Ticket 7386)' },
    { 'Version': '1.120.72', 'Date': '06-03-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - New MS Calc AT tab - Unit AR Price and Unit AR Cost incorrect - Ticket 7221)' },
    { 'Version': '1.120.73', 'Date': '11-03-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - New MS Calc AT tab - Unit AR Price shown when no Unit AR Cost - Ticket 7221)' },
    { 'Version': '1.120.74', 'Date': '08-01-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Obtain API versions via Admin service call' },
    { 'Version': '1.120.75', 'Date': '10-01-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Pass an email address to the delete contact endpoint, not a contact ID' },
    { 'Version': '1.120.76', 'Date': '12-01-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - The contact ID of the creating user was not passed to a new ticket' },
    { 'Version': '1.120.77', 'Date': '16-01-2024', 'ReleasedBy': 'Richard', 'Description': 'Portal - Checked for trailing slash on datatrack base urls' },
    { 'Version': '1.120.78', 'Date': '18-01-2024', 'ReleasedBy': 'Richard', 'Description': 'Portal - Added permissions for Maintel users to access the UC Analytics launch button' },
    { 'Version': '1.120.79', 'Date': '25-01-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Get the company slug from the database and hide the View Details button if it is not there' },
    { 'Version': '1.120.80', 'Date': '31-01-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Fix infinite loop in survey results chart which was crashing ticket trends page, and corrected average calculation' },
    { 'Version': '1.120.81', 'Date': '19-02-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Fix runtime error if user without access to parent site logs ticket against child site' },
    { 'Version': '1.120.82', 'Date': '20-02-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Use correct site and contact ID when logging a new ticket' },
    { 'Version': '1.120.83', 'Date': '22-03-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - disable use detail data for summaries checkbox on DPs (Ticket 7735)' },
    { 'Version': '1.120.84', 'Date': '25-03-2024', 'ReleasedBy': 'Jeremy', 'Description': 'iQuote - create ticket page not working (Ticket 7737)' },
    { 'Version': '1.121.01', 'Date': '28-03-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - create ticket fails if user has no Autotask contact (Ticket 7754)' },
    { 'Version': '1.121.02', 'Date': '28-03-2024', 'ReleasedBy': 'Jeremy', 'Description': 'iQuote - set default priority of new ticket to 8 (P3 - Medium), not 2 (Medium) (Ticket 7774)' },
    { 'Version': '1.122.01', 'Date': '25-03-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Upload to salesforce error on locked DPs (Ticket 7730)' },
    { 'Version': '1.122.02', 'Date': '16-04-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model - RRP Missing on Read-only DPs (Ticket 7828)' },
    { 'Version': '1.122.03', 'Date': '16-04-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - DP RRP Model - Price Overrides give warning when when in limits (Ticket 7611)' },
    { 'Version': '1.122.04', 'Date': '18-04-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Pricing updates - Tickets 7468, 7881, 7580, 7740' },
    { 'Version': '1.122.05', 'Date': '19-04-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fixing incorrect Merge of Code - Ticket 7580' },
    { 'Version': '1.122.06', 'Date': '19-04-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Circuits updates, Fix bug with order coordination in red - Tickets 7184, 7860, 7606, 7731, 7172' },
    { 'Version': '1.122.07', 'Date': '01-05-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Use a post call to a public-facing URL for context (Ticket 7837)' },
    { 'Version': '1.122.08', 'Date': '15-05-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Use a get call with a ContextHash header to a public-facing URL for context (Ticket 7837)' },
    { 'Version': '1.122.09', 'Date': '15-05-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Make ContextHash header a generated GUID (Ticket 7837)' },
    { 'Version': '1.122.10', 'Date': '17-05-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Implemented corrected Context URL display (Ticket 7882)' },
    { 'Version': '1.122.11', 'Date': '20-05-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Implemented corrected Context URL display with auth cookie (Ticket 7882)' },
    { 'Version': '1.122.12', 'Date': '22-05-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Blank the Context IFrame prior to reload (Ticket 7882)' },
    { 'Version': '1.123.01', 'Date': '10-05-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Phoneline+ handsets, DP Updates and Quote Review Screen Fix - Tickets 7338, 7939, 7612, 6870, 5936, 4741' },
    { 'Version': '1.123.02', 'Date': '13-05-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Remove Twilio and Callmedia, Teams Additional SIP Channels Price Change, PS Discount Max now 20% - Tickets 7278, 8130, 8063, 8070, 7609' },
    { 'Version': '1.123.03', 'Date': '16-05-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Teams Additional SIP channel now discountable, circuit upload workbook links fix,  Internal PS Min Margin removed - Tickets 8063, 8070, 7609' },
    { 'Version': '1.123.04', 'Date': '23-05-2024', 'ReleasedBy': 'Jeremy', 'Description': 'Portal - Merged context fixes with latest iQuote updates (ticket 7882)' },
    { 'Version': '1.124.01', 'Date': '21-06-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - SIP Charges renamed and Prices updated, DP Description width fix, Framework Calculation Fix for Negative values (tickets 8179, 7607, 7966, 8162)' },
    { 'Version': '1.124.02', 'Date': '21-06-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Framework Charge fixes, Add PC banner fixes (tickets 7607, 7727, 8180, 7610)' },
    { 'Version': '1.124.03', 'Date': '21-06-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Text Description fixes on DP export, PS Rate changes (tickets 7966, 8252)' },
    { 'Version': '1.124.04', 'Date': '21-06-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - BOM Fixes (tickets 8444, 6149)' },
    { 'Version': '1.124.05', 'Date': '21-06-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Early July 2024 release)' },
    { 'Version': '1.124.06', 'Date': '26-06-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - BOM Template - Remove New Vendor Support and Sort Alphabetically (Ticket 8444)' },
    { 'Version': '1.127.01', 'Date': '10-07-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Remove Portal from iQuote' },
    { 'Version': '1.127.02', 'Date': '15-07-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - New Vendor Support not included on SF upload (Ticket 9128)' },
    { 'Version': '1.127.03', 'Date': '16-07-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Solving login issues' },
    { 'Version': '1.127.04', 'Date': '16-07-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing UC Analytics back button issue, fixing issue for smaller window sizes' },
    { 'Version': '1.127.05', 'Date': '17-07-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing top menu size in smaller windows when scrolling right' },
    { 'Version': '1.127.06', 'Date': '17-07-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixing the "jump to" menu on help page' },
    { 'Version': '1.127.07', 'Date': '18-07-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Hiding portal services on the Service Health page' },
    { 'Version': '1.127.08', 'Date': '19-07-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Solving issue after logging in with non-iQuote user' },
    { 'Version': '1.127.09', 'Date': '22-07-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Reinstate default company list in account settings' },
    { 'Version': '1.127.10', 'Date': '22-07-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Restoring Autotask API health check' },
    { 'Version': '1.127.11', 'Date': '22-07-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Sorting Next button in UC Analytics' },
    { 'Version': '1.127.12', 'Date': '23-07-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fix login' },
    { 'Version': '1.128.01', 'Date': '02-08-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Teams SIP Setup cost change (Ticket 8573)' },
    { 'Version': '1.128.02', 'Date': '07-08-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Amending PS rates (Ticket 8252)' },
    { 'Version': '1.131.01', 'Date': '04-09-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Fixes Netcall and Mitel E2 issues' },
    { 'Version': '1.131.02', 'Date': '11-09-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Netcall Termlength removes 48m term.  STCM description change.  RingCentral BYOC change' },
    { 'Version': '1.131.03', 'Date': '13-09-2024', 'ReleasedBy': 'Pedro', 'Description': 'iQuote - Mitel E2 fix to calc SIP channels, and also use same headers on summary panel as on entry screens' },
    { 'Version': '1.131.04', 'Date': '16-09-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Mitel E2 fix to allow duplicating the module when duplicating quote/DP' },
    { 'Version': '1.131.05', 'Date': '17-09-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Mitel E2 fix to allow deletion, Additional Conference Ports now saved, VMWare cost change, do not force SIP if not required' },
    { 'Version': '1.131.06', 'Date': '17-09-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Mitel E2 remove 48 month as allowed term length' },
    { 'Version': '1.131.07', 'Date': '18-09-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Mitel E2 fix for infrastructure in DP' },
    { 'Version': '1.131.08', 'Date': '18-09-2024', 'ReleasedBy': 'Kevin', 'Description': 'iQuote - Fix component being imported twice' }
  ]
}

export default {
  state,
  mutations,
  getters
}
